<template>
    <div id="otp-verify">
        <section class="after-header p-tb-10 bg-white p-3">
            <div class="c-intro">
                <ul class="breadcrumb mb-0">
                    <li>
                        <router-link to="/">
                        <i class="material-icons" title="Home">home</i>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="login" class="text-capitalize">
                            <span>Login</span>
                        </router-link>
                    </li>
                    <li>
                        <a type="button" class="text-capitalize">
                            <span>Password Reset</span>
                        </a>
                    </li>
                </ul>
            </div>
        </section>

        <form class="register-area d-block mx-auto border bg-white rounded my-5">
            <h4 class="text-dark text-center pt-4">Reset Password</h4>
            <div class="p-3">
                <div class="form-group px-0 pe-1 mb-2">
                    <label for="newPassword" class="mb-0 text-dark fw-bold">New Password <small class="text-danger">*</small></label>
                    <div style="height: 45px;">
                        <input
                            :type="newType ? 'text' : 'password'"
                            v-model="form.new_password"
                            class="form-control"
                            id="newPassword"
                            placeholder="Enter password"/>
                        <font-awesome
                            :icon="newType ? 'eye' : 'eye-slash'"
                            class="pass"
                            @click="newType = !newType" />
                    </div>
                    <span class="text-danger" id="newPasswordMsg"></span>
                </div>
                <div class="form-group px-0 pe-1 mb-2">
                    <label for="confirmPassword" class="mb-0 text-dark fw-bold">New Password Confirmed <small class="text-danger">*</small></label>
                    <div style="height: 45px;">
                        <input
                            :type="confirmType ? 'text' : 'password'"
                            v-model="form.new_password_confirmed"
                            class="form-control"
                            id="confirmPassword"
                            placeholder="Enter password"/>
                        <font-awesome
                            :icon="confirmType ? 'eye' : 'eye-slash'"
                            class="pass"
                            @click="confirmType = !confirmType" />
                    </div>
                    <span class="text-danger" id="confirmPasswordMsg"></span>
                </div>
                <div class="form-group">
                    <p id="errorMsg" class="text-danger m-0"></p>
                    <p id="successMsg" class="text-succes m-0"></p>
                    <router-link to="/forget-password" id="resend" class="float-end nav-link p-0 forgot d-none text-dark pb-2" ><small>Resend?</small></router-link>
                    <router-link to="/login" id="login" class="float-end nav-link p-0 forgot d-none text-dark pb-2" ><small>Login?</small></router-link>
                </div>

                <div class="form-group">
                    <button type="button" class="btn btn-primary col-12" @click="resetPassword">Confirm</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { useHead } from "@vueuse/head";

export default {
    name: "ResetPassword",
    setup(){
        useHead({
            title: 'Reset Password | Sopma Tech'
        });
    },

    data(){
        return {
            newType: false,
            confirmType: false,
            form: {
                new_password: '',
                new_password_confirmed: '',
                token: this.$route.params.token,
            },
        };
    },

    methods: {
        resetPassword(){
            let newPasswordMsg = document.getElementById('newPasswordMsg');
            let newPassword = document.getElementById('newPassword');
            let confirmPasswordMsg = document.getElementById('confirmPasswordMsg');
            let confirmPassword = document.getElementById('confirmPassword');
            let isError = false;
            confirmPasswordMsg.innerHTML = newPasswordMsg.innerHTML = '';
            
            if(this.form.new_password == ''){
                newPasswordMsg.innerHTML = 'New password is required!';
                newPassword.classList.add("border-danger");
                isError = true;
            }else if(this.form.new_password.length < 6){
                newPasswordMsg.innerHTML = 'Password must contain at least 6 characters!';
                newPassword.classList.add("border-danger");
                isError = true;
            }
            if(this.form.new_password_confirmed == ''){
                confirmPasswordMsg.innerHTML = 'New password confirmed is required!';
                confirmPassword.classList.add("border-danger");
                isError = true;
            }else if(this.form.new_password_confirmed != this.form.new_password){
                confirmPasswordMsg.innerHTML = 'The new password and confirm password do not match!';
                confirmPassword.classList.add("border-danger");
                isError = true;
            }
            
            if(isError === false){
                this.$store.dispatch("passwordReset", this.form).then((response) => {
                    if (response.status == 200) {
                        document.getElementById('successMsg').innerHTML = response.data.message;
                        document.getElementById('login').classList.add('d-block-inline');
                        document.getElementById('login').classList.remove('d-none');
                    }else{
                        document.getElementById('errorMsg').innerHTML = response.data.message;
                    }
                })
                .catch((error) => {
                    document.getElementById('errorMsg').innerHTML = error.response.data.message;
                    if(error.response.status){
                        document.getElementById('resend').classList.add('d-block-inline');
                        document.getElementById('resend').classList.remove('d-none');
                    }
                });
            }
        },

        // errror reset
        errorReset(){
            document.getElementById('login').classList.remove('d-block-inline');
            document.getElementById('login').classList.add('d-none');
            document.getElementById('resend').classList.remove('d-block-inline');
            document.getElementById('resend').classList.add('d-none');
            document.getElementById('errorMsg').innerHTML = document.getElementById('successMsg').innerHTML = ''
        }
    },

    watch: {
        ["form.new_password"]() {
            document.getElementById('newPasswordMsg').innerHTML = '';
            document.getElementById('newPassword').classList.remove('border-danger');
            this.errorReset();
        },
        ["form.new_password_confirmed"]() {
            document.getElementById('confirmPasswordMsg').innerHTML = '';
            document.getElementById('confirmPassword').classList.remove('border-danger');
            this.errorReset();
        },
    }
}
</script>