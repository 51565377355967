<template>
  <div class="register col-md-12 px-0" @keyup.enter="update">
    <section class="after-header p-tb-10 bg-white p-3">
      <div class="c-intro">
        <ul class="breadcrumb mb-0">
          <li>
            <router-link to="/">
              <i class="material-icons" title="Home">home</i>
            </router-link>
          </li>
          <li>
            <router-link to="/account" class="text-capitalize">
              <span>Account</span>
            </router-link>
          </li>
          <li>
            <router-link to="/account/profile" class="text-capitalize">
              <span>Profile</span>
            </router-link>
          </li>
        </ul>
      </div>
    </section>
    
    <div class="col-md-12 bg-white mt-3">
      <div class="container ac-layout bg-white pt-3">
        <ul class="navbar-nav ac-navbar" style="height: 44px;">
          <li class="nav-item me-1">
            <router-link to="/account/orders" class="nav-link">
              <span class="material-icons">chrome_reader_mode</span>Orders
            </router-link>
          </li>
          <li class="nav-item mx-1 active">
            <router-link to="/account/profile" class="nav-link">
              <span class="material-icons">person</span>Profile
            </router-link>
          </li>
          <li class="nav-item mx-1">
            <router-link to="/account/password-change" class="nav-link">
              <span class="material-icons">lock</span>Change Password
            </router-link>
          </li>
          <li class="nav-item ms-1">
            <router-link to="/account/saved-pc-list" class="nav-link">
              <span class="material-icons">important_devices</span>Saved Pc
            </router-link>
          </li>
        </ul>


        <div class="order-details">
          <h4>Profile</h4>
          <form v-if="!edit" class="register-area d-block mx-auto border bg-white rounded my-2">
            <div class="p-3">
              <div class="form-group mt-3 row mx-0">
                <p class="border-bottom">
                  <font-awesome type="button"
                    icon="edit"
                    class="float-end p-1 nav-link mb-2 h4"
                    @click="edit = !edit" />
                </p>
                <div class="col-6 px-0 pe-1">
                  <label for="first_name" class="mb-0 text-dark">First Name</label>
                  <input
                    type="text"
                    :value="userInfo.user.first_name"
                    id="first_name"
                    class="form-control px-1"
                    readonly="true"
                  />
                </div>
                <div class="col-6 px-0 ps-1">
                  <label for="last_name" class="mb-0 text-dark">Last Name</label>
                  <input
                    type="text"
                    :value="userInfo.user.last_name"
                    id="last_name"
                    class="form-control px-1"
                    readonly="true"
                  />
                </div>
              </div>
              <div class="form-group mt-3">
                <label for="email" class="text-dark">E-mail</label>
                <input
                  type="text"
                  :value="userInfo.user.email"
                  id="email"
                  class="form-control"
                  readonly="true"
                />
              </div>
              <div class="form-group mt-3" style="height: 61px;">
                <label for="mobile" class="text-dark">Mobile</label>
                <input
                  type="text"
                  :value="userInfo.user.mobile"
                  id="mobile"
                  class="form-control ps-5"
                  readonly="true"
                />
                <span class="mobile">+88</span>
              </div>
              <div class="form-group mt-3">
                <label for="shipping_area_id" class="mb-0 text-dark">Region/State</label>
                  <input
                  type="text"
                  :value="userInfo.user.shipping_area"
                  id="mobile"
                  class="form-control"
                  readonly="true"
                />
              </div>
              <div class="form-group mt-3">
                <label for="address" class="d-block text-dark">Address</label>
                <input
                  type="text"
                  :value="userInfo.user.address"
                  class="form-control"
                  readonly="true"
                />
              </div>
              <div class="form-group mt-3">
                <label for="post_code" class="d-block text-dark">Post Code</label>
                <input
                  type="text"
                  :value="userInfo.user.post_code"
                  class="form-control"
                  readonly="true"
                />
              </div>
            </div>
          </form>
          <form v-else class="register-area d-block mx-auto border bg-white rounded my-2">
            <div class="p-3">
              <div class="form-group mt-3 row mx-0">
                <p class="border-bottom">
                  <span class="d-inline-block h5">Edit Profile</span>
                  <font-awesome type="button"
                    icon="close"
                    class="float-end p-1 nav-link mb-2 h4" style="color: red;"
                    @click="edit = !edit" />
                </p>
                <div class="col-6 px-0 pe-1">
                  <label for="first_name" class="mb-0 text-dark">First Name <small class="text-danger">*</small></label>
                  <input
                    type="text"
                    v-model="registerForm.first_name"
                    id="first_name"
                    class="form-control px-1"
                    placeholder="Enter first name"
                  />
                </div>
                <div class="col-6 px-0 ps-1">
                  <label for="last_name" class="mb-0 text-dark">Last Name <small class="text-danger">*</small></label>
                  <input
                    type="text"
                    v-model="registerForm.last_name"
                    id="last_name"
                    class="form-control px-1"
                    placeholder="Enter last name"
                  />
                </div>
              </div>
              <div class="form-group mt-3">
                <label for="email" class="text-dark">E-mail <small class="text-danger">*</small></label>
                <input
                  type="text"
                  v-model="registerForm.email"
                  id="email"
                  class="form-control"
                  placeholder="Enter email number"
                />
              </div>
              <div class="form-group mt-3" style="height: 61px;">
                <label for="mobile" class="text-dark">Mobile <small class="text-danger">*</small></label>
                <input
                  type="text"
                  v-model="registerForm.mobile"
                  id="mobile"
                  class="form-control ps-5"
                  placeholder="Enter mobile number"
                />
                <span class="mobile">+88</span>
              </div>
              <div class="form-group mt-3">
                <label for="shipping_area_id" class="mb-0 text-dark">Region/State <small class="text-danger">*</small></label>
                <select
                  id="shipping_area_id"
                  class="form-control form-select"
                  v-model="registerForm.shipping_area_id">
                  <option value="">Select Region/State</option>
                  <option
                    v-for="shippingArea in shippingAreas"
                    :key="shippingArea.shipping_area_id"
                    :value="shippingArea.id"
                    :selected="shippingArea.id == userInfo.user.shipping_area_id ? 'true' : 'false'">
                    {{ shippingArea.shipping_area }}
                  </option>
                </select>
              </div>
              <div class="form-group mt-3">
                <label for="address" class="d-block text-dark">Address <small class="text-danger">*</small></label>
                <input
                  type="text"
                  v-model="registerForm.address"
                  class="form-control"
                  placeholder="Enter address"
                />
              </div>
              <div class="form-group mt-3">
                <label for="post_code" class="d-block text-dark">Post Code <small class="text-danger">*</small></label>
                <input
                  type="text"
                  v-model="registerForm.post_code"
                  class="form-control"
                  placeholder="Enter post code"
                />
              </div>
              <div class="form-group row">
                <div class="col-4 mt-3">
                  <button type="reset" class="btn st-outline col-12">Clear</button>
                </div>
                <div class="col-4 mt-3">
                  <button
                    type="button"
                    class="btn st-outline col-12"
                    @click="edit = !edit"
                  >
                    Cancel
                  </button>
                </div>
                <div class="col-4 mt-3">
                  <button
                    type="button"
                    class="btn btn-primary col-12"
                    @click="update"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- loading view -->
    <LoadingView v-if="showLoading"/>

  </div>
</template>

<script>
import LoadingView from '@/components/Layouts/LoadingView.vue';
import { useHead } from "@vueuse/head";
export default {
  name: "AccountView",
  props: ["userInfo"],
  components: {
    LoadingView
  },

  // meta tag setup start
  setup() {
    useHead({
      title: 'Profile | Sopma Tech',
    });
  },
  // meta tag setup end

  data() {
    return {
      showLoading: true,
      shippingAreas: [],
      edit: false,
      eye: false,
      registerForm: {
        customer_id: this.userInfo.user.customer_id,
        first_name: this.userInfo.user.first_name,
        last_name: this.userInfo.user.last_name,
        mobile: this.userInfo.user.mobile,
        email: this.userInfo.user.email,
        address: this.userInfo.user.address,
        post_code: this.userInfo.user.post_code,
        shipping_area_id: this.userInfo.user.shipping_area_id,
      },
    };
  },
  methods: {
    getDistrict() {
      this.showLoading = true;
      this.$store
        .dispatch("getShippingArea")
        .then((response) => {
          this.shippingAreas = response.data.data;
          this.showLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    update() {
      var error = "";
      const mobExp = /^[0-9]*$/;
      const emailRegex = RegExp(
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      );
      if (!this.registerForm.first_name.length) {
        error += '<p class="text-danger mb-0">First name is required!</p>';
      }
      if (!this.registerForm.last_name.length) {
        error += '<p class="text-danger mb-0">Last name is required!</p>';
      }
      if (this.registerForm.email == "") {
        error += '<p class="text-danger mb-0">E-mail is required!</p>';
      } else if (!emailRegex.test(this.registerForm.email)) {
        error += '<p class="text-danger mb-0">E-mail is invalid!</p>';
      }
      if (this.registerForm.mobile == "") {
        error += '<p class="text-danger mb-0">Mobile number is required!</p>';
      } else if (mobExp.test(this.registerForm.mobile) == false) {
        error += '<p class="text-danger mb-0">Only number is allowed!</p>';
      } else if (
        this.registerForm.mobile.length < 11 ||
        this.registerForm.mobile.length > 11
      ) {
        error += '<p class="text-danger mb-0">Mobile number is invalid!</p>';
      }
      if (this.registerForm.shipping_area_id == "") {
        error += '<p class="text-danger mb-0">District is required!</p>';
      }
      if (!this.registerForm.address.length) {
        error += '<p class="text-danger mb-0">Address is required!</p>';
      }
      if (!this.registerForm.post_code) {
        error += '<p class="text-danger mb-0">Post code is required!</p>';
      }
      if (error != "") {
        this.$swal({
          title: "REQUIRED!",
          icon: "error",
          html: error,
        });
      } else {
        this.$store
          .dispatch("profileUpdate", this.registerForm)
          .then((response) => {
            if (response.status == 200) {
              this.$emit("loginReload");
              var suc =
                '<p class="text-success mb-0">' +
                response.data.message +
                "</p>";
              this.$swal({
                title: "Success!",
                icon: "success",
                html: suc,
              });
              this.edit = !this.edit;
            } else {
              var error =
                '<p class="text-danger mb-0">' + response.data.message + "</p>";
              this.$swal({
                title: "Warning!",
                icon: "error",
                html: error,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  created() {
    this.getDistrict();
  },
};
</script>
