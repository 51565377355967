<template>
    <div class="col-md-12 mt-2 mb-5 px-0">
        <section class="after-header p-tb-10 bg-white p-3">
            <div class="c-intro">
                <ul class="breadcrumb mb-0">
                    <li>
                        <router-link to="/">
                            <i class="material-icons" title="Home">home</i>
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="'/product-search/' + searchText">
                            <span>Search Products</span>
                        </router-link>
                    </li>
                    <li>
                    <router-link 
                        :to="'/product-search/' + searchText" class="text-capitalize">
                        <span>{{ searchText.slice(0,30) }}{{ searchText.length>30 ? '...':'' }}</span>
                    </router-link>
                    </li>
                </ul>
                <!-- <div class="child-list">
                    <a href="pc">Desktop</a>       
                </div> -->
            </div>
        </section>
    
        <section class="p-item-page bg-bt-gray p-tb-15 px-3">
        <div class="row">
            <div id="content" class="col-xs-12 col-md-9 product-listing">
                <div class="top-bar ws-box">
                    <div class="row">
                        <div class="col-sm-4 col-xs-2 actions">
                            <label class="page-heading m-hide text-capitalize">{{ searchText }}</label>
                        </div>
                        <div class="col-sm-8 col-xs-10 show-sort">
                            <div class="form-group rs-none">
                                <label>Show:</label>
                                <div class="custom-select">
                                    <select id="input-limit" class="limit" v-model="requestData.limit">
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="40">40</option>
                                        <option value="50">50</option>
                                        <option value="80">80</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Sort By:</label>
                                <div class="custom-select">
                                    <select id="input-sort" class="sort" v-model="requestData.order">
                                        <option value="">Default</option>
                                        <option value="asc">Price (Low &gt; High)</option>
                                        <option value="desc">Price (High &gt; Low)</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="main-content p-items-wrap" v-if="products.data">
                    <div class="p-item" v-for="product in products.data" :key="product.product_id">
                    <div class="p-item-inner">
                        <div class="marks" v-if="product.discount > 0" >
                        <span class="mark">Save: {{ product.discount.toLocaleString() }}৳</span>                                        
                        </div>
                        <div class="p-item-img">
                        <router-link 
                            :to="{
                            name: 'productDetails',
                            params: {
                                productSlug: product.slug,
                            },
                            }">
                            <img :src="product.thumbnail" :alt="product.product_name" width="228" height="228">
                        </router-link>
                        </div>
                        <div class="p-item-details">
                            <h4 class="p-item-name"> 
                                <router-link 
                                    :to="{
                                    name: 'productDetails',
                                    params: {
                                        productSlug: product.slug,
                                    },
                                    }">{{ product.product_name }}</router-link>
                            </h4>
                            <div class="short-description" v-html="product.key_features"></div>
                            <div class="p-item-price">
                                <span class="price-new">{{ product.sale_price.toLocaleString() }}৳</span> 
                                <small v-if="product.discount>0">
                                    <del class="text-secondary ps-2">{{ product.original_price.toLocaleString() }}৳</del>
                                </small>                      
                            </div>
                            <div class="actions">
                                <span v-if="product.stock_status == 2" class="st-btn stock-status">Stock Out</span>
                                <span v-else-if="product.stock_status == 3" class="st-btn stock-status">Up Coming</span>
                                <template v-else>
                                    <router-link
                                    v-if="product.variation_type"
                                    class="st-btn btn-add-cart" type="button"
                                    :to="{
                                        name: 'productDetails',
                                        params: {
                                        productSlug: product.slug,
                                        },
                                    }"
                                    >
                                    <i class="material-icons">shopping_cart</i> Buy Now
                                    </router-link>
                                    <span v-else class="st-btn btn-add-cart" type="button" @click="addToCart(product)">
                                    <i class="material-icons">shopping_cart</i> Buy Now
                                    </span>
                                </template>
                                <span class="st-btn btn-compare" @click="addtoComparef(product)">
                                    <i class="material-icons">library_add</i>Add to Compare
                                </span>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="d-block" v-else>
                    <h4 class="text-center py-5">Product Not Found!</h4>
                </div>
                <div class="bottom-bar">
                    <div class="row px-3" v-if="products.total">
                    <div class="col-md-12 bg-white rounded p-2 shadow">
                        <div v-if="products.last_page > 1" class="d-inline-block">
                        <div class="d-inline-block" v-if="products.current_page == 1">
                            <button class="btn btn-sm btn-success me-2 mt-1 page-btn">
                            PREV
                            </button>
                        </div>
                        <div class="d-inline-block" v-else>
                            <button
                            class="btn btn-sm btn-success me-2 mt-1 page-btn"
                            @click="paginationPro(products.current_page - 1)"
                            >
                            PREV
                            </button>
                        </div>
                        <div
                            class="d-inline-block"
                            v-for="page in products.last_page"
                            :key="page"
                        >
                            <button
                            v-if="
                                page > products.current_page - 4 &&
                                page < products.current_page + 4
                            "
                            class="btn btn-sm link-btn me-2 mt-1"
                            :class="page == products.current_page ? 'link-active' : ''"
                            @click="paginationPro(page)"
                            >
                            {{ page }}
                            </button>
                        </div>
                        <div
                            class="d-inline-block"
                            v-if="products.current_page == products.last_page"
                        >
                            <button class="btn btn-sm btn-success mt-1 page-btn">
                            NEXT
                            </button>
                        </div>
                        <div class="d-inline-block" v-else>
                            <button
                            class="btn btn-sm btn-success mt-1 page-btn"
                            @click="paginationPro(products.current_page + 1)"
                            >
                            NEXT
                            </button>
                        </div>
                        </div>
                        <div class="page-number d-inline-block float-end">
                        <p class="mt-1 mb-0">
                            <small class="fw-bold page-btn"
                            >Showing {{ products.from }} to {{ products.to }} of
                            {{ products.total }} ({{ products.last_page }}
                            {{ products.last_page > 1 ? "Pages" : "Page" }})</small
                            >
                        </p>
                        </div>
                    </div>
                    </div>
                </div> 
            </div>
        </div>
        </section>
  
      <!-- alert modal  -->
      <ProductAddAlert
        :modalAlert="addAlert"
        :alertMsg="alertMsg"
        @modalClose="modalClose"
      />
  
      <!-- loading view -->
      <LoadingView v-if="showLoading"/>
  
    </div>
</template>
<script>
    import ProductAddAlert from "@/components/ProductAddAlert.vue";
    import LoadingView from '@/components/Layouts/LoadingView.vue';
    import { reactive, watch, computed, ref } from 'vue';
    import { useHead } from "@vueuse/head";

    export default{
        name: 'SearchProducts',
        components: {
            ProductAddAlert, LoadingView
        },
        
        // meta tag setup start
        setup() {
            // Define reactive properties within the setup function
            const siteTitle = ref('siteTitle');
            const siteData = reactive({
            title: `Search - `+siteTitle.value +` | Sopma Tech`,
            description: "Sopma Tech is the Best Laptop, Computer, Gaming PC, Component, Accessories, and Gadget retail &amp; Online shop in Bangladesh. Sopma Tech offers the Latest Tech products with the most competitive price in BD.",
            keywords: "Laptop shop in Bangladesh, Laptop shop in bd, computer shop in Bangladesh, PC shop in Bangladesh, computer shop in BD, Gaming PC shop in Bangladesh, PC accessories shop in Bangladesh, best computer shop in Bangladesh, Gadget shop in bd, Gadget Shop in Bangladesh, Online Shop in BD, online computer shop in bd, computer accessories online shop in Bangladesh, computer parts shop in bd, Laptop in Bangladesh, Notebook, Laptop, Desktop, Brand PC, computer, computer store Bangladesh, laptop store Bangladesh, gaming, desktop, monitor, Sopma Tech, computer accessories, Desktop accessories, Laptop accessories, Laptop Online Store in BD, adata, apacer, apple, asus, bangladesh, baseus, belkin, benq, best, boya, brother, cable, camera, canon, GPU, graphics card, Sopma Tech &amp; Engineering Ltd, SOPMATECH, laptop price, laptop price in bangladesh, laptop price bd,",
            });

            // Watch for changes in siteTitle and update siteData.title accordingly
            watch(siteTitle, (newSiteTitle) => {
                siteData.title = `Search - `+newSiteTitle +` | Sopma Tech`
            });

            useHead({
                title: computed(() => siteData.title),
                meta: [
                    {
                        name: 'description',
                        content: computed(() => siteData.description),
                    },
                    {
                        name: 'keywords',
                        content: computed(() => siteData.keywords),
                    },
                ],
            });

            // Access siteTitle directly in setup
            return {
                siteTitle,
            };
        },
        // meta tag setup end

        data(){
            return{
                showLoading: true,
                addAlert: "",
                alertMsg: "",
                loading: false,
                pagePar: '',
                searchText: this.$route.params.searchText,
                products: [],
                requestData: {
                    limit: 20,
                    order: "",
                    stock_status: [],
                    searchText: this.$route.params.searchText,
                    page: 1,
                },
            }
        },
        methods:{
            // product add to compare function
            addtoComparef(product) {
            this.$store.dispatch("addtoCompare", product);
            },

            // product add to cart function
            addToCart(product) {
                let cartForm = {
                    qty: 1,
                    product: product,
                    cartId: product.product_id,
                };
                this.$store.dispatch("addToCart", cartForm);
                this.alertMsg = product.product_name;
                this.addAlert = "show d-block";
            },

            modalClose() {
                this.addAlert = "";
            },
            
            paginationPro(page) {
                this.requestData.page = page;
                this.$store.dispatch("SearchProduct", this.requestData).then((response) => {
                    this.products = response.data;
                })
                .catch((error) => {
                    console.log(error);
                    this.products = [];
                });
            },

            getProducts(){
                // on page loading
                this.showLoading = true;
                this.requestData.page = 1; 
                this.$store.dispatch('SearchProduct',this.requestData).then(response => {
                    this.products = response.data;     
                    this.searchText = this.$route.params.searchText;       
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    // off page loading
                    this.showLoading = false;
                });
            },

        },
        created(){
            // this.getProducts();
            this.siteTitle = this.searchText;
        },
        mounted(){
            this.getProducts();
        },
        watch: {
            $route(){
                this.getProducts();
            }, 
            ['requestData.order'](){
                this.getProducts();
            },
            ['requestData.limit'](){
                this.getProducts();
            },
        }
    }
</script>