<template>
  <div class="login col-md-12 px-0" @keyup.enter="login">
    <section class="after-header p-tb-10 bg-white p-3">
        <div class="c-intro">
            <ul class="breadcrumb mb-0">
                <li>
                    <router-link to="/">
                    <i class="material-icons" title="Home">home</i>
                    </router-link>
                </li>
                <li>
                    <router-link to="login" class="text-capitalize">
                        <span>Account Login</span>
                    </router-link>
                </li>
            </ul>
        </div>
    </section>

    <form class="login-area d-block mx-auto border rounded my-5">
        <div class="form-group row m-0">
            <div class="col-6 py-2 text-center" type="button" :class="mobile? 'activelog':''" @click="loginType">Login With Mobile</div>
            <div class="col-6 py-2 text-center" type="button" :class="!mobile? 'activelog':''" @click="loginType">Login With E-mail</div>
        </div>
        <div class="p-3 bg-white">
            <p v-if="message" class="py-2 text-success">The account has been verified successfully.</p>
            <div class="form-group mt-3">
                <label for="mobileEmail" class="text-secondary ">{{ mobile? 'Mobile':'E-mail' }} <small class="text-danger">*</small></label>
                <input type="text" v-model="loginForm.user_name" class="form-control" :class="mobile? 'ps-5':''" :placeholder="mobile? 'Enter mobile number':'Enter e-mail address'" id="mobileEmail">
                <span v-if="mobile"  class="mobile">+88</span>
                <span class="text-danger position-absolute" id="mobileEmailMsg" style="left: 0;"></span>
            </div>
            <div class="form-group mt-3">
                <label class="text-secondary d-block">Password <small class="text-danger">*</small> 
                <router-link to="/forget-password" class="float-end nav-link p-0 forgot d-inline-block text-secondary" >Forgot Password?</router-link></label>
                <input type="password" v-model="loginForm.password" class="form-control" placeholder="Enter password" id="password">
                <span class="text-danger" id="passwordMsg"></span>
            </div>
            <p class="text-danger" id="errorMsg"></p>
            <div class="form-group row">
                <div class="col-6 mt-3">
                    <button type="reset" class="btn st-outline col-12">Clear</button>                
                </div>
                <div class="col-6 mt-3">
                    <button type="button" class="btn btn-primary col-12" @click="login" >Login</button>                
                </div>
            </div>
            <div class="form-group mt-4">
                <p>Don't have an account? <router-link to="/register" class="float-end" >Create New Account</router-link></p>
            </div>
        </div>
    </form>
  </div>
</template>

<script>
import { useHead } from "@vueuse/head";
export default {
  name: 'LoginView',
  // meta tag setup start
  setup() {
    useHead({
      title: 'Sopma Tech | Login',
    });
  },
  // meta tag setup end

  data(){
    return{
        showLoading: true,
        mobile: true,
        redirectUrl: this.$route.params.redirectUrl,
        message: this.$route.params.message,
        loginForm: {
            user_name: '',
            mobile: '',
            email: '',
            password: '',
            login_from: 'webLogin',
        },
    }
  },
  methods:{
    loginType(){
        this.mobile = !this.mobile;
        this.loginForm.user_name = '';
        this.loginForm.mobile = '';
        this.loginForm.email = '';
        this.loginForm.password = '';
    },
    login(){
        var isError = false;
        let mobileEmail = document.getElementById('mobileEmail');        
        let mobileEmailMsg = document.getElementById('mobileEmailMsg');        
        let password = document.getElementById('password');        
        let passwordMsg = document.getElementById('passwordMsg');        
        let errorMsg = document.getElementById('errorMsg');        
        if (this.mobile) {
            const mobExp = /^[0-9]*$/;
            this.loginForm.mobile = this.loginForm.user_name;
            if (this.loginForm.mobile == '') {
                isError = true;
                mobileEmailMsg.innerHTML = 'Mobile number is required!';
                mobileEmail.classList.add('border-danger');
            }else if (mobExp.test(this.loginForm.mobile) == false) {
                isError = true;
                mobileEmailMsg.innerHTML = 'Only number is allowed!';
                mobileEmail.classList.add('border-danger');
            }else if(this.loginForm.mobile.length < 11 || this.loginForm.mobile.length > 11){
                isError = true;
                mobileEmailMsg.innerHTML = 'Mobile number is invalid!';
                mobileEmail.classList.add('border-danger');
            } 
        } else {
            const emailRegex = RegExp(
                /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            );            
            this.loginForm.email = this.loginForm.user_name;
            if(this.loginForm.email == ''){
                isError = true;
                mobileEmailMsg.innerHTML = 'E-mail is required!';
                mobileEmail.classList.add('border-danger');
            }else if (!emailRegex.test(this.loginForm.email)) {
                isError = true;
                mobileEmailMsg.innerHTML = 'E-mail is invalid!';
                mobileEmail.classList.add('border-danger');
            }            
        }
        if(this.loginForm.password == ''){
            isError = true;
            passwordMsg.innerHTML = 'Password is required!';
            password.classList.add("border-danger");
        }else if(this.loginForm.password.length <6){
            isError = true;
            passwordMsg.innerHTML = 'Enter at least 6 digits password!';
            password.classList.add("border-danger");
        }
        console.log(mobileEmail,mobileEmailMsg,password,passwordMsg);
        if(isError == false){
            this.$store.dispatch("UserLogin",this.loginForm).then(response => { 
                if (response.status == 200) {
                    this.$emit('loginReload');
                    if(this.redirectUrl){
                        this.$router.push(this.redirectUrl);
                    }else{
                        this.$router.push('/account');
                    }
                }else{
                    errorMsg.innerHTML = response.data.message;
                }  
            })
            .catch(error => {
                errorMsg.innerHTML = error.response.data.message;
                if(error.response.status == 403){
                    this.$router.push({
                        name: 'verifyAccount',
                        params: { token: btoa(error.response.data.data.token) }
                    });
                }
            })
        }
    },
  },
  mounted(){
    let user = JSON.parse(localStorage.getItem('userInfo'));
    if (user) {
        this.$router.back();
    }
  },

  watch: {
    ['loginForm.password'](newValue) {
        let password = document.getElementById('password');        
        let passwordMsg = document.getElementById('passwordMsg'); 
        let errorMsg = document.getElementById('errorMsg'); 
        if(newValue.length){
            password.classList.remove('border-danger');
        }else{
            password.classList.add('border-danger');
        }
        passwordMsg.innerHTML = '';
        errorMsg.innerHTML = '';
    },

    ['loginForm.user_name'](newValue) {
        let mobileEmail = document.getElementById('mobileEmail');        
        let mobileEmailMsg = document.getElementById('mobileEmailMsg'); 
        let errorMsg = document.getElementById('errorMsg'); 
        if(newValue.length){
            mobileEmail.classList.remove('border-danger');
        }else{
            mobileEmail.classList.add('border-danger');
        }
        mobileEmailMsg.innerHTML = '';
        errorMsg.innerHTML = '';
    }
  }
}
</script>
