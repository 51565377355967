<template>
  <div id="profile" class="col-md-12 px-0">
    <section class="after-header p-tb-10 bg-white p-3">
      <div class="c-intro">
        <ul class="breadcrumb mb-0">
          <li>
            <router-link to="/">
              <i class="material-icons" title="Home">home</i>
            </router-link>
          </li>
          <li>
            <router-link to="/account" class="text-capitalize">
              <span>Account</span>
            </router-link>
          </li>
        </ul>
      </div>
    </section>

    <div class="col-md-12 bg-white">
      <div class="container ac-layout bg-white">
        <div class="ac-header mt-3 pt-3">
          <div class="left">
            <span class="avatar bg-secondary d-inline-block rounded-circle px-3 py-2">
              <font-awesome icon="user" class="h2 text-white"/>
            </span>
            <div class="name"><p>Hello,</p><p class="user">Sajol karmakar</p></div>
          </div>
          <div class="right" style="justify-content: end;">
            <div class="balance w-auto">
              <span class="material-icons logout-icon px-3 me-3" type="button" @click="logout" title="Logout">input</span>
            </div>
            <!-- <div class="balance">
              <span class="blurb">Points</span>
              <span class="amount">0</span>
            </div>
            <div class="balance">
              <span class="blurb">Store Credit</span>
              <span class="amount">0</span>
            </div> -->
          </div>
        </div>

        <div class="ac-menus">
          <div class="ac-menu-item">
            <router-link to="/account/orders" class="ico-btn"><span class="material-icons">chrome_reader_mode</span><span>Orders</span></router-link>
          </div>
          <div class="ac-menu-item">
            <router-link to="/account/profile" class="ico-btn"><span class="material-icons">person</span><span> Profile</span></router-link>
          </div>
          <div class="ac-menu-item">
            <router-link to="/account/password-change" class="ico-btn"><span class="material-icons">lock</span><span>Change Password</span></router-link>
          </div>
          <div class="ac-menu-item">
              <router-link to="/account/saved-pc-list" class="ico-btn"><span class="material-icons">important_devices</span><span>Saved PC</span></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useHead } from "@vueuse/head";
export default {
  name: "AccountView",
  props: ["userInfo"],

  // meta tag setup start
  setup() {
    useHead({
      title: 'Sopma Tech | Account',
    });
  },
  // meta tag setup end

  data() {
    return {
      user: "",
    };
  },
  methods: {
    logout() {
      this.$emit("logout");
    },
  },
  created() {
    // this.profile();
    // console.log('user')
  },
};
</script>