<template>
  <div id="offer" class="col-md-12 mt-2 mb-5 px-0">
    <section class="after-header p-tb-10 bg-white p-3">
      <div class="c-intro">
        <ul class="breadcrumb mb-0">
          <li>
            <router-link to="/">
              <i class="material-icons" title="Home">home</i>
            </router-link>
          </li>
          <li>
            <router-link 
              to="pc-builder" class="text-capitalize">
              <span>PC Builder</span>
            </router-link>
          </li>
        </ul>
      </div>
    </section>

    <section class="build-your-pc bg-bt-gray ">
      <div class="container">
        <div class="pcb-container rounded shadow border-0">
          <div class="pcb-head">
            <div class="actions">
              <div class="all-actions">
                <div class="action d-flex w-auto">
                  <div class="input-group">
                    <input type="text" :value="pcBuilderComponentCart.totalAmount" aria-describedby="budgetPc" id="budgetPcInput">
                    <router-link :to="{
                        name: 'BudgetPc',
                        params: {
                          maxAmount: pcBuilderComponentCart.totalAmount,
                        },
                      }" class="input-group-text btn px-2" id="budgetPc">
                      <font-awesome icon="desktop" class="pe-2" /> Budget PC
                    </router-link>
                  </div>
                </div>
                <button type="button" class="action" @click="addToCart">
                  <font-awesome icon="cart-arrow-down" class="hisize" />
                    <span class="action-text">Add to Cart</span>
                </button>
                <button type="button" class="action" @click="savePc">
                  <font-awesome icon="floppy-disk" class="hisize" />
                  <span class="action-text">Save PC</span>
                </button>
                <!-- <a class="action m-hide">
                  <i class="material-icons">print</i>
                  <span class="action-text">Print</span>
                </a>
                <form action="" target="_blank" method="post">
                  <input type="hidden" value="">
                  <button type="submit" class="action">
                    <i class="material-icons">camera</i>
                    <span class="action-text">Screenshot</span>
                  </button>
                </form> -->
                <!-- <a class="pcb-button btn st-outline" >Get a Quote</a> -->
              </div>
            </div>
          </div>

            <div class="pcb-inner-content">
                <div class="pcb-content">
                  <div class="bg-secondary py-1 text-white ps-3 fw-bold">Core Components</div>
                  <template v-for="component in coreComponents" :key="component.id">
                    <template v-if="pcBuilderComponentCart['componentId'+component.category.id]">
                      <div v-for="(item, index) in pcBuilderComponentCart['componentId'+component.category.id].products" :key="item.product.id" class="c-item selected">
                        <div class="img">
                          <img :src="item.product.thumbnail" :alt="item.product.product_name" :title="item.product.product_name" width="80" height="80">
                        </div>
                        <div class="details">
                          <div class="component-name">
                            <span>{{ component.category.category_name }}</span>
                            <span v-if="component.required" class="mark">Required</span>
                          </div>
                          <div class="product-name">{{ item.product.product_name }}</div>
                          <div class="pcb-add-another" v-if="(component.max_qty>1) && (pcBuilderComponentCart['componentId'+component.category.id].products.length < component.max_qty) && (index === pcBuilderComponentCart['componentId'+component.category.id].products.length - 1)">
                            <router-link :to ="{
                                name: 'PcBuilderComponets',
                                params: {
                                  maxQty: component.max_qty,
                                  componentId: component.category.id,
                                  slug: component.category.slug,
                                },
                              }">+ Add Another {{ component.category.category_name }}</router-link>
                          </div>
                          <!-- <div class="product-watt">
                            <img src="" alt="Wattage Icon">
                            <span>w</span>                                 
                            <span> - </span>                                 
                            <span>w</span>                             
                          </div> -->
                        </div>
                        <div class="item-price">
                          <div class="price">{{ item.product.sale_price.toLocaleString() }}৳</div>
                        </div>
                        <div class="actions">
                          <div class="action-items d-flex justify-content-center" v-if="pcBuilderComponentCart['componentId'+component.category.id].multiple">
                            <span class="action" type="button" v-on:click="removePcComponent(component.category.id,item.id)" title="Remove" style="width: 24px;">
                              <i class="material-icons">clear</i>
                            </span>
                            <router-link class="action" :to ="{
                                name: 'PcBuilderComponets',
                                params: {
                                  maxQty: component.max_qty,
                                  componentId: component.category.id,
                                  slug: component.category.slug,
                                  id: item.id
                                },
                              }"  title="Choose" style="width: 24px;">
                              <i class="material-icons">autorenew</i>
                            </router-link>
                          </div>
                          <div class="action-items d-flex justify-content-center" v-else>
                            <span class="action" type="button" v-on:click="removePcComponent(component.category.id)" title="Remove" style="width: 24px;">
                              <i class="material-icons">clear</i>
                            </span>
                            <router-link class="action" :to ="{
                                name: 'PcBuilderComponets',
                                params: {
                                  maxQty: component.max_qty,
                                  componentId: component.category.id,
                                  slug: component.category.slug,
                                },
                              }"  title="Choose" style="width: 24px;">
                              <i class="material-icons">autorenew</i>
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </template>
                    <div v-else class="c-item blank">
                      <div class="img">
                        <span class="img-ico monitor"></span>
                      </div>
                      <div class="details">
                        <div class="component-name">
                          <span>{{ component.category.category_name }}</span>
                          <span v-if="component.required" class="mark">Required</span>
                        </div>
                        <div class="product-name"></div>
                      </div>
                      <div class="item-price"></div>
                      <div class="actions">
                        <router-link class="btn st-outline"  :to ="{
                            name: 'PcBuilderComponets',
                            params: {
                              maxQty: component.max_qty,
                              componentId: component.category.id,
                              slug: component.category.slug,
                            },
                          }" >Choose</router-link>
                      </div>
                    </div>
                  </template>

                  <div class="bg-secondary py-1 text-white ps-3 fw-bold">Peripherals & Others</div>               
                  <template v-for="component in peripheralsComponents" :key="component.id">
                    <template v-if="pcBuilderComponentCart['componentId'+component.category.id]">
                      <div v-for="(item, index) in pcBuilderComponentCart['componentId'+component.category.id].products" :key="item.product.id" class="c-item selected">
                        <div class="img">
                          <img :src="item.product.thumbnail" :alt="item.product.product_name" :title="item.product.product_name" width="80" height="80">
                        </div>
                        <div class="details">
                          <div class="component-name">
                            <span>{{ component.category.category_name }}</span>
                            <span v-if="component.required" class="mark">Required</span>
                          </div>
                          <div class="product-name">{{ item.product.product_name }}</div>
                          <div class="pcb-add-another" v-if="(component.max_qty>1) && (pcBuilderComponentCart['componentId'+component.category.id].products.length < component.max_qty) && (index === pcBuilderComponentCart['componentId'+component.category.id].products.length - 1)">
                            <router-link :to ="{
                                name: 'PcBuilderComponets',
                                params: {
                                  maxQty: component.max_qty,
                                  componentId: component.category.id,
                                  slug: component.category.slug,
                                },
                              }">+ Add Another {{ component.category.category_name }}</router-link>
                          </div>
                          <!-- <div class="product-watt">
                            <img src="" alt="Wattage Icon">
                            <span>w</span>                                 
                            <span> - </span>                                 
                            <span>w</span>                             
                          </div> -->
                        </div>
                        <div class="item-price">
                          <div class="price">{{ item.product.sale_price.toLocaleString() }}৳</div>
                        </div>
                        <div class="actions">
                          <div class="action-items d-flex justify-content-center" v-if="pcBuilderComponentCart['componentId'+component.category.id].multiple">
                            <span class="action" type="button" v-on:click="removePcComponent(component.category.id,item.id)" title="Remove" style="width: 24px;">
                              <i class="material-icons">clear</i>
                            </span>
                            <router-link class="action" :to ="{
                                name: 'PcBuilderComponets',
                                params: {
                                  maxQty: component.max_qty,
                                  componentId: component.category.id,
                                  slug: component.category.slug,
                                  id: item.id
                                },
                              }"  title="Choose" style="width: 24px;">
                              <i class="material-icons">autorenew</i>
                            </router-link>
                          </div>
                          <div class="action-items d-flex justify-content-center" v-else>
                            <span class="action" type="button" v-on:click="removePcComponent(component.category.id)" title="Remove" style="width: 24px;">
                              <i class="material-icons">clear</i>
                            </span>
                            <router-link class="action" :to ="{
                                name: 'PcBuilderComponets',
                                params: {
                                  maxQty: component.max_qty,
                                  componentId: component.category.id,
                                  slug: component.category.slug,
                                },
                              }"  title="Choose" style="width: 24px;">
                              <i class="material-icons">autorenew</i>
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </template>
                    <div v-else class="c-item blank">
                      <div class="img">
                        <span class="img-ico monitor"></span>
                      </div>
                      <div class="details">
                        <div class="component-name">
                          <span>{{ component.category.category_name }}</span>
                          <span v-if="component.required" class="mark">Required</span>
                        </div>
                        <div class="product-name"></div>
                      </div>
                      <div class="item-price"></div>
                      <div class="actions">
                        <router-link class="btn st-outline"  :to ="{
                            name: 'PcBuilderComponets',
                            params: {
                              maxQty: component.max_qty,
                              componentId: component.category.id,
                              slug: component.category.slug,
                            },
                          }" >Choose</router-link>
                      </div>
                    </div>
                  </template>               
                </div>
            </div>
        </div>
      </div>
    </section>

    <!-- alert modal  -->
    <ProductAddAlert
      :modalAlert="addAlert"
      :alertMsg="alertMsg"
      @modalClose="modalClose"
    />

    <!-- loading view -->
    <LoadingView v-if="showLoading"/> 

  </div>
</template>
<script>
import ProductAddAlert from "@/components/ProductAddAlert.vue";
import LoadingView from '@/components/Layouts/LoadingView.vue';
import { reactive, computed } from 'vue';
import { useHead } from "@vueuse/head";

export default {
  name: "PcBuilder",
  props: ["userInfo"],
  components: {
    ProductAddAlert, LoadingView
  },

  // meta tag setup start
  setup() {
    // Define reactive properties within the setup function
    const siteData = reactive({
      title: 'PC Builder - Build Your Own Computer | Build Your Own Customize Desktop Computer | Sopma Tech',
      description: "Sopma Tech is the Best Laptop, Computer, Gaming PC, Component, Accessories, and Gadget retail &amp; Online shop in Bangladesh. Sopma Tech offers the Latest Tech products with the most competitive price in BD.",
      keywords: "Laptop shop in Bangladesh, Laptop shop in bd, computer shop in Bangladesh, PC shop in Bangladesh, computer shop in BD, Gaming PC shop in Bangladesh, PC accessories shop in Bangladesh, best computer shop in Bangladesh, Gadget shop in bd, Gadget Shop in Bangladesh, Online Shop in BD, online computer shop in bd, computer accessories online shop in Bangladesh, computer parts shop in bd, Laptop in Bangladesh, Notebook, Laptop, Desktop, Brand PC, computer, computer store Bangladesh, laptop store Bangladesh, gaming, desktop, monitor, Sopma Tech, computer accessories, Desktop accessories, Laptop accessories, Laptop Online Store in BD, adata, apacer, apple, asus, bangladesh, baseus, belkin, benq, best, boya, brother, cable, camera, canon, GPU, graphics card, Sopma Tech &amp; Engineering Ltd, SOPMATECH, laptop price, laptop price in bangladesh, laptop price bd,",
    });

    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: 'description',
          content: computed(() => siteData.description),
        },
        {
          name: 'keywords',
          content: computed(() => siteData.keywords),
        },
      ],
    });
  },
  // meta tag setup end

  data() {
    return {
      showLoading: true,
      addAlert: "",
      alertMsg: "",
      coreComponents: [],
      peripheralsComponents: [],
      pcBuilderComponentCart: [],
      errorMsg: [],
    };
  },
  mounted() {
    this.getPcBuilderComponents();
    this.pcBuilderComponentCart = this.$store.getters.pcBuilderComponentCart;
    // let cart = JSON.parse(localStorage.getItem('pcBuilderComponentCart'));
    //     console.log(cart);
  },
  methods: {
    getPcBuilderComponents() {
      // on page loading
      this.showLoading = true;

      this.$store
        .dispatch("getPcBuilderComponents")
        .then((response) => {
          this.coreComponents = response.data.coreComponents;
          this.peripheralsComponents = response.data.peripherals;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          // off page loading
          this.showLoading = false;
        });
    },

    // remove pc builder product component form componet
    removePcComponent(componentId, index=false){
      let data = {
        componentId: componentId,
        index: index,
      }
      this.$store
        .dispatch("removePcBuilderComponent", data)
        .then((response) => {
          this.pcBuilderComponentCart = this.$store.getters.pcBuilderComponentCart
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // pc save function
    savePc(){
      let requiredMsg = [];
      this.errorMsg = [];
      this.coreComponents.forEach(item =>{        
        if(item.required){
          let componentId = 'componentId'+item.category_id;
          if(!this.pcBuilderComponentCart[componentId]){
            requiredMsg.push(item.category.category_name +' is required!');
          }
        }        
      });
      if(requiredMsg.length){
        this.errorMsg = requiredMsg;
      }else{
        this.$router.push('/account/save-pc');
      }
    },

    // add to cart
    // pc builder component add to cart
    addToCart(){
      // core components data insert
      this.coreComponents.forEach(item =>{
        let componentId = 'componentId'+item.category_id;
        if(this.pcBuilderComponentCart[componentId]){
          this.pcBuilderComponentCart[componentId].products.forEach(product => {
            
            let cartForm = {
              qty: 1,
              cartId: product.product.component_product_id,
              product: {
                id: product.product.id,
                product_id: product.product.component_product_id,
                barcode: product.product.barcode,
                category_id: product.product.category_id,
                brand_id: product.product.brand_id,
                product_name: product.product.product_name,
                product_model: product.product.product_model,
                description: product.product.description,
                specification: product.product.specification,
                slug: product.product.slug,
                product_unit: product.product.product_unit,
                variation_type: product.product.variation_type,
                product_type: product.product.product_type,
                original_price: product.product.original_price,
                sale_price: product.product.sale_price,
                regular_price: product.product.regular_price,
                discount: product.product.discount,
                warranty: product.product.warranty,
                word_warranty: product.product.word_warranty, 
                status: product.product.status,
                stock_status: product.product.stock_status,
                feature_pro_status: product.product.feature_pro_status,
                hot_product: product.product.hot_product,
                thumbnail: product.product.thumbnail,
                stock_qty: product.product.stock_qty,
                user_id: product.product.user_id,
                delete_user: product.product.delete_user,
                created_at: product.product.created_at,
                updated_at: product.product.updated_at,
                deleted_at: product.product.deleted_at,
                category_name: product.product.category_name,
                brand_name: product.product.brand_name,
              },
            };
            this.$store.dispatch("addToCart", cartForm);
          });
        }                         
      });

      // core components data insert
      this.peripheralsComponents.forEach(item =>{
        let componentId = 'componentId'+item.category_id;
        if(this.pcBuilderComponentCart[componentId]){
          this.pcBuilderComponentCart[componentId].products.forEach(product => {
            let cartForm = {
              qty: 1,
              cartId: product.product.component_product_id,
              product: {
                id: product.product.id,
                product_id: product.product.component_product_id,
                barcode: product.product.barcode,
                category_id: product.product.category_id,
                brand_id: product.product.brand_id,
                product_name: product.product.product_name,
                product_model: product.product.product_model,
                description: product.product.description,
                specification: product.product.specification,
                slug: product.product.slug,
                product_unit: product.product.product_unit,
                variation_type: product.product.variation_type,
                product_type: product.product.product_type,
                original_price: product.product.original_price,
                sale_price: product.product.sale_price,
                regular_price: product.product.regular_price,
                discount: product.product.discount,
                warranty: product.product.warranty,
                word_warranty: product.product.word_warranty, 
                status: product.product.status,
                stock_status: product.product.stock_status,
                feature_pro_status: product.product.feature_pro_status,
                hot_product: product.product.hot_product,
                thumbnail: product.product.thumbnail,
                stock_qty: product.product.stock_qty,
                user_id: product.product.user_id,
                delete_user: product.product.delete_user,
                created_at: product.product.created_at,
                updated_at: product.product.updated_at,
                deleted_at: product.product.deleted_at,
                category_name: product.product.category_name,
                brand_name: product.product.brand_name,
              },
            };
            cartForm.product = product.product;
            // this.$store.dispatch("addToCart", cartForm);
          })
        }                         
      });      
      
      if(this.userInfo){
        this.$router.push('/checkout');
      }else{
        this.alertMsg = 'PC Builder Componets';
        this.addAlert = "show d-block";
      }
    },

    // alert modal close function
    modalClose() {
      this.addAlert = "";
    },
  },
  watch: {},
};
</script>
<style scoped>
  .budgetPcInput{
    width: 250px;
  }
  .component-bg{
    background: #f2f4f8;
    color: black;
    font-weight: bold;
  }
  .component-img{
    width: 60px;
    border-right: 1px solid #f2f4f8;
  }
  .price{
    width: 100px;
    border-right: 1px solid #f2f4f8;
  }
  .action{
    width: 100px;
    text-align: center;
  }
  .pc-icon{
    font-size: 20px;
    line-height: 0;
  }
  .add-more{
    font-size: 10px;
  }
  table tbody tr td{
    border-bottom: 1px solid #f2f4f8;
  }
  .cr-p{
    cursor: pointer;
  }
</style>