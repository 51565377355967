<template>
  <div id="orderConfirm" class="col-md-12 px-0">
    <section class="after-header p-tb-10 bg-white p-3">
      <div class="c-intro">
        <ul class="breadcrumb mb-0">
          <li>
            <router-link to="/">
              <i class="material-icons" title="Home">home</i>
            </router-link>
          </li>
          <li>
            <router-link to="/account" class="text-capitalize">
              <span>Account</span>
            </router-link>
          </li>
          <li>
            <router-link to="/account/saved-pc-list" class="text-capitalize">
              <span>Saved PC</span>
            </router-link>
          </li>
        </ul>
      </div>
    </section>
    
    <div class="col-md-12 bg-white mt-3">
      <div class="container ac-layout bg-white pt-3">
        <ul class="navbar-nav ac-navbar" style="height: 44px;">
          <li class="nav-item me-1">
            <router-link to="/account/orders" class="nav-link">
              <span class="material-icons">chrome_reader_mode</span>Orders
            </router-link>
          </li>
          <li class="nav-item mx-1">
            <router-link to="/account/profile" class="nav-link">
              <span class="material-icons">person</span>Profile
            </router-link>
          </li>
          <li class="nav-item mx-1">
            <router-link to="/account/password-change" class="nav-link">
              <span class="material-icons">lock</span>Change Password
            </router-link>
          </li>
          <li class="nav-item ms-1 active">
            <router-link to="/account/saved-pc-list" class="nav-link">
              <span class="material-icons">important_devices</span>Saved PC
            </router-link>
          </li>
        </ul>


        <div class="order-details">
          <h4>Saved PC History</h4>
          <div class="col-md-12 table-responsive" v-if="pcLists.length">
            <table class="table table-hover table-striped table-responsive">
              <thead class="bg-secondary text-white">
                <th class="align-middle pt-2">SL</th>
                <th class="align-middle pt-2">PC Name</th>
                <th class="align-middle pt-2">Description</th>
                <th class="align-middle pt-2">Date</th>
                <th class="align-middle pt-2 text-center">Action</th>
              </thead>
              <tbody>
                <tr v-for="(pc, i) in pcLists" :key="pc.id">
                  <td>{{ ++i }}</td>
                  <td>{{ pc.name }}</td>
                  <td>{{ pc.description }}</td>
                  <td>{{ dateTime(pc.created_at) }}</td>
                  <td>
                      <router-link to="" title="PC Details"><font-awesome icon="eye"></font-awesome></router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-12" v-else>
            <h4 class="text-center py-5">You don't have any saved PCs!</h4>
          </div>
        </div>
      </div>
    </div>

    <!-- loading view -->
    <LoadingView v-if="showLoading"/>
  </div>
</template>
<script>
  import moment from "moment";
  import LoadingView from '@/components/Layouts/LoadingView.vue';
  import { useHead } from "@vueuse/head";
  export default {
    name: "SavePcList",
    props: ["userInfo"],
    components: {
      LoadingView
    },

    // meta tag setup start
    setup() {
      useHead({
        title: 'Sopma Tech | Saved PC List',
      });
    },
    // meta tag setup end
    
    data() {
      return {
        showLoading: true,
        pcLists: [],
        customerId: this.userInfo.user.customer_id,        
      };
    },
    mounted() {
      this.getSavedPC();
    //   console.log(this.customerId)
    },
    methods: {
      getSavedPC() {
        // on page loading
        this.showLoading = true;

        this.$store
          .dispatch("savedPC", this.customerId)
          .then((response) => {
            this.pcLists = response.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            // off page loading
            this.showLoading = false;
          });
      },

    //   date format function
      dateTime(value) {
        return moment(value).format("DD MMM, YYYY hh:mma");
      },
    },
    watch: {},
  };
  </script>