<template>
  <div id="compareArea" class="col-md-12 px-0 mt-2 mb-5">
    <section class="after-header p-tb-10 bg-white p-3">
      <div class="c-intro">
        <ul class="breadcrumb mb-0">
          <li>
            <router-link to="/">
              <i class="material-icons" title="Home">home</i>
            </router-link>
          </li>
          <li>
            <router-link to="compare-products" class="text-capitalize">
              <span>Compare Products</span>
            </router-link>
          </li>
        </ul>
      </div>
    </section>

    <section class="p-item-page bg-bt-gray p-tb-15 px-3">
      <div
        v-if="compareProducts.length == 3"
        class="row bg-white rounded compare py-4">
        <div class="col-md-12">
          <table
            class="table table-hover table-striped table-bordered table-responsive"
          >
            <tbody>
              <tr class="fw-bol nas">
                <td class="fw-bold align-middle">Product</td>
                <td class="text-center">
                  <img
                    v-if="compareProducts[0].product.thumbnail"
                    :src="compareProducts[0].product.thumbnail"
                    :alt="compareProducts[0].product.product_name"
                    class="thumble"
                  />
                  <img
                    v-else
                    src="@/assets/product-default.jpeg"
                    :alt="compareProducts[0].product.product_name"
                    class="thumble"
                  />
                  <h5 class="text-center mt-3">
                    <router-link
                      :to="
                        /product-details/ +
                        [
                          compareProducts[0].product.slug +
                            '/' +
                            compareProducts[0].product.product_id,
                        ]
                      "
                      class="nav-link p-0 text-start"
                    >
                      {{ compareProducts[0].product.product_name }}
                    </router-link>
                    <span class="text-danger fw-bold d-block h5"
                      >{{
                        compareProducts[0].product.sale_price.toLocaleString()
                      }}
                      ৳</span
                    >
                  </h5>
                </td>
                <td class="text-center">
                  <img
                    v-if="compareProducts[1].product.thumbnail"
                    :src="compareProducts[1].product.thumbnail"
                    :alt="compareProducts[1].product.product_name"
                    class="thumble"
                  />
                  <img
                    v-else
                    src="@/assets/product-default.jpeg"
                    :alt="compareProducts[1].product.product_name"
                    class="thumble"
                  />
                  <h5 class="text-center mt-3">
                    <router-link
                      :to="
                        /product-details/ +
                        [
                          compareProducts[1].product.slug +
                            '/' +
                            compareProducts[1].product.product_id,
                        ]
                      "
                      class="nav-link p-0 text-start"
                    >
                      {{ compareProducts[1].product.product_name }}
                    </router-link>
                    <span class="text-danger fw-bold d-block h5"
                      >{{
                        compareProducts[1].product.sale_price.toLocaleString()
                      }}
                      ৳</span
                    >
                  </h5>
                </td>
                <td class="text-center">
                  <img
                    v-if="compareProducts[2].product.thumbnail"
                    :src="compareProducts[2].product.thumbnail"
                    :alt="compareProducts[2].product.product_name"
                    class="thumble"
                  />
                  <img
                    v-else
                    src="@/assets/product-default.jpeg"
                    :alt="compareProducts[2].product.product_name"
                    class="thumble"
                  />
                  <h5 class="text-center mt-3">
                    <router-link
                      :to="
                        /product-details/ +
                        [
                          compareProducts[2].product.slug +
                            '/' +
                            compareProducts[2].product.product_id,
                        ]
                      "
                      class="nav-link p-0 text-start"
                    >
                      {{ compareProducts[2].product.product_name }}
                    </router-link>
                    <span class="text-danger fw-bold d-block h5"
                      >{{
                        compareProducts[2].product.sale_price.toLocaleString()
                      }}
                      ৳</span
                    >
                  </h5>
                </td>
              </tr>
              <tr>
                <td class="fw-bold align-middle">Model</td>
                <td>
                  {{ compareProducts[0].product.product_model }}
                </td>
                <td>
                  {{ compareProducts[1].product.product_model }}
                </td>
                <td>
                  {{ compareProducts[2].product.product_model }}
                </td>
              </tr>
              <tr>
                <td class="fw-bold align-middle">Brand</td>
                <td>
                  {{ compareProducts[0].product.brand_name }}
                </td>
                <td>
                  {{ compareProducts[1].product.brand_name }}
                </td>
                <td>
                  {{ compareProducts[2].product.brand_name }}
                </td>
              </tr>
              <tr>
                <td class="fw-bold align-middle">Availability</td>
                <td>
                  <span v-if="compareProducts[0].product.stock_status == 1">
                    Stock In
                  </span>
                  <span v-else-if="compareProducts[0].product.stock_status == 2">
                    Stock Out
                  </span>
                  <span v-else> Upcomming </span>
                </td>
                <td>
                  <span v-if="compareProducts[1].product.stock_status == 1">
                    Stock In
                  </span>
                  <span v-else-if="compareProducts[1].product.stock_status == 2">
                    Stock Out
                  </span>
                  <span v-else> Upcomming </span>
                </td>
                <td>
                  <span v-if="compareProducts[2].product.stock_status == 1">
                    Stock In
                  </span>
                  <span v-else-if="compareProducts[2].product.stock_status == 2">
                    Stock Out
                  </span>
                  <span v-else> Upcomming </span>
                </td>
              </tr>
              <tr>
                <td class="fw-bold align-middle">Specification</td>
                <td v-html="compareProducts[0].product.specification"></td>
                <td v-html="compareProducts[1].product.specification"></td>
                <td v-html="compareProducts[2].product.specification"></td>
              </tr>
              <tr>
                <td class="fw-bold align-middle">Description</td>
                <td v-html="compareProducts[0].product.description"></td>
                <td v-html="compareProducts[1].product.description"></td>
                <td v-html="compareProducts[2].product.description"></td>
              </tr>
              <tr>
                <td class="fw-bold align-middle">Warranty</td>
                <td v-html="compareProducts[0].product.word_warranty"></td>
                <td v-html="compareProducts[1].product.word_warranty"></td>
                <td v-html="compareProducts[2].product.word_warranty"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-else class="row bg-white rounded compare py-4">
        <div class="col-md-12">
          <table
            class="table table-hover table-striped table-bordered table-responsive"
          >
            <tbody>
              <tr class="fw-bold">
                <td class="fw-bold align-middle">Product</td>
                <td class="text-center">
                  <img
                    v-if="compareProducts[0].product.thumbnail"
                    :src="compareProducts[0].product.thumbnail"
                    :alt="compareProducts[0].product.product_name"
                    class="thumble"
                  />
                  <img
                    v-else
                    src="@/assets/product-default.jpeg"
                    :alt="compareProducts[0].product.product_name"
                    class="thumble"
                  />
                  <h5 class="text-center mt-3">
                    <router-link
                      :to="
                        /product-details/ +
                        [
                          compareProducts[0].product.slug +
                            '/' +
                            compareProducts[0].product.product_id,
                        ]
                      "
                      class="nav-link p-0 text-start"
                    >
                      {{ compareProducts[0].product.product_name }}
                    </router-link>
                    <span class="text-danger fw-bold d-block h5"
                      >{{
                        compareProducts[0].product.sale_price.toLocaleString()
                      }}
                      ৳</span
                    >
                  </h5>
                </td>
                <td class="text-center">
                  <img
                    v-if="compareProducts[1].product.thumbnail"
                    :src="compareProducts[1].product.thumbnail"
                    :alt="compareProducts[1].product.product_name"
                    class="thumble"
                  />
                  <img
                    v-else
                    src="@/assets/product-default.jpeg"
                    :alt="compareProducts[1].product.product_name"
                    class="thumble"
                  />
                  <h5 class="text-center mt-3">
                    <router-link
                      :to="
                        /product-details/ +
                        [
                          compareProducts[1].product.slug +
                            '/' +
                            compareProducts[1].product.product_id,
                        ]
                      "
                      class="nav-link p-0 text-start"
                    >
                      {{ compareProducts[1].product.product_name }}
                    </router-link>
                    <span class="text-danger fw-bold d-block h5"
                      >{{
                        compareProducts[1].product.sale_price.toLocaleString()
                      }}
                      ৳</span
                    >
                  </h5>
                </td>
              </tr>
              <tr>
                <td class="fw-bold align-middle">Model</td>
                <td>
                  {{ compareProducts[0].product.product_model }}
                </td>
                <td>
                  {{ compareProducts[1].product.product_model }}
                </td>
              </tr>
              <tr>
                <td class="fw-bold align-middle">Brand</td>
                <td>
                  {{ compareProducts[0].product.brand_name }}
                </td>
                <td>
                  {{ compareProducts[1].product.brand_name }}
                </td>
              </tr>
              <tr>
                <td class="fw-bold align-middle">Availability</td>
                <td>
                  <span v-if="compareProducts[0].product.stock_status == 1">
                    Stock In
                  </span>
                  <span v-else-if="compareProducts[0].product.stock_status == 2">
                    Stock Out
                  </span>
                  <span v-else> Upcomming </span>
                </td>
                <td>
                  <span v-if="compareProducts[1].product.stock_status == 1">
                    Stock In
                  </span>
                  <span v-else-if="compareProducts[1].product.stock_status == 2">
                    Stock Out
                  </span>
                  <span v-else> Upcomming </span>
                </td>
              </tr>
              <tr>
                <td class="fw-bold align-middle">Specification</td>
                <td v-html="compareProducts[0].product.specification"></td>
                <td v-html="compareProducts[1].product.specification"></td>
              </tr>
              <tr>
                <td class="fw-bold align-middle">Description</td>
                <td v-html="compareProducts[0].product.description"></td>
                <td v-html="compareProducts[1].product.description"></td>
              </tr>
              <tr>
                <td class="fw-bold align-middle">Warranty</td>
                <td v-html="compareProducts[0].product.word_warranty"></td>
                <td v-html="compareProducts[1].product.word_warranty"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "CompareProduct",
  props: ["compareProducts"],
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  watch: {},
};
</script>