<template>
  <div id="product-detail" class="product-area col-md-12 mt-2 mb-5 px-0">
    <section class="after-header px-3 mb-2 bg-white">
      <ul class="breadcrumb mb-0 py-2">
        <li>
          <router-link to="/">
            <i class="material-icons" title="Home">home</i>
          </router-link>
        </li>
        <li v-if="typeSlug">
          <router-link 
            :to="{
              name: 'typeProducts',
              params: {
                typeSlug: typeSlug,
              },
            }">
            <span class="text-capitalize">{{ typeSlug.replace(/-/g, " ") }}</span>
          </router-link>
        </li>
        <li v-if="categorySlug">
          <router-link 
            :to="{
              name: 'categoryProducts',
              params: {
                typeSlug: typeSlug,
                categorySlug: categorySlug,
              },
            }">
            <span class="text-capitalize">{{ categorySlug.replace(/-/g, " ") }}</span>
          </router-link>
        </li>
        <li v-if="subcategorySlug">
          <router-link 
            :to="{
              name: 'categoryProducts',
              params: {
                typeSlug: typeSlug,
                categorySlug: categorySlug,
              },
            }">
            <span class="text-capitalize">{{ subcategorySlug.replace(/-/g, " ") }}</span>
          </router-link>
        </li>
        <li v-if="product">
          <a type="button">
            <span class="text-capitalize">{{ product.product_name.slice(0, 20)
              }}{{ product.product_name.length > 20 ? "..." : "" }}</span>
          </a>
        </li>
      </ul>
    </section>

    <div class="product-details content px-3 bg-white" v-if="product">
      <div class="product-details-summary ">
        <div class="pd-q-actions">
            <div class="share-on">
                <span class="share">Share:</span>
                <span class="icon-sprite share-ico fb-dark" data-type="facebook"></span>
                <span class="icon-sprite share-ico pinterest-dark" data-type="pinterest"></span>
            </div>
            <div class="options">
                <!-- <span class="add-list" onclick="wishlist.add('28150');"><i class="material-icons">bookmark_border</i> Save</span> -->
                <span class="add-compare" @click="addtoComparef(product)"><i class="material-icons">library_add</i> Add to Compare</span>
            </div>
        </div>
        <div class="basic row">
            <div class="col-md-5 left">
              <ProductImageGallery :productImages="productImages" :productName="product.product_name"/>
            </div>
            <div class="col-md-7 right col-mt-0 mt-5 col-pt-0 pt-4" id="product">
                <div class="pd-summary">
                    <div class="product-short-info">
                        <h1 class="product-name">{{ product.product_name }}</h1>
                        <table class="product-info-table">
                            <tbody>
                                <tr class="product-info-group">
                                    <td class="product-info-label">Price</td>
                                    <td class="product-info-data product-price">
                                      {{ product.sale_price.toLocaleString() }}৳
                                      <small v-if="product.discount>0">
                                        <del class="text-secondary ps-2">{{ product.original_price.toLocaleString() }}৳</del>
                                      </small>
                                    </td>
                                </tr>
                                <tr class="product-info-group">
                                    <td class="product-info-label">Regular Price</td>
                                    <td class="product-info-data product-regular-price">{{ product.regular_price.toLocaleString() }}৳</td>
                                </tr>                       
                                <tr class="product-info-group">
                                    <td class="product-info-label">Status</td>
                                    <td class="product-info-data product-status">
                                      {{ (product.stock_status == 1)? 'Stock In':((product.stock_status == 2)? 'Stock Out':'Upcomming') }}
                                    </td>
                                </tr>
                                <tr class="product-info-group">
                                    <td class="product-info-label">Product Code</td>
                                    <td class="product-info-data product-code">{{ product.product_id }}</td>
                                </tr>
                                <tr class="product-info-group">
                                    <td class="product-info-label">Brand</td>
                                    <td class="product-info-data product-brand">{{ (product.brand_id)? product.brand_name:'' }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="short-description" v-if="product.key_features">
                        <h2>Key Features</h2>
                        <div v-html="product.key_features"></div>
                    </div>
                    <ul class="short-description ps-0">
                      <li class="view-more"><a href="#prductInformaiton" class="text-decoration-none">More Information</a></li>
                    </ul>
                    <!-- <div class="stickers">                                                               
                        <div class="sticker reward">
                            <span class="material-icons">stars</span>
                            <span class="points">300</span>
                            <span class="text">Sompatech Points</span>
                        </div>
                    </div> -->
                    <h2>Payment Options</h2>
                    <div class="product-price-options">
                        <label class="p-wrap cash-payment active">
                            <input type="radio" name="enable_emi" checked="" value="0">
                            <span class="price">
                              {{ product.sale_price.toLocaleString() }}৳
                              <small v-if="product.discount>0">
                                <del class="text-secondary ps-2">{{ product.original_price.toLocaleString() }}৳</del>
                              </small>
                            </span>
                            <div class="p-tag">Cash Discount Price</div>                                
                            <div class="p-tag fade">Online / Cash Payment</div>
                        </label>
                        <label class="p-wrap d-none" v-if="product.total_emi>0">
                            <input type="radio" name="enable_emi" value="1">
                            <span class="price">{{ product.per_month_emi.toLocaleString() }}৳/month</span>
                            <div class="p-tag">Regular Price: {{ product.regular_price.toLocaleString() }}৳</div>
                            <div class="p-tag text-secondary small">{{ product.emi_interest_rate }}% EMI for up to {{ product.total_month }} Months***</div>
                        </label>
                    </div>
                    <div class="cart-option d-flex justify-content flex-wrap">
                        <label class="quantity">
                          <span class="ctl" @click="decreaseQty"><i class="material-icons">remove</i></span>
                          <span class="qty">
                            <input type="text" name="quantity" id="input-quantity" v-model="cartForm.qty" size="2">
                          </span>
                          <span class="ctl increment" @click="increaseQty"><i class="material-icons">add</i></span>
                        </label>
                        <label class="quantity" v-if="product.variation_type">
                          <select class="form-select" v-model="cartForm.variation">
                            <option value="">
                              Choose {{ product.variation_type }}
                            </option>
                            <option
                              v-for="variation in product.variations"
                              :key="variation.variation_id"
                              :value="
                                variation.variation_id +
                                ';' +
                                variation.variation_name
                              "
                            >
                              {{ variation.variation_name }}
                            </option>
                          </select>
                        </label>
                        <button v-if="product.stock_status == 1" id="button-cart" class="btn submit-btn me-2" @click="addToCart(product)" >Buy Now</button>
                        <span v-else-if="product.stock_status == 2" class="st-btn stock-status">Stock Out</span>
                        <span v-else-if="product.stock_status == 3" class="st-btn stock-status">Up Coming</span>
                        <div class="actions">
                          <button v-if="product.product_type == 'Budget PC'"  id="button-cart" class="btn submit-btn" @click="addToPcBuilder(product.component_products)" ><font-awesome icon="desktop" /> Customize PC</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    
    <div class="container-fluid" v-if="product" id="prductInformaiton">
      <div class="row">
          <div class="col-lg-9 col-md-12">
              <div class="navs">
                  <ul class="nav">
                      <li><a href="#specification">Specification</a></li>
                      <li><a href="#description">Description</a></li>
                      <li class="hidden-xs"><a href="#questions">Questions ({{ product.questions.length }})</a></li>
                      <li><a href="#reviews">Reviews ({{ product.reviews.length }})</a></li>
                  </ul>
              </div>
              <section class="specification-tab rounded p-3 bg-white mt-3" id="specification">
                  <div class="section-head">
                      <h2>Specification</h2>
                  </div>
                  <div v-html="product.specification"></div>
              </section>
              <section class="description rounded p-3 bg-white mt-3" id="description">
                  <div class="section-head">
                      <h2>Description</h2>
                  </div>
                  <div v-html="product.description"></div>
              </section>
              <section class="ask-question rounded p-3 bg-white mt-3" id="questions">
                <div class="section-head mb-3 d-flex justify-content-between flex-wrap border-bottom">
                  <div class="title-n-action">
                    <h2>Questions ({{ product.questions.length }})</h2>
                    <p class="section-blurb">Have question about this product?</p>
                  </div>
                  <div>
                    <a type="button" class="btn st-outline" @click="QueRevModal('question')">Ask Question</a>
                  </div>
                </div>
                <div id="question" v-if="product.questions">
                  <template v-for="question in product.questions.slice(0, 10)" :key="question.question_id">
                    <div class="question-wrap">
                      <p class="text-secondary ps-4 mb-0">
                        By
                        <span class="text-primary">{{ question.sender_name }}</span>
                        {{ monthNames[new Date(question.created_at).getMonth()] }}
                        {{ new Date(question.created_at).getDate() }},
                        {{ new Date(question.created_at).getFullYear() }}
                      </p>
                      <div class="d-flex">
                        <div class="pe-3 fw-bold">Q:</div>
                        <div class="fw-bold">{{ question.question }}</div>
                      </div>
                      <div class="d-flex mt-2">
                        <div class="pe-3 fw-bold">A:</div>
                        <div>{{ question.answare }}</div>
                      </div>
                    </div>
                    <hr>
                  </template>
                    <p v-if="product.questions.length > 10"
                      class="nav-link text-end my-0 mx-1 bg-white p-0 m-0" >
                      <router-link
                        :to="{
                          name: 'ProductQuestions',
                          params: {
                            productSlug: product.slug,
                            productId: product.product_id,
                          },
                        }"
                        class="nav-link p-0 m-0"
                        target="_blank"
                        >More questions...</router-link
                      >
                    </p>
                </div>
                <div class="empty-content" v-else>
                  <div class="empty-text">There are no questions asked yet.</div>
                </div>
              </section>
              <section class="review rounded p-3 bg-white mt-3" id="reviews">
                <div class="section-head mb-3 d-flex justify-content-between flex-wrap border-bottom">
                  <div class="title-n-action">
                    <h2>Reviews ({{ product.reviews.length }})</h2>
                    <p class="section-blurb mb-0">Customer Reviews</p>
                    <span class="h5 d-block py-2"
                      v-if="product.reviews.length">
                      <font-awesome
                        icon="star"
                        v-for="n in parseInt(
                          product.total_rating / product.total_review
                        )"
                        :key="n"
                        class="text-success"
                        :class="n"
                      />
                      &nbsp;{{
                        (product.total_rating / product.total_review).toFixed(2)
                      }}
                      out of 5
                    </span>
                  </div>
                  <div>
                    <a type="button" class="btn st-outline" @click="QueRevModal('review')">Write Rreview</a>
                  </div>
                </div>
                <div id="review" v-if="product.reviews">
                  <template v-for="review in product.reviews.slice(0, 10)" :key="review.review_id">
                    <div class="question-wrap">
                      <p class="text-secondary mb-0">
                        <small>
                          <font-awesome
                          icon="star"
                          v-for="n in parseInt(review.rating)"
                          :key="n"
                          class="text-success"
                          :class="n"
                        />
                        </small>
                        By
                        <span class="text-primary ps-2">{{ review.reviewer_name }}</span>
                        {{ monthNames[new Date(review.created_at).getMonth()] }}
                        {{ new Date(review.created_at).getDate() }},
                        {{ new Date(review.created_at).getFullYear() }}
                      </p>
                      <div class="ps-2">{{ review.message }}</div>
                    </div>
                    <hr>
                  </template>
                  <p v-if="product.reviews.length > 10"
                  class="nav-link text-end m-0 p-0">
                    <router-link
                      :to="{
                        name: 'ProductReviews',
                        params: {
                          productSlug: product.slug,
                          productId: product.product_id,
                        },
                      }"
                      class="nav-link m-0 p-0"
                      target="_blank"
                      >More reviews...</router-link
                    >
                  </p>
                </div>
                <div class="empty-content" v-else>
                  <div class="empty-text">There are no reviews yet.</div>
                </div>
              </section>
          </div>
          <div class="col-lg-3 col-md-12 c-left">
            <section class="related-product-list">
              <h3 class="bg-white rounded" style="margin: 10px 0 10px 0;">Related Product</h3>
              <div class="p-s-item rounded mb-2 p-2" v-for="rproduct in relatedProducts" :key="rproduct.product_id">
                <div>
                  <router-link
                    :to="{
                      name: 'productDetails',
                      params: {
                        productSlug: rproduct.slug,
                        typeSlug: typeSlug,
                        categorySlug: categorySlug,
                        subcategorySlug: subcategorySlug,
                      },
                    }">
                    <img :src="rproduct.thumbnail" :alt="rproduct.product_name" width="80" height="80">
                    </router-link>
                </div>
                <div class="caption ps-2">
                  <h4 class="product-name">
                    <router-link
                    :to="{
                      name: 'productDetails',
                      params: {
                        productSlug: rproduct.slug,
                        typeSlug: typeSlug,
                        categorySlug: categorySlug,
                        subcategorySlug: subcategorySlug,
                      },
                    }">
                      {{ rproduct.product_name }}
                    </router-link>
                  </h4>
                  <div class="p-item-price price">
                    {{ rproduct.sale_price.toLocaleString() }}৳
                    <small class="ml-3" v-if="rproduct.discount > 0">
                      <del class="text-secondary">{{ rproduct.original_price.toLocaleString() }}৳</del>
                    </small>                                
                  </div>
                  <div class="actions">
                    <span class="d-inline d-flex" type="button" @click="addtoComparef(rproduct)">
                      <i class="material-icons">library_add</i>
                      <span>Add to Compare</span>
                    </span>
                  </div>
                </div>
              </div>
            </section>
          </div>
      </div>
    </div>

    <!-- comment or question modal -->
    <div
      class="modal fade"
      :class="commentModalShow"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h6
              class="modal-title fw-bold text-capitalize"
              id="staticBackdropLabel"
            >
              Write {{ modalTitle }}
            </h6>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="commentModalClose()"
            ></button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="pro" class="fw-bold mb-0">Product</label>
              <input
                v-if="product"
                type="text"
                id="pro"
                class="form-control"
                readonly
                :value="product.product_name"
              />
            </div>
            <div class="form-group mt-3" v-if="modalTitle == 'review'">
              <label for="pro" class="fw-bold mb-0"
                >Rating <small class="text-danger">*</small></label
              >
              <div class="d-block">
                <font-awesome
                  icon="star"
                  :class="one"
                  class="me-3"
                  @click="star(1)"
                />
                <font-awesome
                  icon="star"
                  :class="tow"
                  class="me-3"
                  @click="star(2)"
                />
                <font-awesome
                  icon="star"
                  :class="three"
                  class="me-3"
                  @click="star(3)"
                />
                <font-awesome
                  icon="star"
                  :class="four"
                  class="me-3"
                  @click="star(4)"
                />
                <font-awesome
                  icon="star"
                  :class="five"
                  class=""
                  @click="star(5)"
                />
              </div>
            </div>
            <div class="form-group mt-3">
              <label for="quRe" class="text-capitalize mb-0 fw-bold">{{ modalTitle }} <small class="text-danger">*</small></label>
              <textarea
                name=""
                id=""
                cols="30"
                class="form-control"
                rows="5"
                :placeholder="'Write your ' + modalTitle"
                v-model="form.msg"
              ></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn-outline-danger me-2"
              data-bs-dismiss="modal"
              @click="commentModalClose()">
              Close
            </button>
            <button
              type="button"
              class="btn btn-outline-primary"
              data-bs-dismiss="modal"
              @click="msgSubmit()">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>


    <!-- alert modal  -->
    <ProductAddAlert
      :modalAlert="addAlert"
      :alertMsg="alertMsg"
      @modalClose="modalClose"
    />

    <!-- loading view -->
    <LoadingView v-if="showLoading"/>
  </div>
</template>
<script>
import ProductAddAlert from "@/components/ProductAddAlert.vue";
import LoadingView from '@/components/Layouts/LoadingView.vue';
import ProductImageGallery from '@/components/ProductImageGallery.vue';
import { reactive, watch, computed, ref } from 'vue';
import { useHead } from "@vueuse/head";

export default {
  name: "ProductDetailView",
  props: ["userInfo"],
  components: { ProductAddAlert, LoadingView, ProductImageGallery },

  // meta tag setup start
  setup() {
    // Define reactive properties within the setup function
    const siteTitle = ref('siteTitle');
    const siteData = reactive({
      title: siteTitle.value +' Price in Bangladesh | Sopma Tech',
      description: "Sopma Tech is the Best Laptop, Computer, Gaming PC, Component, Accessories, and Gadget retail &amp; Online shop in Bangladesh. Sopma Tech offers the Latest Tech products with the most competitive price in BD.",
      keywords: "Laptop shop in Bangladesh, Laptop shop in bd, computer shop in Bangladesh, PC shop in Bangladesh, computer shop in BD, Gaming PC shop in Bangladesh, PC accessories shop in Bangladesh, best computer shop in Bangladesh, Gadget shop in bd, Gadget Shop in Bangladesh, Online Shop in BD, online computer shop in bd, computer accessories online shop in Bangladesh, computer parts shop in bd, Laptop in Bangladesh, Notebook, Laptop, Desktop, Brand PC, computer, computer store Bangladesh, laptop store Bangladesh, gaming, desktop, monitor, Sopma Tech, computer accessories, Desktop accessories, Laptop accessories, Laptop Online Store in BD, adata, apacer, apple, asus, bangladesh, baseus, belkin, benq, best, boya, brother, cable, camera, canon, GPU, graphics card, Sopma Tech &amp; Engineering Ltd, SOPMATECH, laptop price, laptop price in bangladesh, laptop price bd,",
    });

    // Watch for changes in siteTitle and update siteData.title accordingly
    watch(siteTitle, (newSiteTitle) => {
      siteData.title = newSiteTitle +' Price in Bangladesh | Sopma Tech'
    });

    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: 'description',
          content: computed(() => siteData.description),
        },
        {
          name: 'keywords',
          content: computed(() => siteData.keywords),
        },
      ],
    });

    // Access siteTitle directly in setup
    return {
      siteTitle,
    };
  },
  // meta tag setup end

  data() {
    return {
      showLoading: true,
      addAlert: "",
      alertMsg: "",
      loading: false,
      productSlug: this.$route.params.productSlug,
      typeSlug: this.$route.params.typeSlug,
      categorySlug: this.$route.params.categorySlug,
      subcategorySlug: this.$route.params.subcategorySlug,
      productImages: [],
      product: "",
      monthNames: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      sizes: [],
      cartForm: {
        variation: "",
        qty: 1,
        product: "",
      },
      relatedProducts: [],
      form: {
        msg: "",
        rating: 0,
      },
      commentModalShow: "",
      modalTitle: "",
      one: "",
      tow: "",
      three: "",
      four: "",
      five: "",
      mobile: true,
    };
  },
  methods: {
    qtyFilter(evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();
      if (!/^[-+]?[0-9]*?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    decreaseQty() {
      if (this.cartForm.qty > 1) {
        this.cartForm.qty -= 1;
      }
    },
    increaseQty() {
      this.cartForm.qty += 1;
    },
    addtoComparef(product) {
      this.$store.dispatch("addtoCompare", product);
    },
    addToCart(product) {
      if (this.cartForm.qty == 0 || this.cartForm.qty == "") {
        this.cartForm.qty = 1;
      }
      this.cartForm.product = product;
      if (product.variation_type && this.cartForm.variation == "") {
        this.$swal({
          title: "REQUIRED!",
          icon: "warning",
          text: "Product " + product.variation_type + " is required!",
        });
      } else {
        let variation = this.cartForm.variation.split(";");
        this.cartForm.variation_id = variation[0];
        this.cartForm.variation_name = variation[1];
        this.cartForm.cartId = product.product_id + variation[0];
        this.$store.dispatch("addToCart", this.cartForm);
        this.alertMsg = product.product_name;
        this.addAlert = "show d-block";
      }
    },

    // product add to pc builder function
    addToPcBuilder(components) {
      this.$store
        .dispatch("componentAddToPcBuilder", components)
        .then((response) => {
          this.$router.push("/pc-builder");
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    modalClose() {
      this.addAlert = "";
    },
    // color change function
    colorf(event) {
      console.log(event.target.value);
    },
    QueRevModal(val) {
      this.modalTitle = val;
      this.one = "";
      this.tow = "";
      this.three = "";
      this.four = "";
      this.five = "";
      this.form.rating = 0;
      this.form.msg = "";
      if (this.userInfo) {
        this.commentModalShow = "show d-block";
      } else {
        this.$router.push({
          name: 'login',
          params: { redirectUrl: this.$route.path }
        });
      }
    },
    commentModalClose() {
      this.commentModalShow = "";
    },
    star(id) {
      if (id == 1) {
        this.one = "text-success";
        this.tow = "";
        this.three = "";
        this.four = "";
        this.five = "";
      } else if (id == 2) {
        this.one = "text-success";
        this.tow = "text-success";
        this.three = "";
        this.four = "";
        this.five = "";
      } else if (id == 3) {
        this.one = "text-success";
        this.tow = "text-success";
        this.three = "text-success";
        this.four = "";
        this.five = "";
      } else if (id == 4) {
        this.one = "text-success";
        this.tow = "text-success";
        this.three = "text-success";
        this.four = "text-success";
        this.five = "";
      } else if (id == 5) {
        this.one = "text-success";
        this.tow = "text-success";
        this.three = "text-success";
        this.four = "text-success";
        this.five = "text-success";
      }
      this.form.rating = id;
    },
    msgSubmit() {
      var error = "";
      if (this.modalTitle == "review") {
        let reviewData = {
          product_id: this.product.product_id,
          message: this.form.msg,
          rating: this.form.rating,
        };
        if (reviewData.message == "") {
          error += '<p class="text-danger mb-0">Review is required!</p>';
        }
        if (reviewData.rating == 0) {
          error += '<p class="text-danger mb-0">Rating is required!</p>';
        }
        if (error.length) {
          this.$swal({
            title: "Warning!",
            icon: "error",
            html: error,
          });
        } else {
          this.$store
            .dispatch("reviewStore", reviewData)
            .then((response) => {
              if (response.status == 201) {
                this.commentModalShow = "";
                this.$swal({
                  title: "success!",
                  icon: "success",
                  text: response.data.message,
                });
              } else {
                this.$swal({
                  title: "Warning!",
                  icon: "error",
                  text: response.data.data,
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        if (this.form.msg.length) {
          let questionData = {
            product_id: this.product.product_id,
            question: this.form.msg,
          };

          this.$store
            .dispatch("questionStore", questionData)
            .then((response) => {
              if (response.status == 201) {
                this.commentModalShow = "";
                this.$swal({
                  title: "success!",
                  icon: "success",
                  text: response.data.message,
                });
              } else {
                this.$swal({
                  title: "Warning!",
                  icon: "error",
                  text: response.data.data,
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          error += '<p class="text-danger mb-0">Question is required!</p>';
          this.$swal({
            title: "Warning!",
            icon: "error",
            html: error,
          });
        }
      }
    },

    // get product detail
    getProductDetails() {
      // on page loading
      this.showLoading = true;
      this.$store
        .dispatch("productDetailf", this.productSlug)
        .then((response) => {
          this.product = response.data.data.product;
          this.relatedProducts = response.data.data.relatedProducts;
          this.cartForm.qty = 1;
          this.cartForm.product = "";
          this.siteTitle = this.product.product_name;
          this.productImages = this.product.product_images;
          // console.log(this.product)
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          // off page loading
          this.showLoading = false;
        });
    },
  },
  created() {
    this.siteTitle = this.$ucWords(this.productSlug.replace(/-/g, " "));
    this.getProductDetails();
  },
  watch: {
    $route() {
      if (this.$route.name == "productDetails") {
        this.productSlug = this.$route.params.productSlug;
        this.typeSlug = this.$route.params.typeSlug;
        this.categorySlug = this.$route.params.categorySlug;
        this.subcategorySlug = this.$route.params.subcategorySlug;
        this.getProductDetails();
      }
    },
  },
};
</script>
