<template>
  <div id="orderConfirm" class="col-md-12 px-0">
    <section class="after-header p-tb-10 bg-white p-3">
      <div class="c-intro">
        <ul class="breadcrumb mb-0">
          <li>
            <router-link to="/">
              <i class="material-icons" title="Home">home</i>
            </router-link>
          </li>
          <li>
            <router-link to="/account" class="text-capitalize">
              <span>Account</span>
            </router-link>
          </li>
          <li>
            <router-link to="/account/orders" class="text-capitalize">
              <span>Orders</span>
            </router-link>
          </li>
        </ul>
      </div>
    </section>

    <div class="col-md-12 bg-white mt-3">
      <div class="container ac-layout bg-white pt-3">
        <ul class="navbar-nav ac-navbar" style="height: 44px;">
          <li class="nav-item me-1 active">
            <router-link to="/account/orders" class="nav-link">
              <span class="material-icons">chrome_reader_mode</span>Orders
            </router-link>
          </li>
          <li class="nav-item mx-1">
            <router-link to="/account/profile" class="nav-link">
              <span class="material-icons">person</span>Profile
            </router-link>
          </li>
          <li class="nav-item mx-1">
            <router-link to="/account/password-change" class="nav-link">
              <span class="material-icons">lock</span>Change Password
            </router-link>
          </li>
          <li class="nav-item ms-1">
            <router-link to="/account/saved-pc-list" class="nav-link">
              <span class="material-icons">important_devices</span>Saved Pc
            </router-link>
          </li>
        </ul>


        <div class="">
          <h4>Order History</h4>
          <div class="col-md-12 table-responsive" v-if="orders.data">
            <table class="table table-hover table-border table-striped table-responsive">
              <thead class="bg-secondary text-white">
                <th class="align-middle text-center py-2">SL#</th>
                <th class="align-middle text-center py-2">Order ID</th>
                <th class="align-middle text-center py-2">Date</th>
                <th class="align-middle text-center py-2">Status</th>
                <th class="align-middle text-center py-2">Net Amount</th>
                <th class="align-middle text-center py-2">Actions</th>
              </thead>
              <tbody>
                <tr v-for="(order, i) in orders.data" :key="order.order_id">
                  <td>{{ ++i }}</td>
                  <td>#{{ order.order_id }}</td>
                  <td>{{ dateTime(order.created_at) }}</td>
                  <td>
                    <span
                      class="rounded p-1 text-white text-capitalize"
                      :class="
                        order.status == 'Cancel'
                          ? 'bg-danger'
                          : 'Delivered' == order.status
                          ? 'bg-success'
                          : 'bg-primary'
                      ">{{ order.status }}</span
                    >
                  </td>
                  <td>{{ order.net_amount.toLocaleString() }} ৳</td>
                  <td>
                    <router-link :to="'/order-details/' + order.order_id" target="_blank">
                      <font-awesome icon="eye" class="p-1" title="Order Details" />
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-12" v-else>
            <h4 class="text-center py-5">You have no orders!</h4>
          </div>
        </div>
      </div>
    </div>

    <!-- loading view -->
    <LoadingView v-if="showLoading"/>

  </div>
</template>
<script>
import moment from "moment";
import LoadingView from '@/components/Layouts/LoadingView.vue';
import { useHead } from "@vueuse/head";

export default {
  name: "OrderView",
  props: ["userInfo"],
  components: {
    LoadingView
  },

  // meta tag setup start
  setup() {
    useHead({
      title: 'Sopma Tech | Orders',
    });
  },
  // meta tag setup end

  data() {
    return {
      showLoading: true,
      orders: [],
      formData: {
        limit: 10,
        order: "asc",
        date: "",
        page: 1,
        customerId: this.userInfo.user.customer_id,
      },
    };
  },
  mounted() {
    this.getOrders();
  },
  methods: {
    getOrders() {
      // on page loading
      this.showLoading = true;

      this.$store
        .dispatch("AllOrders", this.formData)
        .then((response) => {
          this.orders = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          // off page loading
          this.showLoading = false;
        });
    },
    dateTime(value) {
      return moment(value).format("DD MMM, YYYY hh:mma");
    },
    paginationPro(page) {
      this.formData.page = page;
      this.$store
        .dispatch("AllOrders", this.formData)
        .then((response) => {
          this.orders = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.orders = [];
        });
    },
  },
  watch: {},
};
</script>