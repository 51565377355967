<template>
  <div id="orderConfirm" class="col-md-12 px-0">
    <section class="after-header p-tb-10 bg-white p-3">
      <div class="c-intro">
        <ul class="breadcrumb mb-0">
          <li>
            <router-link to="/">
              <i class="material-icons" title="Home">home</i>
            </router-link>
          </li>
          <li>
            <router-link to="/account" class="text-capitalize">
              <span>Account</span>
            </router-link>
          </li>
          <li>
            <router-link to="/account/orders" class="text-capitalize">
              <span>Orders</span>
            </router-link>
          </li>
          <li>
            <a type="button" class="text-capitalize">
              <span># {{ order_id }}</span>
            </a>
          </li>
        </ul>
      </div>
    </section>

    <div class="col-md-12 bg-white mt-3">
      <div class="container ac-layout bg-white pt-3">
        <ul class="navbar-nav ac-navbar" style="height: 44px;">
          <li class="nav-item me-1 active">
            <router-link to="/account/orders" class="nav-link">
              <span class="material-icons">chrome_reader_mode</span>Orders
            </router-link>
          </li>
          <li class="nav-item mx-1">
            <router-link to="/account/profile" class="nav-link">
              <span class="material-icons">person</span>Profile
            </router-link>
          </li>
          <li class="nav-item mx-1">
            <router-link to="/account/password-change" class="nav-link">
              <span class="material-icons">lock</span>Change Password
            </router-link>
          </li>
          <li class="nav-item ms-1">
            <router-link to="/account/saved-pc-list" class="nav-link">
              <span class="material-icons">important_devices</span>Saved Pc
            </router-link>
          </li>
        </ul>


        <div class="order-details" v-if="order">
          <h4>Details of Order No #{{ order.order_id }}</h4>
          <div class="d-block mt-2" >
            <p class="mb-0">Order ID: #{{ order.order_id }}</p>
            <p class="mb-0">Order Date: {{ dateTime(order.created_at) }}</p>
            <p class="text-capitalize mb-0">
              Payment Method: {{ order.payment_type.replace(/_/g, " ") }}
            </p>
            <p class="mb-0">Shipping Address: {{ order.order_shipping.address }}</p>
            <p class="mb-0">Note: {{ order.note }}</p>
            <p class="mb-0 text-capitalize">Status: {{ order.status }}</p>
            <div v-if="order.status == 'Delivered'" class="d-block">
              <div class="placed float-start">
                <div class="complete-circle">
                  <font-awesome icon="circle-check" />
                </div>
                <div class="complete-bar"></div>
                <small class="processing-txt">Placed</small>
              </div>
              <div class="processing float-start">
                <div class="complete-circle">
                  <font-awesome icon="circle-check" />
                </div>
                <div class="complete-bar"></div>
                <small class="processing-txt">Processing</small>
              </div>
              <div class="picked float-start">
                <div class="complete-circle">
                  <font-awesome icon="circle-check" />
                </div>
                <div class="complete-bar"></div>
                <small class="processing-txt">Picked</small>
              </div>
              <div class="shipped float-start">
                <div class="complete-circle">
                  <font-awesome icon="circle-check" />
                </div>
                <div class="complete-bar"></div>
                <small class="processing-txt">Shipped</small>
              </div>
              <div class="delivered float-start">
                <div class="complete-circle">
                  <font-awesome icon="circle-check" />
                </div>
                <small class="delivery-txt">Delivered</small>
              </div>
            </div>
            <div v-else-if="(order.status == 'Shipped')" class="d-block">
              <div class="placed float-start">
                <div class="complete-circle">
                  <font-awesome icon="circle-check" />
                </div>
                <div class="complete-bar"></div>
                <small class="processing-txt">Placed</small>
              </div>
              <div class="processing float-start">
                <div class="complete-circle">
                  <font-awesome icon="circle-check" />
                </div>
                <div class="complete-bar"></div>
                <small class="processing-txt">Processing</small>
              </div>
              <div class="picked float-start">
                <div class="complete-circle">
                  <font-awesome icon="circle-check" />
                </div>
                <div class="complete-bar"></div>
                <small class="processing-txt">Picked</small>
              </div>
              <div class="picked float-start">
                <div class="complete-circle">
                  <font-awesome icon="circle-check" />
                </div>
                <div class="complete-bar"></div>
                <small class="processing-txt">Shipped</small>
              </div>
              <div class="delivered float-start">
                <div class="delivered-circle pending-circle">
                  <font-awesome icon="circle" class="circle" />
                </div>
                <small class="pndelivery-txt">Delivered</small>
              </div>
            </div>
            <div v-else-if="(order.status == 'Picked')" class="d-block">
              <div class="placed float-start">
                <div class="complete-circle">
                  <font-awesome icon="circle-check" />
                </div>
                <div class="complete-bar"></div>
                <small class="processing-txt">Placed</small>
              </div>
              <div class="processing float-start">
                <div class="complete-circle">
                  <font-awesome icon="circle-check" />
                </div>
                <div class="complete-bar"></div>
                <small class="processing-txt">Processing</small>
              </div>
              <div class="processing float-start">
                <div class="complete-circle">
                  <font-awesome icon="circle-check" />
                </div>
                <div class="complete-bar"></div>
                <small class="processing-txt">Picked</small>
              </div>
              <div class="shipped float-start">
                <div class="shipped-circle pending-circle">
                  <font-awesome icon="circle" class="circle" />
                </div>
                <div class="shipped-bar pending-bar"></div>
                <small class="pending-txt">Shipped</small>
              </div>
              <div class="delivered float-start">
                <div class="delivered-circle pending-circle">
                  <font-awesome icon="circle" class="circle" />
                </div>
                <small class="pndelivery-txt">Delivered</small>
              </div>
            </div>
            <div v-else-if="(order.status == 'Processing')" class="d-block">
              <div class="placed float-start">
                <div class="complete-circle">
                  <font-awesome icon="circle-check" />
                </div>
                <div class="complete-bar"></div>
                <small class="processing-txt">Placed</small>
              </div>
              <div class="processing float-start">
                <div class="complete-circle">
                  <font-awesome icon="circle-check" />
                </div>
                <div class="complete-bar"></div>
                <small class="processing-txt">Processing</small>
              </div>
              <div class="picked float-start">
                <div class="picked-circle pending-circle">
                  <font-awesome icon="circle" class="circle" />
                </div>
                <div class="picked-bar pending-bar"></div>
                <small class="pending-txt">Picked</small>
              </div>
              <div class="shipped float-start">
                <div class="shipped-circle pending-circle">
                  <font-awesome icon="circle" class="circle" />
                </div>
                <div class="shipped-bar pending-bar"></div>
                <small class="pending-txt">Shipped</small>
              </div>
              <div class="delivered float-start">
                <div class="delivered-circle pending-circle">
                  <font-awesome icon="circle" class="circle" />
                </div>
                <small class="pndelivery-txt">Delivered</small>
              </div>
            </div>
            <div v-else class="d-block">
              <div class="placed float-start">
                <div class="complete-circle">
                  <font-awesome icon="circle-check" />
                </div>
                <div class="complete-bar"></div>
                <small class="processing-txt">Placed</small>
              </div>
              <div class="processing float-start">
                <div class="processing-circle pending-circle">
                  <font-awesome icon="circle" class="circle" />
                </div>
                <div class="processing-bar pending-bar"></div>
                <small class="pending-txt">Processing</small>
              </div>
              <div class="picked float-start">
                <div class="picked-circle pending-circle">
                  <font-awesome icon="circle" class="circle" />
                </div>
                <div class="picked-bar pending-bar"></div>
                <small class="pending-txt">Picked</small>
              </div>
              <div class="shipped float-start">
                <div class="shipped-circle pending-circle">
                  <font-awesome icon="circle" class="circle" />
                </div>
                <div class="shipped-bar pending-bar"></div>
                <small class="pending-txt">Shipped</small>
              </div>
              <div class="delivered float-start">
                <div class="delivered-circle pending-circle">
                  <font-awesome icon="circle" class="circle" />
                </div>
                <small class="pndelivery-txt">Delivered</small>
              </div>
            </div>
            <table class="table table-hover table-striped mt-2">
              <thead class="bg-secondary text-white">
                <th class="py-2 text-center">Sl</th>
                <th class="py-2 text-center">Product</th>
                <th class="py-2 ">Quantity</th>
                <th class="py-2 text-end pe-2">Unit Price</th>
                <th class="py-2 text-end pe-2">Total Price</th>
              </thead>
              <tbody>
                <tr v-for="(product, i) in order.products"
                  :key="product.product_id">
                  <td>{{ ++i }}</td>
                  <td>
                    <p class="mb-0">{{ product.product_name }}</p>
                    <p class="mb-0 small" v-if="product.variation_id">
                      {{ product.variation_category_name }}:
                      {{ product.variation_name }}
                    </p>
                  </td>
                  <td>{{ product.qty }}</td>
                  <td class="text-end pe-2">
                    {{ product.sale_price.toLocaleString() }}
                  </td>
                  <td class="text-end pe-2">
                    {{ product.total_amount.toLocaleString() }}
                  </td>
                </tr>
                <tr class="fw-bold">
                  <td colspan="4" class="text-end pe-2">Sub Total:</td>
                  <td class="text-end pe-2">
                    {{ order.sub_total.toLocaleString() }}
                  </td>
                </tr>
                <tr class="fw-bold">
                  <td colspan="4" class="text-end pe-2">(+)Shipping Fee:</td>
                  <td class="text-end pe-2">
                    {{ order.shipping_fee.toLocaleString() }}
                  </td>
                </tr>
                <tr class="fw-bold" v-if="order.discount > 0 && order.offer_id != null">
                  <td colspan="4" class="text-end pe-2">(-)Voucher Discount:</td>
                  <td class="text-end pe-2">
                    {{ order.discount.toLocaleString() }}
                  </td>
                </tr>
                <tr class="fw-bold">
                  <td colspan="4" class="text-end pe-2">Net Amount:</td>
                  <td class="text-end pe-2">
                    {{ order.net_amount.toLocaleString() }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- loading view -->
    <LoadingView v-if="showLoading"/>

  </div>
</template>
<script>
import moment from "moment";
import LoadingView from '@/components/Layouts/LoadingView.vue';
import { useHead } from "@vueuse/head";
export default {
  name: "OrderView",
  props: ["userInfo"],
  components: {
    LoadingView
  },

  // meta tag setup start
  setup() {
    useHead({
      title: 'Sopma Tech | Orders Details',
    });
  },
  // meta tag setup end

  data() {
    return {
      showLoading: true,
      order_id: this.$route.params.order_id,
      order: null,
    };
  },
  mounted() {
    this.getOrder();
  },
  methods: {
    getOrder() {
      // on page loading
      this.showLoading = true;

      this.$store
        .dispatch("OrderDetails", this.order_id)
        .then((response) => {
          this.order = response.data.data;
          // console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          // off page loading
          this.showLoading = false;
        });
    },
    dateTime(value) {
      return moment(value).format("DD MMM, YYYY hh:mma");
    },
  },
  watch: {},
};
</script>
<style scoped>
/* .placed,
.processing,
.picked,
.shipped,
.delivered {
  width: 87px;
}
.complete-circle {
  width: auto;
  height: auto;
  font-size: 25px;
  color: green;
}
.complete-bar {
  width: 65px;
  height: 5px;
  background: green;
  position: relative;
  left: 24px;
  top: -21px;
}
.pending-circle {
  width: 25px;
  height: 25px;
  font-size: 10px;
  color: green;
  border: 2px solid gray;
  border-radius: 50%;
  text-align: center;
  position: relative;
  top: 5px;
}
.pending-bar {
  width: 65px;
  height: 5px;
  background: gray;
  position: relative;
  left: 24px;
  top: -9px;
}
.processing-txt {
  font-size: 11px;
  position: relative;
  top: -16px;
  font-weight: bold;
  left: -5px;
}
.delivery-txt {
  font-size: 11px;
  position: relative;
  top: -11px;
  font-weight: bold;
  left: -14px;
}
.pndelivery-txt {
  font-size: 11px;
  position: relative;
  top: 2px;
  font-weight: bold;
  left: -14px;
}
.pending-txt {
  font-size: 11px;
  position: relative;
  top: -4px;
  font-weight: bold;
  left: -14px;
}
.circle {
  margin-top: 6px;
} */
</style>