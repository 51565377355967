<template>
    <footer>
        <div class="col-md-12 px-3">
            <div class="main-footer">
                <div class="footer-block about-us">
                    <h4>About Us</h4>
                    <ul>
                        <li><router-link to="/about-us">About Us</router-link></li>
                        <li><router-link to="/terms-conditions">Terms and Conditions</router-link></li>
                        <li><router-link to="/refund-return-policy">Refund and Return Policy</router-link></li>
                        <li><router-link to="/online-delivery">Online Delivery</router-link></li>
                        <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                        <li><router-link to="/contact">Contact Us</router-link></li>
                        <!-- <li><router-link to="/brands">Brands</router-link></li>
                        <li><router-link to="/emi-terms">EMI Terms</router-link></li> -->
                    </ul>
                </div>
                <div class="footer-block org-info">
                    <h4>Stay Connected</h4>
                    <p class="mb-0"><b class="store-name h5">{{ setting.name }}</b></p>
                    <p class="mb-0"><b>Address:</b> {{ setting.address }}</p>
                    <p class="mb-0"><b>E-mail:</b> <a :href="'mailto:'+setting.email">{{ setting.email }}</a> </p>
                    <p class="mb-0" v-for="phone in phones" :key="phone"><b>Phone:</b> {{ phone }}</p>
                </div>
            </div>

            <div class="social-footer">
                <div class="app-links">
                    <span class="app-link-text">Get {{ setting.name }} App:</span>
                    <span class="app-link-items">
                        <a class="app-link" :href="setting.google_playstore" target="_blank" :title="setting.name+' Android APP'">
                            <span class="icon-sprite playstore"></span>
                            <span class="app-link-text">
                                <span class="download">Download on</span>
                                <span class="app-store">Google Play</span>
                            </span>
                        </a>
                        <a class="app-link" :href="setting.apple_store" target="_blank" :title="setting.name+' ISO APP'">
                            <span class="icon-sprite applestore"></span>
                            <span class="app-link-text">
                                <span class="download">Download on</span>
                                <span class="app-store">App Store</span>
                            </span>
                        </a>
                    </span>
                </div>
                <div class="social-links">
                    <a :href="setting.facebook" title="Facebook">
                        <span class="icon-sprite fb"></span>
                    </a>
                    <a :href="setting.youtube" target="_blank" title="Youtube">
                        <span class="icon-sprite youtube"></span>
                    </a>
                    <a :href="setting.instagram" target="_blank" title="Instagram">
                        <span class="icon-sprite insta"></span>
                    </a>
                </div>
            </div>
            <div class="row sub-footer">
                <div class="col-md-12 copyright-info text-center">
                    <p class="mb-0">&copy; 2024 {{ setting.name }} | All rights reserved</p>
                </div>
                <!-- <div class="col-md-6 powered-by">
                    <p>Powered By: Compile BD</p>
                </div> -->
            </div>
        </div>
    </footer>
</template>
<script>
    export default{
        name: 'FooterView',
        props: ["setting"],
        data(){
            return{
                phones: [],
            }
        },

        methods: {
            // setting data fetch function
            getSettingData(){
                if(this.setting.phone){
                    this.phones = JSON.parse(this.setting.phone)??'';
                }
            },
        },

        // 
        mounted() {
            // call getSettingData for get site setting data
            this.getSettingData();
        },
        
    }
</script>
