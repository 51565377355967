<template>
    <div id="otp-verify">
        <section class="after-header p-tb-10 bg-white p-3">
            <div class="c-intro">
                <ul class="breadcrumb mb-0">
                    <li>
                        <router-link to="/">
                        <i class="material-icons" title="Home">home</i>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="register" class="text-capitalize">
                            <span>Register</span>
                        </router-link>
                    </li>
                    <li>
                        <a type="button" class="text-capitalize">
                            <span>Account Verify</span>
                        </a>
                    </li>
                </ul>
            </div>
        </section>

        <form class="register-area d-block mx-auto border bg-white rounded my-5">
            <h4 class="text-dark text-center pt-4">OTP Verification</h4>
            <div class="p-3">
                <p class="small">We've sent a 6-digit verification code to your email.</p>
                <div class="form-group px-0 pe-1">
                    <label for="first_name" class="mb-0 text-dark fw-bold">OTP <small class="text-danger">*</small></label>
                    <input type="text" v-model="form.otp" id="first_name" class="form-control px-4" placeholder="OTP" maxlength="6" style="letter-spacing: 10px;" autocomplete="off"/>
                    <span class="text-danger" id="otpMsg"></span>
                </div>

                <div class="form-group">
                    <!-- <router-link :to="this.$router.go(-1)" class="btn btn-primary ">Back</router-link> -->
                    <button type="button" class="btn btn-primary col-12" @click="verifyOtp">Confirm</button>
                    <button type="button" class="btn st-outline col-12 mt-3" @click="back">Back</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { useHead } from "@vueuse/head";

export default {
    name: "OtpVerify",
    setup(){
        useHead({
            title: 'Sopma Tech | OTP Verify'
        });
    },

    data(){
        return {
            redirectUrl: this.$route.params.redirectUrl,
            form: {
                otp: '',
                token: atob(this.$route.params.token),
            },
        };
    },

    methods: {
        verifyOtp(){
            let otpMsg = document.getElementById('otpMsg');
            otpMsg.innerHTML = '';
            if(this.form.otp == '' || this.form.otp.length < 6){
                otpMsg.innerHTML = 'A 6-digit OTP is required!';
            }else if(/^\d+$/.test(this.form.otp) == false){
                otpMsg.innerHTML = 'The OTP entered is invalid!';
            }else{
                this.$store.dispatch("verifyOtp", this.form).then((response) => {
                    if (response.status == 200) {
                        this.$router.push({
                            name: 'login',
                            params: { message: true }
                        });
                    }else{
                        otpMsg.innerHTML = response.data.message;
                    }
                })
                .catch((error) => {
                    otpMsg.innerHTML = error.response.data.message;
                });
            }
        },

        // 
        back(){
            this.$router.go(-1);
        }
    },

    watch: {
        ["form.otp"]() {
            document.getElementById('otpMsg').innerHTML = '';
        },
    }
}
</script>