<template>
  <div class="register col-md-12 px-0" @keyup.enter="update">
    <section class="after-header p-tb-10 bg-white p-3">
      <div class="c-intro">
        <ul class="breadcrumb mb-0">
          <li>
            <router-link to="/">
              <i class="material-icons" title="Home">home</i>
            </router-link>
          </li>
          <li>
            <router-link to="/account" class="text-capitalize">
              <span>Account</span>
            </router-link>
          </li>
          <li>
            <router-link to="/account/password-change" class="text-capitalize">
              <span>Change Password</span>
            </router-link>
          </li>
        </ul>
      </div>
    </section>
    
    <div class="col-md-12 bg-white mt-3">
      <div class="container ac-layout bg-white pt-3">
        <ul class="navbar-nav ac-navbar" style="height: 44px;">
          <li class="nav-item me-1">
            <router-link to="/account/orders" class="nav-link">
              <span class="material-icons">chrome_reader_mode</span>Orders
            </router-link>
          </li>
          <li class="nav-item mx-1">
            <router-link to="/account/profile" class="nav-link">
              <span class="material-icons">person</span>Profile
            </router-link>
          </li>
          <li class="nav-item mx-1 active">
            <router-link to="/account/password-change" class="nav-link">
              <span class="material-icons">lock</span>Change Password
            </router-link>
          </li>
          <li class="nav-item ms-1">
            <router-link to="/account/saved-pc-list" class="nav-link">
              <span class="material-icons">important_devices</span>Saved Pc
            </router-link>
          </li>
        </ul>


        <div class="order-details">
          <h4>Change Password</h4>
          <form class="register-area d-block mx-auto border bg-white rounded my-2">
            <div class="p-3">
              <div class="form-group mt-3" style="height: 61px;">
                <label for="password" class="d-block text-dark">Old Password <small class="text-danger">*</small></label>
                <input
                  :type="eye ? 'text' : 'password'"
                  v-model="registerForm.old_password"
                  class="form-control"
                  placeholder="Enter old password"
                />
                <font-awesome
                  :icon="eye ? 'eye' : 'eye-slash'"
                  class="pass"
                  @click="eye = !eye"
                ></font-awesome>
              </div>
              <div class="form-group mb-0" style="height: 85px;">
                <label for="password" class="d-block text-dark">New Password <small class="text-danger">*</small></label>
                <input
                  :type="new_eye ? 'text' : 'password'"
                  v-model="registerForm.new_password"
                  class="form-control"
                  placeholder="Enter new password"
                />
                <font-awesome
                  :icon="new_eye ? 'eye' : 'eye-slash'"
                  class="pass"
                  @click="new_eye = !new_eye"
                ></font-awesome>
              </div>
              <div class="form-group mb-0" style="height: 85px;">
                <label for="password" class="d-block text-dark">Confirm Password <small class="text-danger">*</small></label>
                <input
                  :type="con_eye ? 'text' : 'password'"
                  v-model="registerForm.confirm_password"
                  class="form-control"
                  placeholder="Enter confirm password"
                />
                <font-awesome
                  :icon="con_eye ? 'eye' : 'eye-slash'"
                  class="pass"
                  @click="con_eye = !con_eye"
                ></font-awesome>
              </div>
              <div class="form-group row mb-3">
                <div class="col-4">
                  <button type="reset" class="btn st-outline col-12">Clear</button>
                </div>
                <div class="col-4">
                  <router-link to="/account" class="btn st-outline col-12"
                    >Cancel</router-link
                  >
                </div>
                <div class="col-4">
                  <button
                    type="button"
                    class="btn btn-primary col-12"
                    @click="update"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- loading view -->
    <LoadingView v-if="showLoading"/>
  </div>
</template>

<script>
import LoadingView from '@/components/Layouts/LoadingView.vue';
import { useHead } from "@vueuse/head";
export default {
  name: "PasswordChange",
  props: ["userInfo"],
  components: {
    LoadingView
  },

  // meta tag setup start
  setup() {
    useHead({
      title: 'Sopma Tech | Change Password',
    });
  },
  // meta tag setup end

  data() {
    return {
      showLoading: true,
      eye: false,
      new_eye: false,
      con_eye: false,
      registerForm: {
        customer_id: this.userInfo.user.customer_id,
        old_password: "",
        new_password: "",
        confirm_password: "",
      },
    };
  },

  methods: {
    update() {
      var error = "";
      if (this.registerForm.old_password == "") {
        error += '<p class="text-danger mb-0">Old password is required!';
      }
      if (this.registerForm.new_password == "") {
        error += '<p class="text-danger mb-0">New password is required!</p>';
      } else if (this.registerForm.new_password.length < 6) {
        error +=
          '<p class="text-danger mb-0">Enter at least 6 digits password!</p>';
      }
      if (this.registerForm.new_password == "") {
        error +=
          '<p class="text-danger mb-0">Confirm password is required!</p>';
      } else if (this.registerForm.new_password.length < 6) {
        error +=
          '<p class="text-danger mb-0">Enter at least 6 digits password!</p>';
      } else if (
        this.registerForm.new_password !== this.registerForm.confirm_password
      ) {
        error +=
          '<p class="text-danger mb-0">New password and confirm password do not match!</p>';
      }
      if (error != "") {
        this.$swal({
          title: "REQUIRED!",
          icon: "error",
          html: error,
        });
      } else {
        this.$store
          .dispatch("passwordUpdate", this.registerForm)
          .then((response) => {
            if (response.status == 200) {
              var suc =
                '<p class="text-success mb-0">' +
                response.data.message +
                "</p>";
              this.$swal({
                title: "Success!",
                icon: "success",
                html: suc,
              });
              this.$router.push("/account");
            } else {
              var error =
                '<p class="text-danger mb-0">' + response.data.message + "</p>";
              this.$swal({
                title: "Warning!",
                icon: "error",
                html: error,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  created() {
    this.showLoading = true;
  },

  mounted(){
    this.showLoading = false;
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
