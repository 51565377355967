<template>
  <div class="product-area col-md-12 mt-2 mb-5 px-0">
    <section class="after-header p-tb-10 bg-white p-3">
      <div class="c-intro">
        <ul class="breadcrumb mb-0">
          <li>
            <router-link to="/">
              <i class="material-icons" title="Home">home</i>
            </router-link>
          </li>
          <li>
            <router-link to="/pc-builder" class="text-capitalize">
              <span>PC Builder</span>
            </router-link>
          </li>
          <li>
            <a type="button" class="text-capitalize">
              <span>Budget PC</span>
            </a>
          </li>
        </ul>
      </div>
    </section>

    <section class="p-item-page bg-bt-gray p-tb-15 px-3">
      <div class="row">
        <div id="column-left" class="col-sm-3" :class="filterModal? 'open':''">
          <span class="lc-close" @click="filterClose"><i class="material-icons" aria-hidden="true">close</i></span>
          <div class="filters">
            <div class="price-filter ws-box h-100">
              <div class="label">
                <span>Price Range</span>
              </div>
              <div class="price-range d-block float-start w-100 rounded bg-white px-2">
                <div class="range d-block px-1">
                  <div class="track-container">
                    <div class="track" ref="_vpcTrack"></div>
                    <div class="track-highlight" ref="trackHighlight"></div>
                    <button class="track-btn track1" ref="track1"></button>
                    <button class="track-btn track2" ref="track2"></button>
                  </div>
                </div>
                <div class="d-block float-start mt-3">
                  <input
                    type="text"
                    class="text-center min"
                    name="minValue"
                    v-model="minValue"
                    @blur="priceFilter($event)"
                  />
                  <input
                    type="text"
                    class="text-center max"
                    name="maxValue"
                    v-model="maxValue"
                    @blur="priceFilter($event)"
                  />
                </div>
              </div>
            </div>
          </div>  
        </div>
        <div id="content" class="col-xs-12 col-md-9 product-listing">
            <div class="top-bar ws-box">
                <div class="row">
                    <div class="col-sm-4 col-xs-2 actions">
                      <button class="tool-btn" id="lc-toggle" @click="filterClose"><i class="material-icons">filter_list</i> Filter</button>
                      <label class="page-heading m-hide text-capitalize">Budget PC</label>
                    </div>
                    <div class="col-sm-8 col-xs-10 show-sort">
                        <div class="form-group rs-none">
                            <label>Show:</label>
                            <div class="custom-select">
                                <select id="input-limit" class="limit" v-model="requestData.limit">
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="40">40</option>
                                    <option value="50">50</option>
                                    <option value="80">80</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Sort By:</label>
                            <div class="custom-select">
                                <select id="input-sort" class="sort" v-model="requestData.order">
                                    <option value="">Default</option>
                                    <option value="asc">Price (Low &gt; High)</option>
                                    <option value="desc">Price (High &gt; Low)</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-content p-items-wrap" v-if="products.data">
              <div class="p-item" v-for="product in products.data" :key="product.product_id">
                <div class="p-item-inner">
                  <div class="marks" v-if="product.discount > 0" >
                    <span class="mark">Save: {{ product.discount.toLocaleString() }}৳</span>                                        
                  </div>
                  <div class="p-item-img">
                    <router-link 
                      :to="{
                        name: 'productDetails',
                        params: {
                          productSlug: product.slug
                        },
                      }">
                      <img :src="product.thumbnail" :alt="product.product_name" width="228" height="228">
                    </router-link>
                  </div>
                    <div class="p-item-details">
                        <h4 class="p-item-name"> 
                            <router-link 
                              :to="{
                                name: 'productDetails',
                                params: {
                                  productSlug: product.slug
                                },
                              }">{{ product.product_name }}</router-link>
                        </h4>
                        <div class="short-description" v-html="product.key_features"></div>
                        <div class="p-item-price">
                            <span class="price-new">{{ product.sale_price.toLocaleString() }}৳</span> 
                            <small v-if="product.discount>0">
                              <del class="text-secondary ps-2">{{ product.original_price.toLocaleString() }}৳</del>
                            </small>                      
                        </div>
                        <div class="actions">
                          <a @click="addToPcBuilder(product.component_products)" type="button" class="st-btn btn-add-cart">
                            <i class="material-icons">important_devices</i> Add To PC Builder
                          </a>
                        </div>
                    </div>
                </div>
              </div>
            </div>
            <div class="d-block" v-else>
              <h4 class="text-center py-5">Product Not Found!</h4>
            </div>
            <div class="bottom-bar">
              <div class="row px-3" v-if="products.total">
                <div class="col-md-12 bg-white rounded p-2 shadow">
                  <div v-if="products.last_page > 1" class="d-inline-block">
                    <ul class="pagination">
                      <li v-if="products.current_page == 1">
                        <span class="disabled">PREV</span>
                      </li>
                      <li v-else>
                        <a type="button" @click="paginationPro(products.current_page - 1)">PREV</a>
                      </li>
                      <template v-for="page in products.last_page" :key="page">
                        <template v-if="page > products.current_page - 4 && page < products.current_page + 4">
                          <li v-if="page == products.current_page" class="active">
                            <span>{{ page }}</span>
                          </li>
                          <li v-else>
                            <a @click="paginationPro(page)" type="button">{{ page }}</a>
                          </li>
                        </template>
                      </template>
                      <li v-if="products.current_page == products.last_page">
                        <span class="disabled">NEXT</span>
                      </li>
                      <li v-else>
                        <a type="button" @click="paginationPro(products.current_page + 1)">NEXT</a>
                      </li>
                    </ul>
                  </div>
                  <div class="page-number d-inline-block float-end">
                    <p class="mt-1 mb-0">
                      <small class="fw-bold page-btn"
                        >Showing {{ products.from }} to {{ products.to }} of
                        {{ products.total }} ({{ products.last_page }}
                        {{ products.last_page > 1 ? "Pages" : "Page" }})</small
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div> 
        </div>
      </div>
    </section>

    <!-- loading view -->
    <LoadingView v-if="showLoading"/>

  </div>
</template>
<script>
  import LoadingView from '@/components/Layouts/LoadingView.vue';
  import { reactive, computed } from 'vue';
  import { useHead } from "@vueuse/head";

  export default {
    name: "BudgetPc",
    props: ["filterModal"],
    components: {
      LoadingView
    },

    // meta tag setup start
    setup() {
      // Define reactive properties within the setup function
      const siteData = reactive({
        title: 'Budget PC - Build Your Own Budget Customize Desktop Computer | Sopma Tech',
        description: "Sopma Tech is the Best Laptop, Computer, Gaming PC, Component, Accessories, and Gadget retail &amp; Online shop in Bangladesh. Sopma Tech offers the Latest Tech products with the most competitive price in BD.",
        keywords: "Laptop shop in Bangladesh, Laptop shop in bd, computer shop in Bangladesh, PC shop in Bangladesh, computer shop in BD, Gaming PC shop in Bangladesh, PC accessories shop in Bangladesh, best computer shop in Bangladesh, Gadget shop in bd, Gadget Shop in Bangladesh, Online Shop in BD, online computer shop in bd, computer accessories online shop in Bangladesh, computer parts shop in bd, Laptop in Bangladesh, Notebook, Laptop, Desktop, Brand PC, computer, computer store Bangladesh, laptop store Bangladesh, gaming, desktop, monitor, Sopma Tech, computer accessories, Desktop accessories, Laptop accessories, Laptop Online Store in BD, adata, apacer, apple, asus, bangladesh, baseus, belkin, benq, best, boya, brother, cable, camera, canon, GPU, graphics card, Sopma Tech &amp; Engineering Ltd, SOPMATECH, laptop price, laptop price in bangladesh, laptop price bd,",
      });

      useHead({
        title: computed(() => siteData.title),
        meta: [
          {
            name: 'description',
            content: computed(() => siteData.description),
          },
          {
            name: 'keywords',
            content: computed(() => siteData.keywords),
          },
        ],
      });
    },
    // meta tag setup end

    data() {
      return {
        showLoading: true,
        addAlert: "",
        alertMsg: "",
        loading: false,
        products: [],
        attributeShow: [],
        attributes: [],
        min: 0,
        max: 1000000,
        minValue: 0,
        maxValue: 1000000,
        step: 5,
        totalSteps: 0,
        percentPerStep: 1,
        trackWidth: null,
        isDragging: false,
        pos: {
          curTrack: null,
        },
        requestData: {
          price: [0, 1000000],
          limit: 20,
          order: "",
          stock_status: [],
          brand_id: [],
          variation_id: [],
          slug: "",
          page: 1,
        },
      };
    },
    methods: {
  
      paginationPro(page) {
        this.requestData.page = page;
        this.$store
          .dispatch("getBudgetPcProducts", this.requestData)
          .then((response) => {
            this.products = response.data;
          })
          .catch((error) => {
            console.log(error);
            this.products = [];
          });
      },
  
      // get category product function
      getProducts() {
        // on page loading
        this.showLoading = true;

        this.requestData.page = 1;
        this.$store
          .dispatch("getBudgetPcProducts", this.requestData)
          .then((response) => {
            this.products = response.data;
            console.log(this.products.data);
          })
          .catch((error) => {
            console.log(error);
            this.products = [];
          })
          .finally(() => {
            // off page loading
            this.showLoading = false;
          });
      },
  
      // get category products attributes and brands
      getBrandsAttributes() {
        this.$store
          .dispatch("categoryProductAttributeBrand", 'Budget PC')
          .then((response) => {
            this.brands = response.data.data.brands;
            this.attributes = response.data.data.attributes;
            this.max = response.data.data.max_price;
            this.maxValue = response.data.data.max_price;
            this.min = response.data.data.min_price;
            this.minValue = response.data.data.min_price;
            this.requestData.price = [this.minValue, this.maxValue]
          })
          .catch((error) => {
            console.log(error);
            this.brands = [];
            this.attributes = [];
          });
      },
  
      modalClose() {
        this.addAlert = "";
      },
      // responsive design filter close function
      filterClose() {
        this.$emit("filter");
      },
  
      moveTrack(track, ev) {
        let percentInPx = this.getPercentInPx();
  
        let trackX = Math.round(
          this.$refs._vpcTrack.getBoundingClientRect().left
        );
        let clientX = ev.clientX;
        let moveDiff = clientX - trackX;
  
        let moveInPct = moveDiff / percentInPx;
        // console.log(moveInPct)
  
        if (moveInPct < 1 || moveInPct > 100) return;
        let value =
          Math.round(moveInPct / this.percentPerStep) * this.step + this.min;
        if (track === "track1") {
          if (value >= this.maxValue - this.step) return;
          this.minValue = value;
          this.requestData.price = [value, this.maxValue];
        }
  
        if (track === "track2") {
          if (value <= this.minValue + this.step) return;
          this.maxValue = value;
          this.requestData.price = [this.minValue, value];
        }
  
        this.$refs[track].style.left = moveInPct + "%";
        this.setTrackHightlight();
      },
      mousedown(ev, track) {
        if (this.isDragging) return;
        this.isDragging = true;
        this.pos.curTrack = track;
        console.log(ev);
      },
  
      touchstart(ev, track) {
        this.mousedown(ev, track);
      },
  
      mouseup(ev, track) {
        if (!this.isDragging) return;
        this.isDragging = false;
        console.log(ev);
        console.log(track);
        this.getProducts();
      },
  
      touchend(ev, track) {
        // console.log('touch end');
        this.mouseup(ev, track);
      },
  
      mousemove(ev, track) {
        if (!this.isDragging) return;
        this.moveTrack(track, ev);
      },
  
      touchmove(ev, track) {
        this.mousemove(ev.changedTouches[0], track);
      },
  
      valueToPercent(value) {
        return ((value - this.min) / this.step) * this.percentPerStep;
      },
  
      setTrackHightlight() {
        this.$refs.trackHighlight.style.left =
          this.valueToPercent(this.minValue) + "%";
        this.$refs.trackHighlight.style.width =
          this.valueToPercent(this.maxValue) -
          this.valueToPercent(this.minValue) +
          "%";
      },
  
      getPercentInPx() {
        let trackWidth = this.$refs._vpcTrack.offsetWidth;
        let oneStepInPx = trackWidth / this.totalSteps;
        // 1 percent in px
        let percentInPx = oneStepInPx / this.percentPerStep;
  
        return percentInPx;
      },
  
      setClickMove(ev) {
        let track1Left = this.$refs.track1.getBoundingClientRect().left;
        let track2Left = this.$refs.track2.getBoundingClientRect().left;
        // console.log('track1Left', track1Left)
        if (ev.clientX < track1Left) {
          this.moveTrack("track1", ev);
        } else if (ev.clientX - track1Left < track2Left - ev.clientX) {
          this.moveTrack("track1", ev);
        } else {
          this.moveTrack("track2", ev);
        }
        this.getProducts();
      },

  
      // filter product attribute selection function
      attributeClick(event) {
        if (event.target.checked) {
          this.requestData.variation_id.push(event.target.value);
        } else {
          this.requestData.variation_id = this.requestData.variation_id.filter(
            (variation) => {
              return variation !== event.target.value;
            }
          );
        }
        this.getProducts();
      },

  
      // price filter function
      priceFilter(event) {
        console.log(event.target.value);
        this.getProducts();
      },
  
      // product attribute show close function
      attributeToggle(si) {
        this.attributes[si].show = !this.attributes[si].show;
      },
  
  
      // set default data
      dataSet() {
        this.requestData.price = [0, 1000000];
        this.requestData.limit = 20;
        this.requestData.variation_id = [];
        this.requestData.page = 1;
      },

      // product add to pc builder function
      addToPcBuilder(components){
        this.$store
          .dispatch("componentAddToPcBuilder", components)
          .then((response) => {
            this.$router.push("/pc-builder");
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
      },

      setPriceRange(){
        // this.getFilters();
        // calc per step value
        this.totalSteps = (this.max - this.min) / this.step;
    
        // percent the track button to be moved on each step
        this.percentPerStep = 100 / this.totalSteps;
        // console.log('percentPerStep', this.percentPerStep)
    
        // set track1 initilal
        document.querySelector(".track1").style.left =
          this.valueToPercent(this.minValue) + "%";
        // track2 initial position
        document.querySelector(".track2").style.left =
          this.valueToPercent(this.maxValue) + "%";
        // set initila track highlight
        this.setTrackHightlight();
    
        var self = this;
    
        ["mouseup", "mousemove"].forEach((type) => {
          document.body.addEventListener(type, (ev) => {
            // ev.preventDefault();
            if (self.isDragging && self.pos.curTrack) {
              self[type](ev, self.pos.curTrack);
            }
          });
        });
    
        [
          "mousedown",
          "mouseup",
          "mousemove",
          "touchstart",
          "touchmove",
          "touchend",
        ].forEach((type) => {
          document.querySelector(".track1").addEventListener(type, (ev) => {
            ev.stopPropagation();
            self[type](ev, "track1");
          });
          document.querySelector(".track2").addEventListener(type, (ev) => {
            ev.stopPropagation();
            self[type](ev, "track2");
          });
        });
    
        // on track clik
        // determine direction based on click proximity
        // determine percent to move based on track.clientX - click.clientX
        document.querySelector(".track").addEventListener("click", function (ev) {
          ev.stopPropagation();
          self.setClickMove(ev);
        });
    
        document
          .querySelector(".track-highlight")
          .addEventListener("click", function (ev) {
            ev.stopPropagation();
            self.setClickMove(ev);
        });
      }

    },
    created() {
      // console.log("created");
    },
    mounted() {
      // document.onreadystatechange = () => {
      //     if (document.readyState == "complete") {
      //         this.loading = true;
      //         console.log('sdfsd');
      //     }
      // };
      // call product functon
      this.dataSet();
      this.getProducts();      
  
      this.getBrandsAttributes();
      setTimeout(()=>{
        this.setPriceRange();
      },3000);
    },
    watch: {
      $route() {
        if (this.$route.name == "getBudgetPcProducts") {
          this.dataSet();
          this.getProducts();
          this.getBrandsAttributes();
          let allSelected = this.$el.querySelectorAll(".availability-check");
          allSelected.forEach((singleSL) => {
            singleSL.checked = false;
          });
          this.$el.querySelector(".limit").value = 20;
          this.$el.querySelector(".sort").value = "";

          setTimeout(()=>{
            this.setPriceRange();
          },3000);
        }
      },
      // // if change order then will be call getproducts function
      ["requestData.order"]() {
        this.getProducts();
      },
      // if change limit then will be call getproducts function
      ["requestData.limit"]() {
        this.getProducts();
      },
    },
  };
  </script>
  <style scoped>
  .feature {
    border-bottom: 2px solid #293a99 !important;
  }
  .thumble {
    height: 221px;
    width: 221px;
    text-align: center;
  }
  .pro-card-area {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
    padding: 0;
    justify-content: flex-start;
    background: none;
  }
  
  .pro-card {
    flex: 0 0 25%;
    max-width: 25%;
    padding: 0 5px 10px;
    margin-bottom: 0;
    display: flex;
    position: relative;
  }
  .pro-card-inner {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
  }
  .pro-card-inner:hover {
    box-shadow: 0px 0px 8px 1px gray;
  }
  .marks {
    display: flex;
    position: absolute;
    top: 5px;
    left: 0;
    z-index: 10;
    flex-direction: column;
    align-items: flex-start;
  }
  .mark {
    background: #293a99;
    width: auto;
    color: #fff;
    font-size: 12px;
    padding: 3px 10px;
    line-height: 14px;
    margin-bottom: 2px;
    border-radius: 0 20px 20px 0;
    flex: 0 0 auto;
  }
  
  .pro-image {
    text-align: center;
    border-bottom: 3px solid rgba(55, 73, 187, 0.03);
    flex: 0 0 220px;
    padding: 20px;
    margin: 0;
  }
  .pro-image img {
    max-width: 100%;
  }
  .pro-details {
    padding: 10px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    border-bottom: 3px solid rgba(55, 73, 187, 0.03);
  }
  .price-card {
    font-size: 14px;
  }
  .pro-name {
    font-weight: 400;
    font-size: 15px;
  }
  .pro-price {
    line-height: 22px;
    font-size: 17px;
    font-weight: 600;
    color: #ef4a23;
  }
  .text-dark {
    color: black;
  }
  .buy-btn {
    padding: 10px;
  }
  .btn-primary {
    background: #293a99;
  }
  .btn-out {
    background: #293475c7;
    color: white;
  }
  .btn-primary:hover {
    box-shadow: 0px 3px 3px 1px darkblue;
    color: #fffcfccc;
  }
  .btn-secondary:hover {
    box-shadow: 0px 3px 3px 1px #648fb9;
    color: #fffcfccc;
  }
  .gallary-img {
    height: 50px;
    width: 50px;
    border: 1px solid lightgray;
    padding: 5px;
    margin: 1px;
  }
  .price-bg {
    background: #f1f1f3d1;
  }
  .link-btn {
    background: #1934cfad;
    color: white;
  }
  .filter-border {
    border-bottom: 1px solid #80808057;
  }
  .link-active {
    background: #00008bc7;
    box-shadow: 0px 0px 4px 1px #1934cf;
  }
  .filter-btn,
  .per-page,
  .filter-sort {
    background: #e7e9ed;
    color: black;
    font-size: 14px;
    cursor: pointer;
  }
  .availability {
    height: 16px;
    width: 16px;
    position: relative;
    top: 3px;
  }
  .availability-grp:hover {
    background: #02189178;
    color: white;
  }
  .resp-filter-area {
    position: absolute;
    width: 280px;
    background: #f2f4f8;
    top: 0;
    left: 10px;
    box-shadow: 0 0 10px 5px grey;
    z-index: 999;
    padding: 10px;
    overflow-y: scroll;
    height: 100%;
  }
  .filter-area {
    display: block;
  }
  .filter-btn {
    display: none;
  }
  .page-btn {
    font-size: 11px;
  }
  @media (max-width: 1279px) {
    .pro-card {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
  @media (max-width: 1200px) {
    .filter-area {
      display: none;
    }
    .filter-btn {
      display: block;
    }
  }
  @media (min-width: 992px) {
    .modal-dialog {
      width: 95%;
    }
  }
  @media (max-width: 767px) {
    .pro-card {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .modal-dialog {
      width: 95%;
    }
  }
  
  .min {
    width: 47%;
    float: left;
  }
  
  .max {
    width: 47%;
    float: right;
  }
  .track-container {
    width: 100%;
    position: relative;
    cursor: pointer;
    height: 0.5rem;
  }
  
  .track,
  .track-highlight {
    display: block;
    position: absolute;
    width: 100%;
    height: 0.5rem;
  }
  
  .track {
    background-color: #cccfd4;
  }
  
  .track-highlight {
    background-color: #293a99b3;
    z-index: 2;
  }
  
  .track-btn {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    cursor: pointer;
    display: block;
    position: absolute;
    z-index: 2;
    width: 1.2rem;
    height: 1.2rem;
    top: calc(-50% - 0.15rem);
    margin-left: -0.7rem;
    border: none;
    background-color: #293a99;
    border-radius: 50%;
    -ms-touch-action: pan-x;
    touch-action: pan-x;
    transition: box-shadow 0.3s ease-out, background-color 0.3s ease,
      -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out,
      background-color 0.3s ease;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out,
      background-color 0.3s ease, -webkit-transform 0.3s ease-out;
  }
  .min-resp,
  .max-resp {
    width: 113px;
  }
  </style>
  