<template>
  <div class="drawer m-cart px-0" :class="cartShow? 'open':''" id="m-cart">
    <div class="title">
      <p>YOUR CART</p>
      <span class="mc-toggler loaded close" @click="cartClose"><i class="material-icons">close</i></span>
    </div>
    <div class="content">
      <template v-if="cartProducts.length">
        <div class="item" v-for="cartpro in cartProducts" :key="cartpro.cartId">
          <div class="image">
            <img :src="cartpro.product.thumbnail" width="47" height="47">
          </div>
          <div class="info">
            <div class="name">{{ cartpro.product.product_name }}</div>
            <span class="amount">{{ cartpro.product.sale_price.toLocaleString() }}৳</span>
            <i class="material-icons">clear</i>
            <span>{{ cartpro.qty.toLocaleString() }}</span>
            <span class="eq"> = </span>
            <span class="total">{{ (cartpro.product.sale_price * cartpro.qty) .toLocaleString() }}৳</span>
          </div>
          <div class="remove" @click="removeProduct(cartpro.cartId)" title="Remove"><i class="material-icons" aria-hidden="true">delete</i></div>
        </div>
      </template>
      <div v-else class="empty-content">
        <p class="text-center">Your shopping cart is empty!</p>
      </div>
    </div>
    <div class="footer">
      <!-- <div class="promotion-code">
        <div class="input-group">
          <input type="text" placeholder="Promo Code" id="input-cart-coupon">
          <span class="input-group-btn"><button data-target="#input-cart-coupon" class="btn button-coupon" type="submit">Apply</button></span>
        </div>
      </div> -->
      <!-- <div class="total">
        <div class="title">Sub-Total</div>
        <div class="amount">0৳</div>
      </div> -->
      <div class="total">
        <div class="title">Total</div>
        <div class="amount" v-if="cartTotal.subTotal">{{ cartTotal.subTotal.toLocaleString() }}৳</div>
      </div>
      <div class="checkout-btn" v-if="cartProducts.length">
        <router-link to="/checkout" @click="cartClose"><button class="btn submit">Checkout</button></router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CartView",
  props: ["cartProducts", "cartTotal", "cartShow"],
  data() {
    return {};
  },
  methods: {
    cartClose() {
      this.$emit("closeCart");
    },
    removeProduct(product_id) {
      this.$store.dispatch("removeProduct", product_id);
      this.$emit("cartReload");
    },
    clearCart() {
      this.$store.dispatch("clearCart");
      this.$emit("cartReload");
    },
  },
};
</script>