<template>
    <div class="register col-md-12 mt-2 mb-5 px-0" @keyup.enter="update">
      <section class="after-header p-tb-10 bg-white p-3">
      <div class="c-intro">
        <ul class="breadcrumb mb-0">
          <li>
            <router-link to="/">
              <i class="material-icons" title="Home">home</i>
            </router-link>
          </li>
          <li>
            <router-link 
              to="/pc-builder" class="text-capitalize">
              <span>PC Builder</span>
            </router-link>
          </li>
          <li>
            <router-link 
              to="/account/save-pc" class="text-capitalize">
              <span>Save PC</span>
            </router-link>
          </li>
        </ul>
      </div>
    </section>

      <section class="p-item-page bg-bt-gray p-tb-15 px-3">
        <div class="row">
          <div class="col-md-12">
              <form action="" class="mx-5 bg-white border p-3 rounded">
                  <div class="form-group mb-3">
                      <label for="pcName">PC Name <span class="text-danger">*</span></label>
                      <input type="text" v-model="name" class="form-control" id="pcName" maxlength="255">
                      <span class="text-danger">{{  nameError  }}</span>
                  </div>
                  <div class="form-group mb-3">
                      <label for="description">Description <span class="text-danger">*</span></label>
                      <textarea v-model="description" id="description" rows="3" class="form-control"></textarea>
                  </div>

                  <div class="form-group text-end mb-3">
                      <button type="reset" class="btn st-outline me-2">Reset</button>
                      <button type="button" class="btn btn-primary" @click="savePc">Save</button>
                  </div>
              </form>
          </div>
        </div>
      </section>

      <!-- loading view -->
      <LoadingView v-if="showLoading"/>

    </div>
</template>
  
<script>
  import LoadingView from '@/components/Layouts/LoadingView.vue';
  import { useHead } from "@vueuse/head";
  export default {
    name: "AccountView",
    props: ["userInfo"],
    components: {
      LoadingView
    },

    // meta tag setup start
    setup() {
      useHead({
        title: 'PC Save | Sopma Tech',
      });
    },
    // meta tag setup end

    data() {
      return {
        showLoading: false,
        name: '',
        description: '',
        nameError: '',
        coreComponents: [],
        peripheralsComponents: [],
        pcBuilderComponentCart: {},                
      };
    },
    methods: {
        getPcBuilderComponents() {
          this.$store
            .dispatch("getPcBuilderComponents")
            .then((response) => {
            this.coreComponents = response.data.coreComponents;
            this.peripheralsComponents = response.data.peripherals;
          })
            .catch((error) => {
            console.log(error);
          });
        },
        savePc(){
            let success = true;
            this.nameError = '';
            if(this.name.length == 0){
                this.nameError = 'PC name is required!';
                success = false;
            }
            if(success){
              this.showLoading = true;
                let formData = {
                    name: this.name,
                    description: this.description,
                    components: [],
                };
                // core components data insert
                this.coreComponents.forEach(item =>{
                    let componentId = 'componentId'+item.category_id;
                    if(this.pcBuilderComponentCart[componentId]){
                        this.pcBuilderComponentCart[componentId].products.forEach(product => {
                            formData.components.push({
                                pc_builder_component_id: item.id,
                                product_id: product.product.product_id,
                                variation_id: '',
                                qty: 1,
                            });
                        });
                    }                         
                });
                // core components data insert
                this.peripheralsComponents.forEach(item =>{
                    let componentId = 'componentId'+item.category_id;
                    if(this.pcBuilderComponentCart[componentId]){
                        this.pcBuilderComponentCart[componentId].products.forEach(product => {
                            formData.components.push({
                                pc_builder_component_id: item.id,
                                product_id: product.product.product_id,
                                variation_id: '',
                                qty: 1,
                            })
                        })
                    }                         
                });
                this.$store.dispatch("savePcBuilder", formData)
                  .then((response) => {
                      console.log(response);
                      this.$router.push('saved-pc-list');
                      this.showLoading = false;
                  })
                    .catch((error) => {
                    console.log(error);
                    this.showLoading = false;
                });
            }
        }
    },
    mounted() {
        this.getPcBuilderComponents();
        this.pcBuilderComponentCart = this.$store.getters.pcBuilderComponentCart;
    },
  };
  </script>
  
