<template>
    <div id="otp-verify">
        <section class="after-header p-tb-10 bg-white p-3">
            <div class="c-intro">
                <ul class="breadcrumb mb-0">
                    <li>
                        <router-link to="/">
                        <i class="material-icons" title="Home">home</i>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="login" class="text-capitalize">
                            <span>Login</span>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="forget-password" class="text-capitalize">
                            <span>Forget Password</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </section>

        <form class="register-area d-block mx-auto border bg-white rounded my-5">
            <h4 class="text-dark text-center pt-4">Forgot Your Password</h4>
            <div class="p-3">
                <div class="form-group px-0 pe-1">
                    <label for="mobileOrEmail" class="mb-0 text-dark fw-bold">Phone / E-Mail <small class="text-danger">*</small></label>
                    <input type="text" v-model="form.mobileOrEmail" id="mobileOrEmail" class="form-control px-4" placeholder="Enter your phone/e-mail" maxlength="100" autocomplete="off"/>
                    <span class="text-danger" id="errorMsg"></span>
                    <span class="text-success fw-bold py-2" id="successMsg"></span>
                </div>

                <div class="form-group">
                    <button type="button" class="btn btn-primary col-12" @click="verifyAccount">Confirm</button>
                    <button type="button" class="btn st-outline col-12 mt-3" @click="back">Back</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { useHead } from "@vueuse/head";

export default {
    name: "ForgetPassword",
    setup(){
        useHead({
            title: 'Forget Password | Sopma Tech'
        });
    },

    data(){
        return {
            form: {
                mobileOrEmail: ''
            },
        };
    },

    methods: {
        verifyAccount(){
            let errorMsg = document.getElementById('errorMsg');
            errorMsg.innerHTML = '';
            let successMsg = document.getElementById('successMsg');
            successMsg.innerHTML = '';
            if(this.form.mobileOrEmail == ''){
                errorMsg.innerHTML = 'Phone or E-mail is required!';
            }else{
                this.$store.dispatch("forgetPasswordRequest", this.form).then((response) => {
                    if (response.status == 200) {
                        successMsg.innerHTML = response.data.message;
                    }else{
                        errorMsg.innerHTML = response.data.message;
                    }
                })
                .catch((error) => {
                    errorMsg.innerHTML = error.response.data.message;
                });
            }
        },

        // 
        back(){
            this.$router.go(-1);
        }
    },

    watch: {
        ['form.mobileOrEmail'] () {
            document.getElementById('errorMsg').innerHTML = '';
            document.getElementById('successMsg').innerHTML = '';
        },
    }
}
</script>