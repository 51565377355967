<template>
  <div class="product-area col-md-12 mt-2 mb-5 px-0">
    <section class="after-header p-tb-10 bg-white p-3">
      <div class="c-intro">
        <ul class="breadcrumb mb-0">
          <li>
            <router-link to="/">
              <i class="material-icons" title="Home">home</i>
            </router-link>
          </li>
          <li>
            <router-link to="/offers" class="text-capitalize">
              <span>Offers</span>
            </router-link>
          </li>
          <li>
            <router-link :to="'/offer-info/' + pagePar.slug + '/' + pagePar.offer_id" class="text-capitalize">
              <span>{{ pagePar.slug.replace(/-/g, " ") }}</span>
            </router-link>
          </li>
        </ul>
        <!-- <div class="child-list">
          <a href="pc">Desktop</a>    
        </div> -->
      </div>
    </section>

    <section class="p-item-page bg-bt-gray p-tb-15 px-3">
      <div class="row">
        <!-- <div id="column-left" class="col-sm-3" :class="filterModal? 'open':''">
          <span class="lc-close" @click="filterClose"><i class="material-icons" aria-hidden="true">close</i></span>
          <div class="filters">
            <div class="price-filter ws-box h-100">
              <div class="label">
                <span>Price Range</span>
              </div>
              <div class="price-range d-block float-start w-100 rounded bg-white px-2">
                <div class="range d-block px-1">
                  <div class="track-container">
                    <div class="track" ref="_vpcTrack"></div>
                    <div class="track-highlight" ref="trackHighlight"></div>
                    <button class="track-btn track1" ref="track1"></button>
                    <button class="track-btn track2" ref="track2"></button>
                  </div>
                </div>
                <div class="d-block float-start mt-3">
                  <input
                    type="text"
                    class="text-center min"
                    name="minValue"
                    v-model="minValue"
                    @blur="priceFilter($event)"
                  />
                  <input
                    type="text"
                    class="text-center max"
                    name="maxValue"
                    v-model="maxValue"
                    @blur="priceFilter($event)"
                  />
                </div>
              </div>
            </div>
             <div class="filter-group ws-box" :class="statusShow? 'show':''">
                <div class="label" @click="statusToggle()">
                  <span>Availability</span>
                </div>
                <div class="items">
                  <label class="filter">
                    <input type="checkbox" value="1" @click="stockStatusf($event)">
                    <span>In Stock</span>
                  </label>
                  <label class="filter">
                    <input type="checkbox" value="3" @click="stockStatusf($event)">
                    <span>Up Coming</span>
                  </label>
                </div>
            </div>
            <div class="filter-group ws-box" :class="brandShow? 'show':''">
                <div class="label" @click="brandToggle()">
                  <span>Brand</span>
                </div>
                <div class="items">
                  <label class="filter" v-for="brand in brands" :key="brand.brand_id">
                    <input type="checkbox" name="brand_id[]"
                      :id="'brandId' + brand.brand_id"
                      :value="brand.brand_id"
                      @click="brandClick($event)">
                    <span>{{ brand.brand_name }}</span>
                  </label>
                </div>
            </div>
            <template v-for="(cat, si) in attributes" :key="si">
              <div class="filter-group ws-box" :class="cat.show? 'show':''" >
                <div class="label" @click="attributeToggle(si)">
                  <span>{{ cat.category }}</span>
                </div>
                <div class="items">
                  <label class="filter" v-for="variation in cat.attributes" :key="variation.variation_id">
                    <input type="checkbox" name="variation_id[]"
                      :id="'variationId' + variation.variation_id"
                      :value="variation.variation_id"
                      @click="attributeClick($event)">
                    <span>{{ variation.variation_name }}</span>
                  </label>
                </div>
              </div>
            </template>
          </div>  
        </div> -->
        <div id="content" class="col-xs-12 col-md-9 product-listing">
          <div class="top-bar ws-box">
            <div class="row">
              <div class="col-sm-4 col-xs-2 actions">
                <button class="tool-btn" id="lc-toggle" @click="filterClose"><i class="material-icons">filter_list</i> Filter</button>
                <label class="page-heading m-hide text-capitalize">{{ siteTitle }}</label>
              </div>
              <div class="col-sm-8 col-xs-10 show-sort">
                  <div class="form-group rs-none">
                      <label>Show:</label>
                      <div class="custom-select">
                          <select id="input-limit" class="limit" v-model="pagePar.limit">
                              <option value="20">20</option>
                              <option value="30">30</option>
                              <option value="40">40</option>
                              <option value="50">50</option>
                              <option value="80">80</option>
                              <option value="100">100</option>
                          </select>
                      </div>
                  </div>
                  <div class="form-group">
                      <label>Sort By:</label>
                      <div class="custom-select">
                          <select id="input-sort" class="sort" v-model="pagePar.order">
                              <option value="">Default</option>
                              <option value="asc">Price (Low &gt; High)</option>
                              <option value="desc">Price (High &gt; Low)</option>
                          </select>
                      </div>
                  </div>
              </div>
            </div>
          </div>
          <div class="main-content p-items-wrap" v-if="products.data">
            <div class="p-item" v-for="product in products.data" :key="product.product_id">
              <div class="p-item-inner">
                <div class="marks" v-if="product.discount > 0" >
                  <span class="mark">Save: {{ product.discount.toLocaleString() }}৳</span>                                        
                </div>
                <div class="p-item-img">
                  <router-link 
                    :to="{
                      name: 'offerProductDetails',
                      params: {
                        productSlug: product.slug,
                        offerSlug: this.$route.params.slug,
                        offerId: this.$route.params.offer_id,
                      },
                    }">
                    <img :src="product.thumbnail" :alt="product.product_name" width="228" height="228">
                  </router-link>
                </div>
                  <div class="p-item-details">
                      <h4 class="p-item-name"> 
                        <router-link 
                          :to="{
                            name: 'offerProductDetails',
                            params: {
                              productSlug: product.slug,
                              offerSlug: this.$route.params.slug,
                              offerId: this.$route.params.offer_id,
                            },
                          }">{{ product.product_name }}</router-link>
                      </h4>
                      <div class="short-description" v-html="product.key_features"></div>
                      <div class="p-item-price">
                          <span class="price-new">{{ product.sale_price.toLocaleString() }}৳</span> 
                          <small v-if="product.discount>0 && product.original_price>0">
                            <del class="text-secondary ps-2">{{ product.original_price.toLocaleString() }}৳</del>
                          </small>                      
                      </div>
                      <div class="actions">
                          <span v-if="product.stock_status == 2" class="st-btn stock-status">Stock Out</span>
                          <span v-else-if="product.stock_status == 3" class="st-btn stock-status">Up Coming</span>
                          <template v-else>
                            <router-link
                              v-if="product.variation_type"
                              class="st-btn btn-add-cart" type="button"
                              :to="{
                                name: 'offerProductDetails',
                                params: {
                                  productSlug: product.slug,
                                  offerSlug: this.$route.params.slug,
                                  offerId: this.$route.params.offer_id,
                                },
                              }">
                              <i class="material-icons">shopping_cart</i> Buy Now
                            </router-link>
                            <span v-else class="st-btn btn-add-cart" type="button" @click="addToCart(product)">
                              <i class="material-icons">shopping_cart</i> Buy Now
                            </span>
                          </template>
                          <span class="st-btn btn-compare" @click="addtoComparef(product)">
                            <i class="material-icons">library_add</i>Add to Compare
                          </span>
                      </div>
                  </div>
              </div>
            </div>
          </div>
          <div class="d-block" v-else>
            <h4 class="text-center py-5">Product Not Found!</h4>
          </div>
          <div class="bottom-bar">
            <div class="row px-3" v-if="products.total">
              <div class="col-md-12 bg-white rounded p-2 shadow">
                <div v-if="products.last_page > 1" class="d-inline-block">
                  <ul class="pagination">
                    <li v-if="products.current_page == 1">
                      <span class="disabled">PREV</span>
                    </li>
                    <li v-else>
                      <a type="button" @click="paginationPro(products.current_page - 1)">PREV</a>
                    </li>
                    <template v-for="page in products.last_page" :key="page">
                      <template v-if="page > products.current_page - 4 && page < products.current_page + 4">
                        <li v-if="page == products.current_page" class="active">
                          <span>{{ page }}</span>
                        </li>
                        <li v-else>
                          <a @click="paginationPro(page)" type="button">{{ page }}</a>
                        </li>
                      </template>
                    </template>
                    <li v-if="products.current_page == products.last_page">
                      <span class="disabled">NEXT</span>
                    </li>
                    <li v-else>
                      <a type="button" @click="paginationPro(products.current_page + 1)">NEXT</a>
                    </li>
                  </ul>
                </div>
                <div class="page-number d-inline-block float-end">
                  <p class="mt-1 mb-0">
                    <small class="fw-bold page-btn"
                      >Showing {{ products.from }} to {{ products.to }} of
                      {{ products.total }} ({{ products.last_page }}
                      {{ products.last_page > 1 ? "Pages" : "Page" }})</small
                    >
                  </p>
                </div>
              </div>
            </div>
          </div> 
        </div>
      </div>
    </section>

    <!-- alert modal  -->
    <ProductAddAlert
      :modalAlert="addAlert"
      :alertMsg="alertMsg"
      @modalClose="modalClose"
    />

    <!-- loading view -->
    <LoadingView v-if="showLoading"/>

  </div>
</template>
<script>
import LoadingView from '@/components/Layouts/LoadingView.vue';
import ProductAddAlert from "@/components/ProductAddAlert.vue";
import { reactive, watch, computed, ref } from 'vue';
import { useHead } from "@vueuse/head";
export default {
  name: "OfferProducts",
  props: ["filterModal"],
  components: {
    LoadingView, ProductAddAlert
  },

    // meta tag setup start
  setup() {
    // Define reactive properties within the setup function
    const siteTitle = ref('siteTitle');
    const siteData = reactive({
      title: siteTitle.value +' | Sopma Tech',
      description: siteTitle.value,
      keywords: "Laptop shop in Bangladesh, Laptop shop in bd, computer shop in Bangladesh, PC shop in Bangladesh, computer shop in BD, Gaming PC shop in Bangladesh, PC accessories shop in Bangladesh, best computer shop in Bangladesh, Gadget shop in bd, Gadget Shop in Bangladesh, Online Shop in BD, online computer shop in bd, computer accessories online shop in Bangladesh, computer parts shop in bd, Laptop in Bangladesh, Notebook, Laptop, Desktop, Brand PC, computer, computer store Bangladesh, laptop store Bangladesh, gaming, desktop, monitor, Sopma Tech, computer accessories, Desktop accessories, Laptop accessories, Laptop Online Store in BD, adata, apacer, apple, asus, bangladesh, baseus, belkin, benq, best, boya, brother, cable, camera, canon, GPU, graphics card, Sopma Tech &amp; Engineering Ltd, SOPMATECH, laptop price, laptop price in bangladesh, laptop price bd,",
    });

    // Watch for changes in siteTitle and update siteData.title accordingly
    watch(siteTitle, (newSiteTitle) => {
      siteData.title = newSiteTitle +' | Sopma Tech'
    });

    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: 'description',
          content: computed(() => siteData.description),
        },
        {
          name: 'keywords',
          content: computed(() => siteData.keywords),
        },
      ],
    });

    // Access siteTitle directly in setup
    return {
      siteTitle,
    };
  },
  // meta tag setup end


  data() {
    return {
      showLoading: true,
      addAlert: "",
      alertMsg: "",
      pagePar: {
        page: 1,
        order: "asc",
        limit: 20,
      },
      products: [],
      buyModalShow: "",
      product_detail: "",
      cartForm: {
        variation_type_id: "",
        variation_id: "",
        qty: 1,
        product: "",
      },
    };
  },
  methods: {
    addtoComparef(product) {
      this.$store.dispatch("addtoCompare", product);
    },
    // product add to cart function
    addToCart(product) {
      let cartForm = {
        qty: 1,
        product: product,
        cartId: product.product_id
      };
      this.$store.dispatch("addToCart", cartForm);
      this.alertMsg = product.product_name;
      this.addAlert = "show d-block";
    },
    paginationPro(page) {
      this.pagePar.page = page;
      this.$store
        .dispatch("offerProducts", this.pagePar)
        .then((response) => {
          this.products = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    paginationFilterPro(page) {
      this.pagePar.page = page;
      this.$store
        .dispatch("offerProducts", this.pagePar)
        .then((response) => {
          this.products = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getProducts() {
      // on page loading
      this.showLoading = true;

      this.pagePar.slug = this.$route.params.slug;
      this.pagePar.offer_id = this.$route.params.offer_id;
      this.pagePar.page = 1;
      this.siteTitle = this.$ucWords(this.$route.params.slug.replace(/-/g,' '));
      this.$store
        .dispatch("offerProducts", this.pagePar)
        .then((response) => {
          this.products = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          // off page loading
          this.showLoading = false;
        });
    },

    statusToggle() {
      this.statusShow = !this.statusShow;
    },

    modalClose() {
      this.addAlert = "";
    },
  },
  created() {
    this.getProducts();
  },
  mounted() {},
  watch: {
    $route() {
      if (this.$route.name == "OfferProducts") this.getProducts();
    },
    ["pagePar.order"]() {
      this.getProducts();
    },
    ["pagePar.limit"]() {
      this.getProducts();
    },
  },
};
</script>
<style scoped>
.feature {
  border-bottom: 2px solid #942a8f !important;
}
.thumble {
  height: 221px;
  width: 221px;
  text-align: center;
}
.pro-card-area {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
  padding: 0;
  justify-content: flex-start;
  background: none;
}

.pro-card {
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 5px 10px;
  margin-bottom: 0;
  display: flex;
  position: relative;
}
.pro-card-inner {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
}
.pro-card-inner:hover {
  box-shadow: 0px 0px 8px 1px gray;
}
.marks {
  display: flex;
  position: absolute;
  top: 5px;
  left: 0;
  z-index: 10;
  flex-direction: column;
  align-items: flex-start;
}
.mark {
  background: #ed283a;
  width: auto;
  color: #fff;
  font-size: 12px;
  padding: 3px 10px;
  line-height: 14px;
  margin-bottom: 2px;
  border-radius: 0 20px 20px 0;
  flex: 0 0 auto;
}

.pro-image {
  text-align: center;
  border-bottom: 3px solid rgba(55, 73, 187, 0.03);
  flex: 0 0 220px;
  padding: 20px;
  margin: 0;
}
.pro-image img {
  max-width: 100%;
}
.pro-details {
  padding: 10px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  border-bottom: 3px solid rgba(55, 73, 187, 0.03);
}
.price-card {
  font-size: 14px;
}
.pro-name {
  font-weight: 400;
  font-size: 15px;
}
.pro-price {
  line-height: 22px;
  font-size: 17px;
  font-weight: 600;
  color: #ef4a23;
}
.text-dark {
  color: black;
}
.buy-btn {
  padding: 10px;
}
.btn-primary {
  background: #f2f4f8;
  color: #932a8f;
  font-weight: bold;
  transition: 1s;
}
.btn-out {
  background: #d4d5d7;
  color: #932aaa;
  font-weight: bold;
}
.btn-primary:hover {
  background-color: #932a8f;
  box-shadow: 0px 3px 3px 1px #f2f4f8;
  color: #fffcfccc;
  transition: 1s;
}
.btn-secondary{
  background-color: #f2f4f86b;
  transition: 1s;
  font-weight: 500;
  border: 0;
  color: rgb(0, 0, 0);
}

.btn-secondary:hover {
  box-shadow: 0px 3px 3px 1px #f2f4f8;
  color: #932a8f;
  background-color: #cacdd5;
}
.gallary-img {
  height: 50px;
  width: 50px;
  border: 1px solid lightgray;
  padding: 5px;
  margin: 1px;
}
.price-bg {
  background: #f1f1f3d1;
}
.link-btn {
  background: #1934cfad;
  color: white;
}
.filter-border {
  border-bottom: 1px solid #80808057;
}
.link-active {
  background: #00008bc7;
  box-shadow: 0px 0px 4px 1px #1934cf;
}
.filter-btn,
.per-page,
.filter-sort {
  background: #e7e9ed;
  color: black;
  font-size: 14px;
  cursor: pointer;
}
.availability {
  height: 16px;
  width: 16px;
  position: relative;
  top: 3px;
}
.availability-grp:hover {
  background: #02189178;
  color: white;
}
.resp-filter-area {
  position: absolute;
  width: 280px;
  background: #f2f4f8;
  top: 0;
  left: 10px;
  box-shadow: 0 0 10px 5px grey;
  z-index: 999;
  padding: 10px;
  overflow-y: scroll;
  height: 100%;
}
.filter-area {
  display: block;
}
.filter-btn {
  display: none;
}
.page-btn {
  font-size: 11px;
}
@media (max-width: 1279px) {
  .pro-card {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media (max-width: 1200px) {
  .filter-area {
    display: none;
  }
  .filter-btn {
    display: block;
  }
}
@media (min-width: 992px) {
  .modal-dialog {
    width: 95%;
  }
}
@media (max-width: 767px) {
  .pro-card {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .modal-dialog {
    width: 95%;
  }
}

.min {
  width: 47%;
  float: left;
}

.max {
  width: 47%;
  float: right;
}
.track-container {
  width: 100%;
  position: relative;
  cursor: pointer;
  height: 0.5rem;
}

.track,
.track-highlight {
  display: block;
  position: absolute;
  width: 100%;
  height: 0.5rem;
}

.track {
  background-color: #cccfd4;
}

.track-highlight {
  background-color: #293a99b3;
  z-index: 2;
}

.track-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  display: block;
  position: absolute;
  z-index: 2;
  width: 1.2rem;
  height: 1.2rem;
  top: calc(-50% - 0.15rem);
  margin-left: -0.7rem;
  border: none;
  background-color: #293a99;
  border-radius: 50%;
  -ms-touch-action: pan-x;
  touch-action: pan-x;
  transition: box-shadow 0.3s ease-out, background-color 0.3s ease,
    -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out,
    background-color 0.3s ease;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out,
    background-color 0.3s ease, -webkit-transform 0.3s ease-out;
}
.min-resp,
.max-resp {
  width: 113px;
}
</style>
