<template>
    <div id="productImageGallery" :class="isEnableGalleryModal? 'productImageGallery':''" class="images product-images px-md-5 px-0">
        <div @click="closeGalleryModal" :class="isEnableGalleryModal? 'productImageGalleryClose':''" class="gallery-close">
            <font-awesome icon="close" class="close" />
        </div>
        <div id="galleryCaptions" class="carousel slide" data-bs-ride="carousel" data-bs-interval="false">
            <div class="carousel-indicators" id="imageGalleryIndicator">
                <img
                    type="button"
                    class="p-2"
                    v-for="(image, i) in productImages"
                    :key="image.offer_id"
                    data-bs-target="#galleryCaptions"
                    :data-bs-slide-to="i"
                    :class="[i == 0 ? 'active' : '']"
                    :aria-current="[i == 0 ? 'true' : '']"
                    aria-label="Slide 1"
                    :src="image.image"
                    style="height: 40px; width: 40px"
                >
            </div>
            <div class="carousel-inner py-3">
                <div
                    v-for="(image, i) in productImages"
                    :key="image.offer_id"
                    class="carousel-item"
                    :class="[i == 0 ? 'active' : '']">
                    <div class="w-100 d-block">
                        <img
                            type="button"
                            :src="image.image"
                            :alt="productName"
                            @click="enableGalleryModal"
                        />
                    </div>
                </div>
            </div>
            <div v-if="isEnableGalleryModal">
                <button
                    class="carousel-control-prev"
                    type="button"
                    data-bs-target="#galleryCaptions"
                    data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button
                    class="carousel-control-next"
                    type="button"
                    data-bs-target="#galleryCaptions"
                    data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "ProductImageGallery",
        props: ['productImages', 'productName'],
        data() {
            return {
                isEnableGalleryModal: false,
            }
        },

        methods: {
            enableGalleryModal() {
                this.isEnableGalleryModal = true;
                console.log(this.isEnableGalleryModal);
            },

            closeGalleryModal(){
                this.isEnableGalleryModal = false; 
            }
        }
    }
</script>
<style scoped>
    .productImageGallery{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background-color: gray;
        z-index: 100;
    }
    .productImageGallery .carousel-inner{
        height: 90vh;
    }
    .productImageGallery .carousel-inner img{
        width: 100vh;
    }
    .gallery-close{
        display: none;
    }
    .productImageGalleryClose{
        top: 10px;
        right: 10px;
        position: absolute;
        display: block;
        cursor: pointer;
    }
    .productImageGalleryClose .close{
        color: #dfd6d6;
        border: 1px solid;
        padding: 5px;
        border-radius: 50%;
        width: 25px;
        height: 25px;
    }
    .carousel-control-prev-icon, .carousel-control-next-icon {
        height: 20px;
    }
</style>