<template>
  <div class="register col-md-12 px-0" @keyup.enter="register">
    <section class="after-header p-tb-10 bg-white p-3">
      <div class="c-intro">
          <ul class="breadcrumb mb-0">
              <li>
                  <router-link to="/">
                  <i class="material-icons" title="Home">home</i>
                  </router-link>
              </li>
              <li>
                  <router-link to="register" class="text-capitalize">
                      <span>Account Register</span>
                  </router-link>
              </li>
          </ul>
      </div>
    </section>

    <form class="register-area d-block mx-auto border bg-white rounded my-5">
      <div class="p-3">
        <div class="form-group mt-3 row mx-0">
          <div class="col-6 px-0 pe-1">
            <label for="first_name" class="mb-0 text-secondary">First Name <small class="text-danger">*</small></label>
            <input
              type="text"
              v-model="registerForm.first_name"
              id="first_name"
              class="form-control px-1"
              placeholder="Enter first name"
            />
          </div>
          <div class="col-6 px-0 ps-1">
            <label for="last_name" class="mb-0 text-secondary">Last Name <small class="text-danger">*</small></label>
            <input
              type="text"
              v-model="registerForm.last_name"
              id="last_name"
              class="form-control px-1"
              placeholder="Enter last name"
            />
          </div>
        </div>
        <div class="form-group mt-3">
          <label for="email" class="text-secondary">E-mail <small class="text-danger">*</small></label>
          <input
            type="text"
            v-model="registerForm.email"
            id="email"
            class="form-control"
            placeholder="Enter email number"
          />
        </div>
        <div class="form-group mt-3">
          <label for="mobile" class="text-secondary">Mobile <small class="text-danger">*</small></label>
          <input
            type="text"
            v-model="registerForm.mobile"
            id="mobile"
            class="form-control ps-5"
            placeholder="Enter mobile number"
          />
          <span class="mobile">+88</span>
        </div>
        <div class="form-group mt-3">
          <label for="shipping_area_id" class="mb-0 text-secondary">Region/State <small class="text-danger">*</small></label>
          <select
            id="shipping_area_id"
            class="form-control form-select"
            v-model="registerForm.shipping_area_id"
          >
            <option value="">Select Region/State</option>
            <option
              v-for="shippingArea in shippingAreas"
              :key="shippingArea.shipping_area_id"
              :value="shippingArea.id"
            >
              {{ shippingArea.shipping_area }}
            </option>
          </select>
        </div>
        <div class="form-group mt-3">
          <label for="address" class="d-block text-secondary">Address <small class="text-danger">*</small></label>
          <input
            type="text"
            v-model="registerForm.address"
            class="form-control"
            placeholder="Enter address"
          />
        </div>
        <div class="form-group mt-3">
          <label for="post_code" class="d-block text-secondary">Post Code <small class="text-danger">*</small></label>
          <input
            type="text"
            v-model="registerForm.post_code"
            class="form-control"
            placeholder="Enter post code"
          />
        </div>
        <div class="form-group mt-3">
          <label for="password" class="d-block text-secondary">Password <small class="text-danger">*</small></label>
          <input
            :type="eye ? 'text' : 'password'"
            v-model="registerForm.password"
            class="form-control"
            placeholder="Enter password"
          />
          <font-awesome
            :icon="eye ? 'eye' : 'eye-slash'"
            class="pass"
            @click="eye = !eye"
          ></font-awesome>
        </div>
        <div class="form-group row">
          <div class="col-6 mt-3">
            <button type="reset" class="btn st-outline col-12">Clear</button>
          </div>
          <div class="col-6 mt-3">
            <button
              type="button"
              class="btn btn-primary col-12"
              @click="register"
            >
              Register
            </button>
          </div>
        </div>
        <div class="form-group mt-4 ">
          <p>
            Already have an account?
            <router-link to="/login" class="float-end">Login</router-link>
          </p>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "RegisterView",
  data() {
    return {
      shippingAreas: [],
      eye: false,
      registerForm: {
        first_name: "",
        last_name: "",
        mobile: "",
        email: "",
        address: "",
        password: "",
        post_code: "",
        shipping_area_id: "",
      },
    };
  },
  methods: {
    register() {
      var error = "";
      const mobExp = /^[0-9]*$/;
      const emailRegex = RegExp(
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      );
      if (!this.registerForm.first_name.length) {
        error += '<p class="text-danger mb-0">First name is required!</p>';
      }
      if (!this.registerForm.last_name.length) {
        error += '<p class="text-danger mb-0">Last name is required!</p>';
      }
      if (this.registerForm.email == "") {
        error += '<p class="text-danger mb-0">E-mail is required!</p>';
      } else if (!emailRegex.test(this.registerForm.email)) {
        error += '<p class="text-danger mb-0">E-mail is invalid!</p>';
      }
      if (this.registerForm.mobile == "") {
        error += '<p class="text-danger mb-0">Mobile number is required!</p>';
      } else if (mobExp.test(this.registerForm.mobile) == false) {
        error += '<p class="text-danger mb-0">Only number is allowed!</p>';
      } else if (
        this.registerForm.mobile.length < 11 ||
        this.registerForm.mobile.length > 11
      ) {
        error += '<p class="text-danger mb-0">Mobile number is invalid!</p>';
      }
      if (this.registerForm.shipping_area_id == "") {
        error += '<p class="text-danger mb-0">District is required!</p>';
      }
      if (!this.registerForm.address.length) {
        error += '<p class="text-danger mb-0">Address is required!</p>';
      }
      if (!this.registerForm.post_code.length) {
        error += '<p class="text-danger mb-0">Post code is required!</p>';
      }
      if (this.registerForm.password == "") {
        error += '<p class="text-danger mb-0">Password is required!';
      } else if (this.registerForm.password.length < 6) {
        error +=
          '<p class="text-danger mb-0">Enter at least 6 digits password!</p>';
      }
      if (error != "") {
        this.$swal({
          title: "REQUIRED!",
          icon: "error",
          html: error,
        });
      } else {
        this.$store
          .dispatch("Userregister", this.registerForm)
          .then((response) => {
            if (response.status == 201) {
              this.$router.push({
                name: 'verifyAccount',
                params: { token: btoa(response.data.data.token) }
              });
            } else {
              var error ='<p class="text-danger mb-0">' + response.data.data + "</p>";
              this.$swal({
                title: "Warning!",
                icon: "error",
                html: error,
              });
            }
          })
          .catch((error) => {
            var errorMsg ='<p class="text-danger mb-0">' + error.response.data.message + "</p>";
            this.$swal({
              title: "Warning!",
              icon: "error",
              html: errorMsg,
            });
          });
      }
    },
    getDistrict() {
      this.$store
        .dispatch("getShippingArea")
        .then((response) => {
          this.shippingAreas = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    this.getDistrict();
    let user = JSON.parse(localStorage.getItem('userInfo'));
    if (user) {
        this.$router.back();
    }
  },
};
</script>
