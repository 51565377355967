<template>
    <div class="product-area col-md-12 px-0 mb-5">
        <section class="after-header p-tb-10 bg-white p-3">
            <div class="c-intro">
                <ul class="breadcrumb mb-0">
                    <li>
                        <router-link to="/">
                        <i class="material-icons" title="Home">home</i>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="contact" class="text-capitalize">
                            <span>Contact</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </section>
        
        <section class="p-item-page bg-bt-gray p-tb-15 px-3">
            <div class="row bg-white sort-area rounded mx-1 py-2 mb-3">
                <div class="col-md-12 p-4">
                    <p><font-awesome icon="location-dot" /> Address: {{ setting.address }}</p>
                    <p><font-awesome icon="envelope" /> E-mail: {{ setting.email }}</p>
                    <div class="d-flex">
                        <div>
                            <font-awesome icon="phone" /> Phone:
                        </div>
                        <div class="ps-1">
                            <p v-for="phone in phones" :key="phone" class="m-0">{{ phone }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { useHead } from "@vueuse/head";
export default{
    name: 'ContactView',
    // meta tag setup start
    setup() {
        useHead({
            title: 'Contact | Sopma Tech',
        });
    },
    // meta tag setup end

    data(){
        return{
            setting: '',
            phones: [],
        }
    },

    methods: {
        // setting data fetch function
        getSettingData(){
            let setting = this.$store.getters.siteSetting;
            if(setting){
                this.setting = setting;
                this.phones = JSON.parse(setting.phone);
            }else{
                console.log('server');
                this.$store.dispatch("getSettingData").then((response) => {
                    this.setting = response.data;
                    this.phones = JSON.parse(response.data.phone);
                })
                .catch((error) => {
                    console.log(error);
                });
            }
        }
    },

      // 
    mounted() {
        // call getSettingData for get site setting data
        this.getSettingData();
    },
    
}
</script>