<template>
    <div class="drawer cmpr-panel px-0" :class="compareShow? 'open':''" id="cmpr-panel">
    <div class="title">
      <p>Compare Product</p>
      <span class="mc-toggler loaded close" @click="compareClose"><i class="material-icons">close</i></span>
    </div>
    <div class="content">
      <template v-if="compareProducts.length">
        <div class="item" v-for="comparepro in compareProducts" :key="comparepro.cartId">
          <div class="image">
            <img :src="comparepro.product.thumbnail" width="47" height="47">
          </div>
          <div class="info">
            <div class="name">{{ comparepro.product.product_name }}</div>
          </div>
          <div class="remove" @click="removeProduct(comparepro.product_id)" title="Remove"><i class="material-icons" aria-hidden="true">delete</i></div>
        </div>
      </template>
      <div v-else class="empty-content">
        <p class="text-center">Your compare list is empty!</p>
      </div>
    </div>
    <div class="footer btn-wrap" v-if="compareProducts.length">
        <a class="clear-all" @click="clearCompare" >Clear</a>
        <a class="btn" @click="compareNow" >Compare Now</a>
      </div>
  </div>
</template>
<script>

export default{
    name: 'compareView',
    props:['compareProducts', 'compareShow'],
    data(){
        return{
            
        }
    },
    methods:{
        compareClose(){
           this.$emit('closecompare');
        },
        removeProduct(product_id){
           this.$store.dispatch('removeCompareProduct',product_id);
           this.$emit('compareReload');
        }, 
        clearCompare(){
           this.$store.dispatch('clearCompare',);
           this.$emit('compareReload');
        },
        compareNow(){
            if (this.compareProducts.length >1) {
                this.$router.push('/compare-products');
                this.$emit('closecompare');
            }
        },
    }
}
</script>