<template>
    <div class="product-area col-md-12 px-0 mb-5">
        <section class="after-header p-tb-10 bg-white p-3">
            <div class="c-intro">
                <ul class="breadcrumb mb-0">
                    <li>
                        <router-link to="/">
                        <i class="material-icons" title="Home">home</i>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="online-delivery" class="text-capitalize">
                            <span>Online Delivery</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </section>
        <section class="p-item-page bg-bt-gray p-tb-15 px-3">
            <div class="row bg-white sort-area rounded mx-1 py-2 mb-3">
                <div class="col-md-12 p-4">
                    <div v-html="onlineDelivery"></div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { useHead } from "@vueuse/head";
export default{
    name: 'OnlineDelivery',
    // meta tag setup start
    setup() {
        useHead({
            title: 'Online Delivery | Sopma Tech',
        });
    },
    // meta tag setup end
    data(){
        return{
            onlineDelivery: '',
        }
    },

    methods: {
        // setting data fetch function
        getSettingData(){
            let setting = this.$store.getters.siteSetting;
            if(setting){
                this.onlineDelivery = setting.onlineDelivery;
            }else{
                console.log('server');
                this.$store.dispatch("getSettingData").then((response) => {
                    this.onlineDelivery = response.data.onlineDelivery;
                    // console.log(response);
                })
                .catch((error) => {
                    console.log(error);
                });
            }
        }
    },

      // 
    mounted() {
        // call getSettingData for get site setting data
        this.getSettingData();
    },
    
}
</script>