<template>
  <div class="col-md-12 mt-2 mb-5 px-0">
    <section class="after-header p-tb-10 bg-white p-3">
      <div class="c-intro">
          <ul class="breadcrumb mb-0">
              <li>
                <router-link to="/">
                  <i class="material-icons" title="Home">home</i>
                </router-link>
              </li>
              <li>
                <router-link 
                  :to="{
                    name: 'typeProducts',
                    params: {
                      typeSlug: typeSlug,
                    },
                  }" class="text-capitalize">
                  <span>{{ typeName }}</span>
                </router-link>
              </li>
              <li v-if="categorySlug">
                <router-link 
                  :to="{
                    name: 'categoryProducts',
                    params: {
                      typeSlug: typeSlug,
                      categorySlug: categorySlug,
                    },
                  }" class="text-capitalize">
                  <span>{{ categoryName }}</span>
                </router-link>
              </li>
              <li v-if="subcategorySlug">
                <router-link 
                  :to="{
                    name: 'subCategoryProducts',
                    params: {
                      typeSlug: typeSlug,
                      categorySlug: categorySlug,
                      subcategorySlug: subcategorySlug,
                    },
                  }" class="text-capitalize">
                  <span>{{ subcategoryName }}</span>
                </router-link>
              </li>
          </ul>
          <!-- <div class="child-list">
              <a href="pc">Desktop</a>    
          </div> -->
      </div>
  </section>

  <section class="p-item-page bg-bt-gray p-tb-15 px-3">
    <div class="row">
        <div id="column-left" class="col-sm-3" :class="filterModal? 'open':''">
            <span class="lc-close" @click="filterClose"><i class="material-icons" aria-hidden="true">close</i></span>
            <div class="filters">
                <div class="price-filter ws-box h-100">
                  <div class="label">
                    <span>Price Range</span>
                  </div>
                  <div class="price-range d-block float-start w-100 rounded bg-white px-2">
                    <div class="range d-block px-1">
                      <div class="track-container">
                        <div class="track" ref="_vpcTrack"></div>
                        <div class="track-highlight" ref="trackHighlight"></div>
                        <button class="track-btn track1" ref="track1"></button>
                        <button class="track-btn track2" ref="track2"></button>
                      </div>
                    </div>
                    <div class="d-block float-start mt-3">
                      <input
                        type="text"
                        class="text-center min"
                        name="minValue"
                        v-model="minValue"
                        @blur="priceFilter($event)"
                      />
                      <input
                        type="text"
                        class="text-center max"
                        name="maxValue"
                        v-model="maxValue"
                        @blur="priceFilter($event)"
                      />
                    </div>
                  </div>
                </div>
                <div class="filter-group ws-box" :class="statusShow? 'show':''">
                    <div class="label" @click="statusToggle()">
                      <span>Availability</span>
                    </div>
                    <div class="items">
                      <label class="filter">
                        <input type="checkbox" value="1" @click="stockStatusf($event)">
                        <span>In Stock</span>
                      </label>
                      <label class="filter">
                        <input type="checkbox" value="3" @click="stockStatusf($event)">
                        <span>Up Coming</span>
                      </label>
                    </div>
                </div>
                <div class="filter-group ws-box" :class="brandShow? 'show':''">
                    <div class="label" @click="brandToggle()">
                      <span>Brand</span>
                    </div>
                    <div class="items">
                      <label class="filter" v-for="brand in brands" :key="brand.brand_id">
                        <input type="checkbox" name="brand_id[]"
                          :id="'brandId' + brand.brand_id"
                          :value="brand.brand_id"
                          @click="brandClick($event)">
                        <span>{{ brand.brand_name }}</span>
                      </label>
                    </div>
                </div>
                <template v-for="(cat, si) in attributes" :key="si">
                  <div class="filter-group ws-box" :class="cat.show? 'show':''" >
                    <div class="label" @click="attributeToggle(si)">
                      <span>{{ cat.category }}</span>
                    </div>
                    <div class="items">
                      <label class="filter" v-for="variation in cat.attributes" :key="variation.variation_id">
                        <input type="checkbox" name="variation_id[]"
                          :id="'variationId' + variation.variation_id"
                          :value="variation.variation_id"
                          @click="attributeClick($event)">
                        <span>{{ variation.variation_name }}</span>
                      </label>
                    </div>
                  </div>
                </template>
            </div>  
        </div>
        <div id="content" class="col-xs-12 col-md-9 product-listing">
            <div class="top-bar ws-box">
                <div class="row">
                    <div class="col-sm-4 col-xs-2 actions">
                      <button class="tool-btn" id="lc-toggle" @click="filterClose"><i class="material-icons">filter_list</i> Filter</button>
                      <label class="page-heading m-hide text-capitalize">{{ subcategoryName? subcategoryName:(categoryName? categoryName:typeName) }}</label>
                    </div>
                    <div class="col-sm-8 col-xs-10 show-sort">
                        <div class="form-group rs-none">
                            <label>Show:</label>
                            <div class="custom-select">
                                <select id="input-limit" class="limit" v-model="requestData.limit">
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="40">40</option>
                                    <option value="50">50</option>
                                    <option value="80">80</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Sort By:</label>
                            <div class="custom-select">
                                <select id="input-sort" class="sort" v-model="requestData.order">
                                    <option value="">Default</option>
                                    <option value="asc">Price (Low &gt; High)</option>
                                    <option value="desc">Price (High &gt; Low)</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-content p-items-wrap" v-if="products.data">
              <div class="p-item" v-for="product in products.data" :key="product.product_id">
                <div class="p-item-inner">
                  <div class="marks" v-if="product.discount > 0" >
                    <span class="mark">Save: {{ product.discount.toLocaleString() }}৳</span>                                        
                  </div>
                  <div class="p-item-img">
                    <router-link 
                      :to="{
                        name: 'productDetails',
                        params: {
                          productSlug: product.slug,
                          typeSlug: typeSlug,
                          categorySlug: categorySlug,
                          subcategorySlug: subcategorySlug,
                        },
                      }">
                      <img :src="product.thumbnail" :alt="product.product_name" width="228" height="228">
                    </router-link>
                  </div>
                    <div class="p-item-details">
                        <h4 class="p-item-name"> 
                            <router-link 
                              :to="{
                                name: 'productDetails',
                                params: {
                                  productSlug: product.slug,
                                  typeSlug: typeSlug,
                                  categorySlug: categorySlug,
                                  subcategorySlug: subcategorySlug,
                                },
                              }">{{ product.product_name }}</router-link>
                        </h4>
                        <div class="short-description" v-html="product.key_features"></div>
                        <div class="p-item-price">
                            <span class="price-new">{{ product.sale_price.toLocaleString() }}৳</span> 
                            <small v-if="product.discount>0">
                              <del class="text-secondary ps-2">{{ product.original_price.toLocaleString() }}৳</del>
                            </small>                      
                        </div>
                        <div class="actions">
                            <span v-if="product.stock_status == 2" class="st-btn stock-status">Stock Out</span>
                            <span v-else-if="product.stock_status == 3" class="st-btn stock-status">Up Coming</span>
                            <template v-else>
                              <router-link
                                v-if="product.variation_type"
                                class="st-btn btn-add-cart" type="button"
                                :to="{
                                  name: 'productDetails',
                                  params: {
                                    productSlug: product.slug,
                                    typeSlug: typeSlug,
                                    categorySlug: categorySlug,
                                    subcategorySlug: subcategorySlug,
                                  },
                                }"
                              >
                                <i class="material-icons">shopping_cart</i> Buy Now
                              </router-link>
                              <span v-else class="st-btn btn-add-cart" type="button" @click="addToCart(product)">
                                <i class="material-icons">shopping_cart</i> Buy Now
                              </span>
                            </template>
                            <span class="st-btn btn-compare" @click="addtoComparef(product)">
                              <i class="material-icons">library_add</i>Add to Compare
                            </span>
                        </div>
                    </div>
                </div>
              </div>
            </div>
            <div class="d-block" v-else>
              <h4 class="text-center py-5">Product Not Found!</h4>
            </div>
            <div class="bottom-bar">
              <div class="row px-3" v-if="products.total">
                <div class="col-md-12 bg-white rounded p-2 shadow">
                  <div v-if="products.last_page > 1" class="d-inline-block">
                    <ul class="pagination">
                      <li v-if="products.current_page == 1">
                        <span class="disabled">PREV</span>
                      </li>
                      <li v-else>
                        <a type="button" @click="paginationPro(products.current_page - 1)">PREV</a>
                      </li>
                      <template v-for="page in products.last_page" :key="page">
                        <template v-if="page > products.current_page - 4 && page < products.current_page + 4">
                          <li v-if="page == products.current_page" class="active">
                            <span>{{ page }}</span>
                          </li>
                          <li v-else>
                            <a @click="paginationPro(page)" type="button">{{ page }}</a>
                          </li>
                        </template>
                      </template>
                      <li v-if="products.current_page == products.last_page">
                        <span class="disabled">NEXT</span>
                      </li>
                      <li v-else>
                        <a type="button" @click="paginationPro(products.current_page + 1)">NEXT</a>
                      </li>
                    </ul>
                  </div>
                  <div class="page-number d-inline-block float-end">
                    <p class="mt-1 mb-0">
                      <small class="fw-bold page-btn"
                        >Showing {{ products.from }} to {{ products.to }} of
                        {{ products.total }} ({{ products.last_page }}
                        {{ products.last_page > 1 ? "Pages" : "Page" }})</small
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div> 
        </div>
    </div>
  </section>

    <!-- alert modal  -->
    <ProductAddAlert
      :modalAlert="addAlert"
      :alertMsg="alertMsg"
      @modalClose="modalClose"
    />

    <!-- loading view -->
    <LoadingView v-if="showLoading"/>

  </div>
</template>
<script>
import ProductAddAlert from "@/components/ProductAddAlert.vue";
import { reactive, watch, computed, ref } from 'vue';
import { useHead } from "@vueuse/head";
import LoadingView from '@/components/Layouts/LoadingView.vue';

export default {
  name: "CategoryProductView",
  props: ["filterModal"],
  components: {
    ProductAddAlert, LoadingView
  },

  // meta tag setup start
  setup() {
    // Define reactive properties within the setup function
    const siteTitle = ref('siteTitle');
    const siteData = reactive({
      title: siteTitle.value +' Price in Bangladesh | Sopma Tech',
      description: "Sopma Tech is the Best Laptop, Computer, Gaming PC, Component, Accessories, and Gadget retail &amp; Online shop in Bangladesh. Sopma Tech offers the Latest Tech products with the most competitive price in BD.",
      keywords: "Laptop shop in Bangladesh, Laptop shop in bd, computer shop in Bangladesh, PC shop in Bangladesh, computer shop in BD, Gaming PC shop in Bangladesh, PC accessories shop in Bangladesh, best computer shop in Bangladesh, Gadget shop in bd, Gadget Shop in Bangladesh, Online Shop in BD, online computer shop in bd, computer accessories online shop in Bangladesh, computer parts shop in bd, Laptop in Bangladesh, Notebook, Laptop, Desktop, Brand PC, computer, computer store Bangladesh, laptop store Bangladesh, gaming, desktop, monitor, Sopma Tech, computer accessories, Desktop accessories, Laptop accessories, Laptop Online Store in BD, adata, apacer, apple, asus, bangladesh, baseus, belkin, benq, best, boya, brother, cable, camera, canon, GPU, graphics card, Sopma Tech &amp; Engineering Ltd, SOPMATECH, laptop price, laptop price in bangladesh, laptop price bd,",
    });

    // Watch for changes in siteTitle and update siteData.title accordingly
    watch(siteTitle, (newSiteTitle) => {
      siteData.title = newSiteTitle +' Price in Bangladesh | Sopma Tech'
    });

    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          name: 'description',
          content: computed(() => siteData.description),
        },
        {
          name: 'keywords',
          content: computed(() => siteData.keywords),
        },
      ],
    });

    // Access siteTitle directly in setup
    return {
      siteTitle,
    };
  },
  // meta tag setup end

  data() {
    return {
      showLoading: true,
      addAlert: "",
      alertMsg: "",
      typeName: "",
      typeSlug: this.$route.params.typeSlug,
      categorySlug: "",
      categoryName: "",
      subcategoryName: "",
      subcategorySlug: "",
      products: [],
      attributeShow: [],
      brandShow: true,
      statusShow: true,
      brands: [],
      attributes: [],
      min: 0,
      max: 1000000,
      minValue: 0,
      maxValue: 1000000,
      step: 5,
      totalSteps: 0,
      percentPerStep: 1,
      trackWidth: null,
      isDragging: false,
      pos: {
        curTrack: null,
      },
      requestData: {
        price: [0, 1000000],
        limit: 20,
        order: "",
        stock_status: [],
        brand_id: [],
        variation_id: [],
        slug: "",
        page: 1,
      },
    };
  },
  methods: {
    // product add to compare function
    addtoComparef(product) {
      this.$store.dispatch("addtoCompare", product);
    },

    // product add to cart function
    addToCart(product) {
      let cartForm = {
        qty: 1,
        product: product,
        cartId: product.product_id,
      };
      this.$store.dispatch("addToCart", cartForm);
      this.alertMsg = product.product_name;
      this.addAlert = "show d-block";
    },

    paginationPro(page) {
      this.requestData.page = page;
      this.$store
        .dispatch("categoryProducts", this.requestData)
        .then((response) => {
          this.products = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.products = [];
        });
    },

    // get category product function
    getProducts() {
      this.showLoading = true;
      this.requestData.page = 1;
      this.$store
        .dispatch("categoryProducts", this.requestData)
        .then((response) => {
          this.products = response.data.data;
          this.typeName = this.$route.params.typeSlug.replace(/-/g, " ");
        })
        .catch((error) => {
          console.log(error);
          this.products = [];
        })
        .finally(() => {
          // off page loading
          this.showLoading = false;
        });
    },

    // get category products attributes and brands
    getBrandsAttributes() {
      this.$store
        .dispatch("categoryProductAttributeBrand", this.$route.params.typeSlug)
        .then((response) => {
          this.brands = response.data.data.brands;
          this.attributes = response.data.data.attributes;
          this.max = response.data.data.max_price;
          this.maxValue = response.data.data.max_price;
          this.min = response.data.data.min_price;
          this.minValue = response.data.data.min_price;
          this.requestData.price = [this.minValue, this.maxValue]
          // console.log(this.requestData.price);
        })
        .catch((error) => {
          console.log(error);
          this.brands = [];
          this.attributes = [];
        });
    },

    modalClose() {
      this.addAlert = "";
    },
    // responsive design filter close function
    filterClose() {
      this.$emit("filter");
    },

    moveTrack(track, ev) {
      let percentInPx = this.getPercentInPx();

      let trackX = Math.round(
        this.$refs._vpcTrack.getBoundingClientRect().left
      );
      let clientX = ev.clientX;
      let moveDiff = clientX - trackX;

      let moveInPct = moveDiff / percentInPx;
      // console.log(moveInPct)

      if (moveInPct < 1 || moveInPct > 100) return;
      let value =
        Math.round(moveInPct / this.percentPerStep) * this.step + this.min;
      if (track === "track1") {
        if (value >= this.maxValue - this.step) return;
        this.minValue = value;
        this.requestData.price = [value, this.maxValue];
      }

      if (track === "track2") {
        if (value <= this.minValue + this.step) return;
        this.maxValue = value;
        this.requestData.price = [this.minValue, value];
      }

      this.$refs[track].style.left = moveInPct + "%";
      this.setTrackHightlight();
    },
    mousedown(ev, track) {
      if (this.isDragging) return;
      this.isDragging = true;
      this.pos.curTrack = track;
      console.log(ev);
    },

    touchstart(ev, track) {
      this.mousedown(ev, track);
    },

    mouseup(ev, track) {
      if (!this.isDragging) return;
      this.isDragging = false;
      console.log(ev);
      console.log(track);
      this.getProducts();
    },

    touchend(ev, track) {
      // console.log('touch end');
      this.mouseup(ev, track);
    },

    mousemove(ev, track) {
      if (!this.isDragging) return;
      this.moveTrack(track, ev);
    },

    touchmove(ev, track) {
      this.mousemove(ev.changedTouches[0], track);
    },

    valueToPercent(value) {
      return ((value - this.min) / this.step) * this.percentPerStep;
    },

    setTrackHightlight() {
      this.$refs.trackHighlight.style.left =
        this.valueToPercent(this.minValue) + "%";
      this.$refs.trackHighlight.style.width =
        this.valueToPercent(this.maxValue) -
        this.valueToPercent(this.minValue) +
        "%";
    },

    getPercentInPx() {
      let trackWidth = this.$refs._vpcTrack.offsetWidth;
      let oneStepInPx = trackWidth / this.totalSteps;
      // 1 percent in px
      let percentInPx = oneStepInPx / this.percentPerStep;

      return percentInPx;
    },

    setClickMove(ev) {
      let track1Left = this.$refs.track1.getBoundingClientRect().left;
      let track2Left = this.$refs.track2.getBoundingClientRect().left;
      // console.log('track1Left', track1Left)
      if (ev.clientX < track1Left) {
        this.moveTrack("track1", ev);
      } else if (ev.clientX - track1Left < track2Left - ev.clientX) {
        this.moveTrack("track1", ev);
      } else {
        this.moveTrack("track2", ev);
      }
      this.getProducts();
    },

    // filter brand selection on click function
    brandClick(event) {
      if (event.target.checked) {
        this.requestData.brand_id.push(event.target.value);
      } else {
        this.requestData.brand_id = this.requestData.brand_id.filter(
          (brand) => {
            return brand !== event.target.value;
          }
        );
      }
      this.getProducts();
    },

    // filter product attribute selection function
    attributeClick(event) {
      if (event.target.checked) {
        this.requestData.variation_id.push(event.target.value);
      } else {
        this.requestData.variation_id = this.requestData.variation_id.filter(
          (variation) => {
            return variation !== event.target.value;
          }
        );
      }
      this.getProducts();
    },

    // filter product stock status selection function
    stockStatusf(event) {
      if (event.target.checked) {
        this.requestData.stock_status.push(event.target.value);
      } else {
        this.requestData.stock_status = this.requestData.stock_status.filter(
          (statuss) => {
            return statuss !== event.target.value;
          }
        );
      }
      this.getProducts();
    },

    // price filter function
    priceFilter(event) {
      let name = event.target.name;
      let price = event.target.value;
      const numberPattern = /^\d+$/;
      if(name == 'maxValue'){
        if(numberPattern.test(price)){
          if(parseInt(price)>this.max){
            this.maxValue = this.max;
          }else if(parseInt(price)<this.minValue){
            this.maxValue = this.minValue;
          }else if(parseInt(price)<this.min){
            this.maxValue = this.min;
          }
        }else{
          this.maxValue = this.max;
        }
      }else{
        if(numberPattern.test(price)){
          if(parseInt(price)<this.min){
            this.minValue = this.min;
          }else if(parseInt(price)>this.maxValue){
            this.minValue = this.maxValue;
          }else if(parseInt(price)>this.max){
            this.minValue = this.max;
          }
        }else{
          this.minValue = this.min;
        }
      }
      this.requestData.price = [this.minValue, this.maxValue]
      this.setPriceRange();
      this.getProducts();
    },

    // product attribute show close function
    attributeToggle(si) {
      this.attributes[si].show = !this.attributes[si].show;
    },

    // product brand show close function
    brandToggle() {
      this.brandShow = !this.brandShow;
    },

    // product stock status show close function
    statusToggle() {
      this.statusShow = !this.statusShow;
    },

    // set default data
    dataSet() {
      this.requestData.price = [0, 1000000];
      this.requestData.limit = 20;
      this.requestData.order = "";
      this.requestData.stock_status = [];
      this.requestData.brand_id = [];
      this.requestData.variation_id = [];
      this.requestData.slug = "";
      this.requestData.page = 1;

      this.typeSlug = this.$route.params.typeSlug;
      this.typeName = this.typeSlug ? this.typeSlug.replace(/-/g, " ") : "";
      this.categorySlug = this.$route.params.categorySlug ?? "";
      this.categoryName = this.categorySlug.replace(/-/g, " ");
      this.subcategorySlug = this.$route.params.subcategorySlug ?? "";
      this.subcategoryName = this.subcategorySlug.replace(/-/g, " ");
      this.requestData.slug =
        this.subcategorySlug != ""
          ? this.subcategorySlug
          : this.categorySlug != ""
          ? this.categorySlug
          : this.typeSlug;

      this.siteTitle = this.$ucWords((this.subcategoryName? this.subcategoryName+' ':'') + (this.categoryName? this.categoryName+' ':'')+this.typeName);
    },

    // price range set
    setPriceRange(){
      // this.getFilters();
      // calc per step value
      this.totalSteps = (this.max - this.min) / this.step;

      // percent the track button to be moved on each step
      this.percentPerStep = 100 / this.totalSteps;
      // console.log('percentPerStep', this.percentPerStep)

      // set track1 initilal
      document.querySelector(".track1").style.left =
        this.valueToPercent(this.minValue) + "%";
      // track2 initial position
      document.querySelector(".track2").style.left =
        this.valueToPercent(this.maxValue) + "%";
      // set initila track highlight
      this.setTrackHightlight();

      var self = this;

      ["mouseup", "mousemove"].forEach((type) => {
        document.body.addEventListener(type, (ev) => {
          // ev.preventDefault();
          if (self.isDragging && self.pos.curTrack) {
            self[type](ev, self.pos.curTrack);
          }
        });
      });

      [
        "mousedown",
        "mouseup",
        "mousemove",
        "touchstart",
        "touchmove",
        "touchend",
      ].forEach((type) => {
        document.querySelector(".track1").addEventListener(type, (ev) => {
          ev.stopPropagation();
          self[type](ev, "track1");
        });
        document.querySelector(".track2").addEventListener(type, (ev) => {
          ev.stopPropagation();
          self[type](ev, "track2");
        });
      });

      // on track clik
      // determine direction based on click proximity
      // determine percent to move based on track.clientX - click.clientX
      document.querySelector(".track").addEventListener("click", function (ev) {
        ev.stopPropagation();
        self.setClickMove(ev);
      });

      document
        .querySelector(".track-highlight")
        .addEventListener("click", function (ev) {
          ev.stopPropagation();
          self.setClickMove(ev);
      });
    }
  },
  created() {
    // console.log("created");
  },
  mounted() {
    // document.onreadystatechange = () => {
    //     if (document.readyState == "complete") {
    //         this.loading = true;
    //         console.log('sdfsd');
    //     }
    // };
    // call product functon
    this.dataSet();
    this.getProducts();

    this.getBrandsAttributes();

    setTimeout(()=>{
      this.setPriceRange();
    },3000);
  },
  watch: {
    $route() {
      if (
        this.$route.name == "typeProducts" ||
        this.$route.name == "categoryProducts" ||
        this.$route.name == "subCategoryProducts"
      ) {
        this.dataSet();
        this.getProducts();
        this.getBrandsAttributes();
        let allSelected = this.$el.querySelectorAll(".availability-check");
        allSelected.forEach((singleSL) => {
          singleSL.checked = false;
        });
        this.$el.querySelector(".limit").value = 20;
        this.$el.querySelector(".sort").value = "";

        setTimeout(()=>{
          this.setPriceRange();
        },3000);
      }
    },
    // // if change order then will be call getproducts function
    ["requestData.order"]() {
      this.getProducts();
    },
    // if change limit then will be call getproducts function
    ["requestData.limit"]() {
      this.getProducts();
    },
  },
};
</script>
