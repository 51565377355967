<template>
  <div id="topHeader" class="col-md-12 px-0">
    <header>
      <div class="top px-4">
        <div class="ht-item logo">
            <div class="mbl-nav-top h-desk" >
                <div @click="menuf()" id="nav-toggler" :class="menuShow? 'close':''">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
            <router-link class="brand" to="/">
              <img v-if="logo" :src="logo" title="Star Tech Ltd " width="144" height="164" alt="Star Tech Ltd ">
              <img v-else src="./../../assets/logo.jpeg" title="Star Tech Ltd " width="144" height="164" alt="Star Tech Ltd ">
            </router-link>
            <div class="mbl-right h-desk">
                <div class="ac search-toggler" @click="searchInShowf()" ><i class="material-icons">search</i></div>
                <div class="ac mc-toggler" @click="cartOpen"><i class="material-icons">shopping_basket</i><span class="counter" data-count="0">{{ cartProducts.length? cartProducts.length:0 }}</span></div>
            </div>
        </div>
        <div class="ht-item search " :class="search? 'open':(searchTxt.length? 'open':'')"  id="search">
            <input type="text" autocomplete="off" 
              v-model="searchTxt"
              class="form-control"
              placeholder="Search product ..."
              @keyup.enter="SearchEnter" />
            <router-link :to="searchTxt? '/product-search/' + searchTxt:'#'" class="material-icons search-btn">search</router-link>
            <!-- <div class="dropdown-menu" style="top: 47px; left: 0px; display: block;"> -->
            <div class="dropdown-menu" :class="searchProducts.length > 0 ? 'd-block' : (searchTxt.length? 'd-block':'d-none')" >
              <div class="search-details">
                  <!-- <ul class="nav nav-tabs">
                    <li data-tab="tab-prod" class="active">Products</li>
                    <li data-tab="tab-cat" class="">Categories</li>
                  </ul> -->
                  <div id="tab-prod" class="search-results" style="display: block;">
                    <template v-if="searchProducts.length > 0">
                      <div class="search-item" 
                        v-for="product in searchProducts.slice(0, 15)"
                        :key="product.product_id" >
                        <router-link 
                            :to="{
                            name: 'productDetails',
                            params: {
                              productSlug: product.slug,
                            },
                          }">
                          <div class="image">
                            <img 
                            :src="product.thumbnail"
                            :alt="product.product_name">
                          </div>
                          <div class="name">{{ searchProducts.length }} {{ product.product_name.slice(0, 55) }}
                          {{ product.product_name.length > 55 ? "..." : "" }}</div>
                          <div class="price">
                            <span>{{ product.sale_price.toLocaleString() }}৳</span>
                            <small class="ps-3" v-if="product.discount > 0">
                              <del class="text-secondary">
                                {{ (product.original_price - product.sale_price).toLocaleString() }}৳
                              </del>
                            </small>
                          </div>
                        </router-link>
                      </div>
                    </template>
                    <div v-else class="search-item empty">No results found for query</div>
                    <div class="search-item remainder-count" v-if="searchProducts.length > 15">
                      <router-link :to="'/product-search/' + searchTxt">See all results</router-link>
                    </div>
                  </div>
              </div>
            </div>
        </div>
        <div class="ht-item q-actions">
            <router-link to="/offers" class="ac h-offer-icon">
                <div class="ic"><i class="material-icons">card_giftcard</i></div>
                <div class="ac-content">
                    <h5>Offers</h5>
                    <p>Latest Offers</p>
                </div>
            </router-link>
            <!-- <a href="https://www.startech.com.bd/smart-watch-fest " class="ac h-offer-icon">
                <div class="ic"><i class="material-icons blink">flash_on</i></div>
                <div class="ac-content">
                    <h5>Watch Deal</h5>
                    <p>Special Deals</p>
                </div>
            </a> -->
            <router-link
              to="/pc-builder" class="ac h-desk build-pc">
                <div class="ic"><i class="material-icons">important_devices</i></div>
                <div class="ac-content">
                    <h5 class="text">PC Builder</h5>
                </div>
            </router-link>
            <div class="ac cmpr-toggler h-desk" @click="compareOpen">
                <div class="ic"><i class="material-icons">library_add</i></div>
                <div class="ac-content">
                    <h5 class="text">Compare ({{ compareProducts.length? compareProducts.length:0 }})</h5>
                </div>
            </div>
            <div class="ac">
                <router-link to="/account" class="ic"><i class="material-icons">person</i></router-link>
                <div class="ac-content">
                  <router-link
                    to="/login"><h5>Account</h5>
                  </router-link>
                  <p v-if="userInfo">
                    <router-link to="/account">Profile</router-link>
                    or
                    <a type="button" href="#" @click="logout">Logout</a>
                  </p>
                  <p v-else>
                    <router-link to="/register">Register</router-link>
                    or
                    <router-link to="/login">Login</router-link>
                  </p>
                </div>                
            </div>
            <div class="ac build-pc m-hide">
                <router-link
              to="/pc-builder" class="btn">PC Builder</router-link>
            </div>
        </div>
      </div>
      <nav class="navbar" :class="menuShow? 'open':''" id="main-nav">
        <ul class="navbar-nav px-0 px-xl-4">
            <li v-for="typeres in productCategories" :key="typeres.type_id" class="nav-item c-1" :class="typeres.categories.length? 'has-child':''">
                <router-link 
                  :to="{
                    name: 'typeProducts',
                    params: { typeSlug: typeres.slug },
                  }" class="nav-link">{{ typeres.category_name }}</router-link>
                <ul v-if="typeres.categories.length" class="drop-down drop-menu-1">
                  <li v-for="category in typeres.categories" :key="category.id" class="nav-item " :class="category.sub_categories.length? 'has-child':''">
                    <router-link 
                      :to="{
                        name: 'categoryProducts',
                        params: {
                          typeSlug: typeres.slug,
                          categorySlug: category.slug,
                        },
                      }" class="nav-link" >{{ category.category_name }}</router-link>
                    <ul v-if="category.sub_categories.length" class="drop-down drop-menu-2">
                      <li v-for="sub_category in category.sub_categories"
                      :key="sub_category.sub_category_id" class="nav-item">
                        <router-link 
                          :to="{
                            name: 'subCategoryProducts',
                            params: {
                              typeSlug: typeres.slug,
                              categorySlug: category.slug,
                              subcategorySlug: sub_category.slug,
                            },
                          }" class="nav-link" >{{ sub_category.category_name }}</router-link>
                      </li>
                    </ul>
                  </li>
                </ul>
            </li>
        </ul>
      </nav>
    </header>


    <div class="f-btn mc-toggler" id="cart" @click="cartOpen">
      <i class="material-icons">shopping_basket</i>
      <div class="label">Cart</div>
      <span class="counter">{{ cartProducts.length? cartProducts.length:0 }}</span>
    </div>
    <div class="f-btn cmpr-toggler" id="cmpr-btn" @click="compareOpen">
      <i class="material-icons">library_add</i>
      <div class="label">Compare</div>
      <span class="counter">{{ compareProducts.length? compareProducts.length:0 }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopHeader",
  props: ["cartProducts", "compareProducts", "userInfo", "menuShow", "productCategories", "logo"],
  data() {
    return {
      search: false,
      searchTxt: "",
      windowHeight: window.innerHeight - 60,
      // menuShow: false,
      // menuOpen: true,
      // menuClose: false,
      // productCategories: [],
      searchProducts: [],
      menuPosition: 'inherit',
    };

    // console.log(this.windowHeight);
  },
  methods: {
    searchInShowf() {
      this.search = !this.search;
      this.searchTxt = "";
    },
    menuf() {
      this.$emit("ToggleMenu");
      // this.menuShow = !this.menuShow;
      // this.menuOpen = !this.menuOpen;
      // this.menuClose = !this.menuClose;
    },
    cartOpen() {
      this.$emit("OpenCart");
    },
    compareOpen() {
      this.$emit("OpenCompare");
    },
    logout() {
      this.$emit("logout");
    },
    searchClose() {
      this.searchProducts = [];
      this.searchTxt = '';
    },
    SearchEnter() {
      if (this.searchTxt.length) {
        this.searchProducts = [];
        this.$router.push("/product-search/" + this.searchTxt);
      }
    },

    // setting data fetch function
    getSettingData(){
      // let setting = this.$store.getters.siteSetting;
      // if(setting){
      //   this.logo = setting.logo;
      // }else{
      //   this.$store.dispatch("getSettingData").then((response) => {
      //     this.logo = response.data.logo;
      //     // console.log(response.data);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
      // }
    },

    // scroll event for header and footer fixed
    handleScroll () {
      // Any code to be executed when the window is scrolled
      if(window. scrollY > 80){
        // console.log(window.scrollY);
        this.menuPosition = 'fixed';
      }else{
        this.menuPosition = 'inherit';
      }
    }
  },
  created() {
    // this.$store
    //   .dispatch("productCategory")
    //   .then((response) => {
    //     this.productCategories = response.data.data;
    //     // console.log(this.productCategories);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    window.addEventListener('scroll', this.handleScroll);
  },

  // 
  mounted() {
    // call getSettingData for get site setting data
    // this.getSettingData();
  },

  unmounted() {
    window.addEventListener('scroll', this.handleScroll);
  },

  watch: {
    searchTxt(txt) {
      if (txt.length) {
        let requestData = {
          page: 1,
          limit: 20,
          searchText: txt,
          order: '',
        };
        this.$store
          .dispatch("SearchProduct", requestData)
          .then((response) => {
            this.searchProducts = response.data.data;
            // console.log(this.searchProducts)
          })
          .catch((error) => {
            console.log(error);
            this.searchProducts = [];
          });
      } else {
        this.searchProducts = [];
      }
    },
    $route(){
      this.searchTxt = '';
      this.searchProducts = [];
    },
  },
};
</script>