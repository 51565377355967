<template>
  <div id="slider" class="col-lg-9 col-md-12 mt-3">
    <div
      id="carouselExampleCaptions"
      class="carousel slide"
      data-bs-ride="carousel"
    >
      <div class="carousel-indicators">
        <button
          type="button"
          v-for="(slider, i) in sliders"
          :key="slider.offer_id"
          data-bs-target="#carouselExampleCaptions"
          :data-bs-slide-to="i"
          :class="[i == 0 ? 'active' : '']"
          :aria-current="[i == 0 ? 'true' : '']"
          aria-label="Slide 1"
        ></button>
      </div>
      <div class="carousel-inner">
        <div
          v-for="(slider, i) in sliders"
          :key="slider.offer_id"
          class="carousel-item"
          :class="[i == 0 ? 'active' : '']"
        >
          <router-link
            :to="'/offer-info/' + slider.slug + '/' + slider.offer_id"
          >
            <img
              :src="slider.image_url"
              class="d-block img-fluid slider-img"
              alt="..."
              style="max-height: 500px; width: 100%"
            />
          </router-link>
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SliderView",
  props: ['sliders'],
  data() {
    return {
      // sliders: [],
    };
  },

  methods: {},

  created() {
    // this.$store
    //   .dispatch("offerSlider")
    //   .then((response) => {
    //     this.sliders = response.data.data;
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   })
    //   .finally(() => {
    //     // off page loading
    //     this.showLoading = false;
    //   });
  },

  mounted() {
    // this.sliders = this.$store.getters.sliders;
  },
};
</script>

<style scoped>
.carousel-control-prev,
.carousel-control-next {
  top: 45%;
  background: #850980;
  width: 40px;
  height: 40px;
  margin: 5px;
}
.slider-image {
  max-height: 400px;
}
</style>