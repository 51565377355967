<template>
  <div class="content-area px-0">
      <div class="row mx-3">
        <SliderView :sliders="sliders"/>
        <div class="col-lg-3 col-md-12">
          <HotProducts :hotProducts="hotProducts" />
        </div>
      </div>
    <ContentView :products="featureProducts"/>
    <BrandView :brands="brands"/>

    <!-- loading view -->
    <LoadingView v-if="isLoading"/>

  </div>
</template>

<script>
  import LoadingView from '@/components/Layouts/LoadingView.vue';
  import SliderView from '@/components/Layouts/SliderView.vue';
  import ContentView from '@/components/Layouts/ContentView.vue';
  import BrandView from '@/components/Layouts/BrandView.vue';
  import HotProducts from '@/components/Layouts/HotProducts.vue';
  import { reactive, computed } from 'vue';
  import { useHead } from "@vueuse/head";

  export default {
    props: ['sliders', 'brands', 'featureProducts', 'hotProducts', 'isLoading'],
    name: 'HomeView',
    components: {
      SliderView, ContentView, BrandView, LoadingView, HotProducts
    },

    // meta tag setup start
    setup() {
      // Define reactive properties within the setup function
      const siteData = reactive({
        title: 'Sopma Tech',
        description: "Sopma Tech is the Best Laptop, Computer, Gaming PC, Component, Accessories, and Gadget retail &amp; Online shop in Bangladesh. Sopma Tech offers the Latest Tech products with the most competitive price in BD.",
        keywords: "Laptop shop in Bangladesh, Laptop shop in bd, computer shop in Bangladesh, PC shop in Bangladesh, computer shop in BD, Gaming PC shop in Bangladesh, PC accessories shop in Bangladesh, best computer shop in Bangladesh, Gadget shop in bd, Gadget Shop in Bangladesh, Online Shop in BD, online computer shop in bd, computer accessories online shop in Bangladesh, computer parts shop in bd, Laptop in Bangladesh, Notebook, Laptop, Desktop, Brand PC, computer, computer store Bangladesh, laptop store Bangladesh, gaming, desktop, monitor, Sopma Tech, computer accessories, Desktop accessories, Laptop accessories, Laptop Online Store in BD, adata, apacer, apple, asus, bangladesh, baseus, belkin, benq, best, boya, brother, cable, camera, canon, GPU, graphics card, Sopma Tech &amp; Engineering Ltd, SOPMATECH, laptop price, laptop price in bangladesh, laptop price bd,",
      });

      useHead({
        title: computed(() => siteData.title),
        meta: [
          {
            name: 'description',
            content: computed(() => siteData.description),
          },
          {
            name: 'keywords',
            content: computed(() => siteData.keywords),
          },
        ],
      });
    },
    // meta tag setup end
  }
</script>
