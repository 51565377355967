<template>
  <div class="col-md-12 px-4 pt-5">
    <h2 class="m-header">Featured Products</h2>   
    <p class="m-blurb">Upgrade your life effortlessly with our Featured Products</p> 
    <div id="content" class="product-listing"> 
      <div class="main-content p-items-wrap">
        <div class="p-item" v-for="product in products" :key="product.product_id">
          <div class="p-item-inner">
            <div class="marks" v-if="product.discount > 0">
              <span class="mark">Save: {{ product.discount.toLocaleString() }}৳</span>                        
              </div>
              <div class="p-item-img">
              <router-link
                :to="{
                  name: 'productDetails',
                  params: {
                    productSlug: product.slug,
                  },
                }">
                <img :src="product.thumbnail" :alt="product.product_name" width="228" height="228">
              </router-link>
            </div>
            <div class="p-item-details">
              <h4 class="p-item-name pb-4" style="font-weight: 400; font-size: 15px;"> 
                <router-link
                  :to="{
                    name: 'productDetails',
                    params: {
                      productSlug: product.slug,
                    },
                  }">{{ product.product_name }}</router-link>
              </h4>
              <div class="p-item-price text-center position-absolute bottom-0 pb-3">
                <span>{{ product.sale_price.toLocaleString() }}৳</span>                      
                <small v-if="product.discount>0"><del class="text-secondary ps-2">{{ product.original_price.toLocaleString() }}৳</del></small>                      
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
</template>
<script>
export default {
  name: "ContentView",
  props: ["products"],
  data() {
    return {
      buyModalShow: "",
      product_detail: "",
      sizes: [],
      cartForm: {
        variation_type_id: "",
        variation_id: "",
        qty: 1,
        product: "",
      },
    };
  },
  methods: {
    // buynow method
    buyNowf(product_id) {
      this.buyModalShow = "show d-block";
      this.$store
        .dispatch("productDetailf", product_id)
        .then((response) => {
          this.product_detail = response.data;
          if (this.product_detail.attr_type == "color_size") {
            var colors = this.product_detail.color_size_variation[0];
            var colordata = {
              product_id: this.product_detail.product_id,
              variation_type_id: colors.variation_type_id,
            };
            this.$store
              .dispatch("colorSizeVariations", colordata)
              .then((responses) => {
                this.sizes = responses.data;
                this.cartForm.variation_id = responses.data[0].variation_id;
              });
            this.cartForm.variation_type_id = colors.variation_type_id;
          } else if (this.product_detail.attr_type == "color") {
            var color = this.product_detail.color_variation[0];
            this.cartForm.variation_type_id = color.variation_type_id;
            this.cartForm.variation_id = color.variation_id;
          } else if (this.product_detail.attr_type == "size") {
            var sizev = this.product_detail.size_variation[0];
            this.cartForm.variation_type_id = sizev.variation_type_id;
            this.cartForm.variation_id = sizev.variation_id;
          } else {
            this.cartForm.variation_type_id = "";
            this.cartForm.variation_id = "";
          }
          this.cartForm.qty = 1;
          this.cartForm.product = "";
        })
        .catch((error) => {
          console.log(error);
        });
    },
    buyModalClose() {
      this.buyModalShow = "";
      this.product_detail = "";
    },

    // color change function
    colorf(event) {
      var colordata = {
        product_id: this.product_detail.product_id,
        variation_type_id: event.target.value,
      };
      this.$store
        .dispatch("colorSizeVariations", colordata)
        .then((responses) => {
          this.sizes = responses.data;
          this.cartForm.variation_id = this.sizes[0].variation_id;
        });
    },
    addtoComparef(product) {
      this.$store.dispatch("addtoCompare", product);
    },
    addToCart(product) {
      if (this.cartForm.qty == 0 || this.cartForm.qty == "") {
        this.cartForm.qty = 1;
      }
      this.cartForm.product = product;
      this.$store.dispatch("addToCart", this.cartForm);
    },
    qtyFilter(evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();
      if (!/^[-+]?[0-9]*?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    decreaseQty() {
      if (this.cartForm.qty > 1) {
        this.cartForm.qty -= 1;
      }
    },
    increaseQty() {
      this.cartForm.qty += 1;
    },
  },
  created() {
    // this.$store.dispatch('featureProducts').then(response => {
    //     this.products = response.data;
    // })
    // .catch(error => {
    //     console.log(error);
    // });
  },
  mounted() {
    // this.$store
    //   .dispatch("featureProducts")
    //   .then((response) => {
    //     this.products = response.data.data;
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   })
    //   .finally(() => {
    //     // off page loading
    //     this.showLoading = false;
    //   });
  },
  watch: {},
};
</script>
<style scoped>
.feature {
  border-bottom: 2px solid #942a8f !important;
}
.thumble {
  height: 221px;
  width: 221px;
  text-align: center;
}
.pro-card-area {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
  padding: 0;
  justify-content: flex-start;
  background: none;
}

.pro-card {
  flex: 0 0 20%;
  max-width: 20%;
  padding: 0 5px 10px;
  margin-bottom: 0;
  display: flex;
  position: relative;
}
.pro-card-inner {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 10%);
}
.pro-card-inner:hover {
  box-shadow: 0px 0px 8px 1px gray;
}
.marks {
  display: flex;
  position: absolute;
  top: 5px;
  left: 0;
  z-index: 10;
  flex-direction: column;
  align-items: flex-start;
}
.mark {
  background: #ed283a;
  width: auto;
  color: #fff;
  font-size: 12px;
  padding: 3px 10px;
  line-height: 14px;
  margin-bottom: 2px;
  border-radius: 0 20px 20px 0;
  flex: 0 0 auto;
}

.pro-image {
  text-align: center;
  border-bottom: 3px solid rgba(55, 73, 187, 0.03);
  flex: 0 0 220px;
  padding: 20px;
  margin: 0;
}
.pro-image img {
  max-width: 100%;
}
.pro-details {
  padding: 10px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  border-bottom: 3px solid rgba(55, 73, 187, 0.03);
}
.price-card {
  font-size: 14px;
}
.pro-name {
  font-weight: 400;
  font-size: 15px;
}
.pro-price {
  line-height: 22px;
  font-size: 17px;
  font-weight: 600;
  color: #ef4a23;
}
.text-dark {
  color: black;
}
.buy-btn {
  padding: 10px;
}
.btn-primary {
  background: #f2f4f8;
  color: #932a8f;
  font-weight: bold;
  transition: 1s;
}
.btn-out {
  background: #d4d5d7;
  color: #932aaa;
  font-weight: bold;
}
.btn-primary:hover {
  background-color: #932a8f;
  box-shadow: 0px 3px 3px 1px #f2f4f8;
  color: #fffcfccc;
  transition: 1s;
}
.btn-secondary{
  background-color: #f2f4f86b;
  transition: 1s;
  font-weight: 500;
  border: 0;
  color: rgb(0, 0, 0);
}

.btn-secondary:hover {
  box-shadow: 0px 3px 3px 1px #f2f4f8;
  color: #932a8f;
  background-color: #cacdd5;
}
.gallary-img {
  height: 50px;
  width: 50px;
  border: 1px solid lightgray;
  padding: 5px;
  margin: 1px;
}
.price-bg {
  background: #f1f1f3d1;
}

@media (max-width: 1279px) {
  .pro-card {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media (max-width: 767px) {
  .pro-card {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
</style>
