<template>
  <div id="main" class="row">
    <TopHeader
      @OpenCart="cartEvent"
      @OpenCompare="compareEvent"
      @ToggleMenu="toggleMenu"
      :cartProducts="cartProducts"
      :compareProducts="compareProducts"
      :userInfo="userInfo"
      :menuShow="menuShow"
      @logout="logout"
      :productCategories="productCategories"
      :logo="logo"
    />
    <router-view
      @filter="filterModalf"
      :filterModal="filterModal"
      @loginReload="loginReload"
      :userInfo="userInfo"
      @logout="logout"
      :compareProducts="compareProducts"
      @cartReload="cartReload"
      @click="closeAtiveModal"
      :sliders="sliders"
      :brands="brands"
      :featureProducts="featureProducts"
      :hotProducts="hotProducts"
      :isLoading="isLoading"
    />
    <FooterView @click="closeAtiveModal" :setting="siteSetting" />
    <CartView
      :cartShow="cartShow"
      @closeCart="cartEvent"
      :cartProducts="cartProducts"
      :cartTotal="cartTotal"
      @cartReload="cartReload"
    />
    <CompareView
      :compareShow="compareShow"
      @closecompare="compareEvent"
      :compareProducts="compareProducts"
      @compareReload="compareReload"
    />
  </div>
</template>
<script>
import TopHeader from "@/components/Layouts/TopHeader.vue";
import FooterView from "@/components/Layouts/FooterView.vue";
import CartView from "@/components/Layouts/CartView.vue";
import CompareView from "@/components/Layouts/CompareView.vue";
export default {
  name: "App",
  components: {
    TopHeader,
    FooterView,
    CartView,
    CompareView,
  },
  data() {
    return {
      icon: "mobile",
      cartShow: false,
      menuShow: false,
      cartProducts: [],
      cartTotal: {},
      filterModal: false,
      compareShow: false,
      compareProducts: [],
      userInfo: "",
      productCategories: [],
      siteSetting: '',
      logo: '',
      sliders: [],
      brands: [],
      featureProducts: [],
      hotProducts: [],
      isLoading: true,
    };
  },
  methods: {
    cartEvent() {
      this.cartShow = !this.cartShow;
      this.cartTotal = this.$store.getters.cartTotal;
      this.compareShow = false;
    },
    cartReload() {
      this.cartProducts = this.$store.getters.productCart;
      this.cartTotal = this.$store.getters.cartTotal;
    },
    compareEvent() {
      this.compareShow = !this.compareShow;
    },
    compareReload() {
      this.compareProducts = this.$store.getters.compareProducts;
    },
    filterModalf() {
      this.filterModal = !this.filterModal;
    },
    start() {
      console.log("loading start");
    },
    end() {
      console.log("loading end");
    },
    loginReload() {
      this.userInfo = this.$store.getters.userInfo;
    },
    logout() {
      this.$store.dispatch("userLogout", this.userInfo).then((response) => {
        if (response.status == 200) {
          this.userInfo = "";
          this.$router.push("/");
        } else {
          alert("Logout fail! Try again.");
        }
      });
    },

    closeAtiveModal(){
      this.cartShow = false;
      this.compareShow = false; 
      this.menuShow = false; 
    },

    // menu toggle show//hide
    toggleMenu(){
      this.menuShow = !this.menuShow;
    },


    //get landing page data
    getLandingPageData(){
      if(this.siteSetting == ''){
        this.$store.dispatch("landingPageData").then(response=>{
          this.productCategories = response.data.categories;
          this.siteSetting = response.data.siteSetting;
          this.logo = this.siteSetting.logo;
          this.sliders = response.data.sliders;
          this.brands = response.data.brands;
          this.featureProducts = response.data.featureProducts;
          this.hotProducts = response.data.hotProducts;
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
        });
      }
    }
  },

  created() {

    // get brands, sliders, features products, hot products
    this.getLandingPageData();

    this.cartProducts = this.$store.getters.productCart;
    this.cartTotal = this.$store.getters.cartTotal;
    this.compareProducts = this.$store.getters.compareProducts;
    this.userInfo = this.$store.getters.userInfo;
    // console.log(this.userInfo);
  },

  mounted() {
    document.onreadystatechange = () => {
      if (document.readyState == "complete") {
        console.log("complete");
      } else {
        console.log("loading");
      }
    };
  },
  watch: {
    cartProducts() {
      this.$store.getters.cartProducts;
    },
    cartTotal() {
      this.$store.getters.cartTotal;
    },
    compareProducts() {
      this.$store.getters.compareProducts;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
}
body {
  background-color: #f2f4f8;
}
.modal {
  background: #808080b8;
}
</style>
