<template>
    <div id="hotProduct" class="row row-cols-1  row-cols-md-2 row-cols-lg-1">
        <div class="col my-3" v-for="product in hotProducts" :key="product.product_id">
            <router-link :to="{
                name: 'productDetails',
                params: {
                    productSlug: product.slug,
                },
            }">
                <img :src="product.thumbnail" :alt="product.product_name" class="img-fluid">
            </router-link>
        </div>
    </div>
</template>
<script>
    export default{
        name: 'HotProducts',
        props: ['hotProducts'],
    }
</script>
