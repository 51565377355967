<template>
    <div id="orderConfirm" class="col-md-12 px-0" >
        <section class="after-header p-tb-10 bg-white p-3">
            <div class="c-intro">
                <ul class="breadcrumb mb-0">
                    <li>
                        <router-link to="/">
                        <i class="material-icons" title="Home">home</i>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="checkout" class="text-capitalize">
                        <span>Checkout</span>
                        </router-link>
                    </li>
                    <li>
                        <a href="#" class="text-capitalize">
                        <span>Order Complete</span>
                        </a>
                    </li>
                </ul>
            </div>
        </section>
        <section class="p-item-page bg-bt-gray p-tb-15 px-3">
            <div class="row py-5">
                <div class="col-md-12 text-center">
                    <h3 class="text-success" >Your order is completed!</h3>
                    <p>Thank you for your order! Your order is being processed and will be completed within 3-6 days. You will receive an email confirmation when your order is completed.</p>
                    <h5 class="nav-link">Order ID: #{{ order_id }}</h5>
                    <router-link to="/" class="btn btn-sm btn-primary">Continue Shopping</router-link>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    name: 'OrderConfirm',
    data(){
        return{
            'order_id': this.$route.params.order_id,            
        }
    },
    mounted(){

    },
    methods:{

    },
    watch: {

    }
}
</script>